@use "@styles/helpers" as *;

.root {
    @include rounded-corners(16px);

    display: block;
    width: 100%;
    overflow: hidden;
    box-shadow: 0 3px 6px 0 transparent;
    transform: translateY(0);
    transition: box-shadow .2s ease, transform .2s ease, border-color .2s ease;

    &:hover {
        cursor: pointer;
        border-color: $color-gray;
        box-shadow: 0 3px 6px 0 #3333330d;
        transform: translateY(-1px);
    }
}

.thumbnail {
    position: relative;
    width: 100%;
    overflow: hidden;

    .image {
        @include flex(center, center);

        position: relative;
        width: 100%;
    }

    .label {
        @include typography(14px, 16px, 500, 500, 'sm');
        @include clamp(1, 28);

        position: absolute;
        top: 8px;
        left: 0;
        padding-top: 0;
        padding-bottom: 0;
        max-width: 75%;
    }

    @include breakpoint(sm) {
        .label {
            @include clamp(1, 32);

            top: 16px;
        }
    }

    .banner {
        position: absolute;
        width: calc(100% - 16px);
        bottom: 8px;
        left: 8px;
        min-height: 24px;
    }
}

.content {
    flex-shrink: 0;
    padding: 12px 8px 8px;
    border-radius: 0 0 16px 16px;
    border: 1px solid $color-gray-light;
    border-top: none;

    .stats {
        @include flex(center, flex-start, row);
        
        min-height: 22px;
        margin-bottom: 12px;
        padding: 0 2px;
    }

    .name {
        @include typography(16px, 16px, 500, 500, 'sm');
        @include clamp(2, 20);

        min-height: 40px;
        padding: 0 4px;
        color: $color-gray-dark;
    }

    .pricing {
        min-height: 28px;
        padding: 0 4px;
        margin-top: 4px;

        .originalPrice {
            margin-bottom: 1px;
            margin-right: 6px;
        }

        .perServingLabel {
            @include typography(14px, 16px, 500, 500);

            margin-left: 1px;
            margin-bottom: 2px;
        }
    }

    .actions {
        width: 100%;
        margin-top: 8px;

        .viewIngredientsButton {
            @include typography(14px, 16px, 500, 500, 'sm');

            width: 100%;
            height: 40px;
        }
    }

    @include breakpoint(sm) {
        padding: 12px;
        
        .name {
            @include clamp(2, 21);
        }

        .pricing {
            min-height: 28px;
            margin-top: 8px;
        }

        .actions {
            margin-top: 12px;
        }
    }

    @include breakpoint(md) {
        .name {
            @include clamp(2, 22);
        }
    }
}