@use "@styles/helpers" as *;

.root {
    &.vertical {
        @include flex(flex-start, flex-start, column-reverse);

        gap: 4px;
    }

    &.horizontal {
        @include flex(center, flex-start, row);

        gap: 4px;
    }
}
