@use "@styles/helpers" as *;

.root {
    @include content-fill;

    position: relative;
    height: auto;

    &.empty {
        @include rounded-corners;

        background-color: $color-gray-light-ultra;
    }

    // Slider
    
    .carousel {
        background: $color-gray-light-ultra;
        border-radius: 8px;
        overflow: hidden;

        &.maximized {
            border-radius: 8px 8px 0 0;
        }

        .carouselPrev {
            left: 10px;
        }

        .carouselNext {
            right: 10px;
        }
    }
}

.slidesWrapper {
    margin-bottom: 8px;

    &.maximized {
        margin-bottom: 8px;
    }
}

.pagination {
    transform: translateY(-8px)
}

// --- Slide

.slide {
    @include flex(center, center);

    height: auto !important;
    cursor: pointer;
    
    &.isImageMaximized {
        padding-bottom: 8px;
        cursor: inherit;

        @include breakpoint(sm) {
            width: 100% !important;
        }
    }
    
    &.firstLevelZoom {
        cursor: zoom-in;
    }

    &.canPan {
        cursor: grab;
    }
    
    &.panning {
        cursor: grabbing;
    }
}

// --- Image

.imageWrapper {
    position: relative;
    max-width: 264px;
    font-size: 0;

    > span {
        display: block !important;
    }

    @include breakpoint(400px) {
        max-width: 320px;
    }

    @include breakpoint(sm) {
        max-width: unset;
    }

    &.empty {
        @include flex(center, center, row);

        margin: 0 auto;

        .image {
            @include rounded-corners;

            width: 100%;
        }
    }
}

.image {
    &.unavailable {
        opacity: 0.4;
        pointer-events: none;
    }

    @include breakpoint(sm) {
        max-width: unset;
        max-height: 550px;
        width: auto;
    }
}

// --- Thumbnails

.thumbnails {
    margin: 32px 0 12px;
}

.carouselSlide {
    width: 100%;
}

// Info Labels

.infoLabels {
    position: absolute;
    bottom: 8px;
    left: 8px;
    z-index: z("product-details-labels");
}