@use "@styles/helpers" as *;

.root {
    @include flex(center);
    @include typography(body);

    color: $color-gray-dark-subtle;

    &:hover {
        color: $color-primary;
    }
}

.icon {
    color: inherit;
    
    .withLabel {
        margin-right: 8px;
    }
}
