@use "@styles/helpers" as *;

.root {
    @include flex(center, flex-start, column);
    @include rounded-corners;

    outline: none;
    border: none;
    width: 100%;
    padding: 8px 12px;

    .inner {
        @include flex(center, flex-start, row);

        flex-grow: 1;
        width: 100%;
    }

    @include breakpoint(md) {
        @include flex(center, flex-start, row);

        padding: 14px 16px;

        .inner {
            width: auto;
        }
    }

    // Centered

    &.centered {
        .inner {
            align-items: center;

            .icon {
                align-self: center;
            }

            @include breakpoint(md) {
                align-items: center;
            }
        }
    }

    // Clickable

    &.clickable {
        transition: background-color 0.2s ease;
        cursor: pointer;
    }

    // With highlighted Icon

    &.highlightIcon {
        .icon {
            @include rounded-corners(100px);

            background: white;
        }
    }

    // Variants

    // --- Default

    &:where(.custom) {
        background: var(--background-color);

        .title {
            color: var(--title-color);
        }

        .description {
            color: var(--text-color);

            span {
                font-weight: 500;
                color: var(--title-color);
            }
        }

        &.highlightIcon .icon {
            color: var(--icon-color);
        }
    }

    // --- Success

    &.success {
        background: $color-success-tint-1;

        &.highlightIcon .icon {
            color: $color-success;
        }

        &.highlightIcon .icon.custom img {
            @include recolorSVG($color-success);
        }

        &.clickable:focus,
        &.clickable:hover {
            background-color: darken($color-success-tint-1, 3%);
        }
    }

    // --- Primary

    &.primary {
        background: $color-secondary;

        .icon,
        .title,
        .description,
        .secondaryIcon {
            color: $color-primary;
        }

        &.highlightIcon .icon {
            color: $color-primary;
        }

        &.highlightIcon .icon.custom img {
            @include recolorSVG($color-primary);
        }

        &.clickable:focus,
        &.clickable:hover {
            background-color: darken($color-secondary, 3%);
        }
    }

    // --- Error

    &.error {
        background: $color-error-tint-1;

        .icon,
        .title,
        .description,
        .secondaryIcon {
            color: $color-error;
        }

        &.highlightIcon .icon.custom img {
            @include recolorSVG($color-error);
        }

        &.clickable:focus,
        &.clickable:hover {
            background: darken($color-error-tint-1, 3%);
        }
    }

    // --- Info

    &.info {
        background: $color-info-tint-1;

        &.highlightIcon .icon {
            color: $color-info;
        }

        &.highlightIcon .icon.custom img {
            @include recolorSVG($color-info);
        }

        &.clickable:focus,
        &.clickable:hover {
            background: darken($color-info-tint-1, 3%);
        }
    }

    // --- Warning

    &.warning {
        background: $color-warning-tint-1;

        &.highlightIcon .icon {
            color: $color-warning;
        }

        &.highlightIcon .icon.custom img {
            @include recolorSVG($color-warning);
        }

        &.clickable:focus,
        &.clickable:hover {
            background: darken($color-warning-tint-1, 3%);
        }
    }

    &.warningV2 {
        background: $color-warning-tint-1;

        .icon,
        .title,
        .description,
        .secondaryIcon {
            color: $color-warning;
        }

        &.highlightIcon .icon {
            color: $color-warning;
        }

        &.highlightIcon .icon.custom img {
            @include recolorSVG($color-warning);
        }

        &.clickable:focus,
        &.clickable:hover {
            background: darken($color-warning-tint-1, 3%);
        }
    }

    // --- Promotion

    &.promotion {
        background-color: $color-promotion-tint-1;

        &.highlightIcon .icon {
            color: $color-promotion;
        }

        &.highlightIcon .icon.custom img {
            @include recolorSVG($color-promotion);
        }

        &.clickable:focus,
        &.clickable:hover {
            background: darken($color-promotion-tint-1, 3%);
        }
    }

    // --- Genius

    &.genius {
        background-color: $color-genius-tint-1;

        .icon,
        .title,
        .description,
        .secondaryIcon {
            color: $color-genius;
        }

        &.highlightIcon .icon.custom img {
            @include recolorSVG($color-genius);
        }

        &.clickable:focus,
        &.clickable:hover {
            background: darken($color-genius-tint-1, 2%);
        }
    }
}

// Sizes

// --- Small

.small {
    padding: 10px 8px;

    &.highlightIcon .icon {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;

        &.custom {
            padding: 4px;
        }
    }

    .icon {
        min-height: 20px;
        margin-right: 8px;
    }

    .secondary {
        margin-left: 8px;
    }

    .title {
        @include typography(14px, 14px, 500, 500);

        margin-bottom: 2px;
    }

    .description {
        @include typography(12px, 12px, 400, 400);
    }
}

// --- Medium

.medium {
    padding: 12px;

    &.highlightIcon .icon {
        width: 36px;
        height: 36px;
        min-width: 36px;
        min-height: 36px;

        &.custom {
            padding: 8px;
        }
    }

    .icon {
        min-height: 21px;
        margin-right: 16px;
    }

    .secondary {
        margin-left: 16px;
    }

    .title {
        @include typography(14px, 16px, 500, 500);

        margin-bottom: 4px;
    }

    .description {
        @include typography(14px, 14px, 400, 400);
    }
}

.large {
    padding: 16px;

    &.highlightIcon .icon {
        width: 40px;
        height: 40px;

        &.custom {
            padding: 8px;
        }
    }

    .icon {
        min-height: 21px;
        margin-right: 16px;
    }

    .secondary {
        margin-left: 16px;
    }

    .title {
        @include typography(16px, 16px, 500, 500);

        margin-bottom: 6px;
    }

    .description {
        @include typography(14px, 14px, 400, 400);
    }

    @include breakpoint(md) {
        padding: 24px;
    }
}

// Icon

.icon {
    @include flex(center, center, row);

    align-self: flex-start;
    flex-shrink: 0;
    color: $color-gray-dark;

    // @todo check why important is needed
    &.custom {
        width: auto !important;
        height: auto !important;
    }
}

// Main

.content {
    @include flex(flex-start, center, column);

    flex-grow: 1;
    text-align: left;

    .title {
        color: $color-gray-dark;
    }

    .description {
        color: $color-gray-dark-subtle;
    }
}

// Secondary

.secondary {
    @include flex(center, center, row);

    width: 20px;

    .secondaryIcon {
        @include flex(center, center, row);

        padding: 2px;
    }

    &.close {
        align-self: flex-start;

        .secondaryIcon {
            @include rounded-corners;

            cursor: pointer;
            outline: none;
            background-color: transparent;
            border: none;
            color: $color-gray-dark;
            transition: background-color 0.2s ease;

            &:focus,
            &:hover {
                background-color: $color-white;
            }
        }
    }
}

// Children

.children {
    flex-shrink: 0;
    width: 100%;
    margin-top: 16px;
    margin-left: 0;

    @include breakpoint(md) {
        width: auto;
        margin-top: 0;
        margin-left: 8px;
    }
}
