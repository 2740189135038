@use "@styles/helpers" as *;

// Root

// --- Mobile

.root.mobile {
    .stats {
        margin-top: 24px;

        .stat {
            padding-top: 8px;
            padding-bottom: 8px;
        }

        @include breakpoint(sm) {
            margin-top: 32px;
            
            .stat {
                padding-top: 12px;
                padding-bottom: 12px;
            }
        }
    }

    .ingredients {
        margin-top: 28px;

        .ingredientsList {
            border-bottom: none;
        }
    }

    .description {
        margin-top: 24px;
        padding-top: 24px;
        border-top: 1px solid $color-gray-light;
    }

    .related {
        .relatedSlider {
            width: calc(100% + 32px);
            margin: 0 -16px;
            padding: 0 16px;
        }

        .relatedNavigationBtn {
            display: none;
        }
    }
}

// --- Desktop

.root.desktop {
    .stats {
        @include breakpoint(md) {
            width: calc(100% + 24px);
            margin: 32px -12px 0;
            padding: 0 8px;
    
            .stat {
                margin: 0 4px;
                padding: 12px 0;
            }
        }
    
        @include breakpoint(lg) {
            width: calc(100% + 48px);
            margin: 32px -24px 0;
            padding: 0 12px;
    
            .stat {
                margin: 0 12px;
                padding: 16px 0;
            }
        }
    }
}

// Main

.main {
    .header {
        @include flex(flex-start, space-between, row);
    
        .title {
            font-size: 18px;
            font-weight: 600;
            line-height: 1;
            color: $color-gray-dark;
        }
    
        .pricePerServing {
            margin-left: 16px;
            margin-right: 4px;
            flex-shrink: 0;
    
            .price,
            .originalPrice {
                @include typography(26px, 26px, 500, 500);
    
                line-height: 1;
            }
    
            .perServingLabel {
                @include typography(16px, 16px, 400, 400);
    
                margin-left: 4px;
                line-height: 1.4;
            }
        }
    
        @include breakpoint(sm) {
            .title {
                font-size: 24px;
                font-weight: 600;
            }
        }
    
        @include breakpoint(md) {
            .title {
                font-size: 42px;
                font-weight: 700;
            }
    
            .pricePerServing {
                min-height: 42px;
                padding-bottom: 2px;
            }
        }
    }

    
    .date {
        @include typography(body);
    
        margin-top: 8px;
        line-height: 1.2;
        color: $color-gray-semi;
    
        @include breakpoint(sm) {
            margin-top: 8px;
        }
    
        @include breakpoint(md) {
            margin-top: 16px;
        }
    }
    
    .description {
        margin-top: 24px;
    }

    .promoBannerContainer {
        margin-top: 16px;
        min-width: 221px;
        width: fit-content;
    }
    
    .ingredients {
        .ingredientsList {
            &.withoutBorder {
                border-bottom: none;
            }
        }
    }
}

// Details

.details {
    .section {
        margin-top: 24px;
        padding-top: 24px;
        border-top: 1px solid $color-gray-light;

        @include breakpoint(md) {
            margin-top: 32px;
            padding-top: 32px;

            &.textIngredients {
                margin-top: 8px;
                border-top: none;
            }
        }
    }

    .tags {
        margin-top: 32px;

        .tagsWrapper {
            flex-wrap: wrap;
        }
        
        @include breakpoint(md) {
            margin-top: 48px;
        }
    }
}
