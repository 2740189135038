@use "@styles/helpers" as *;

.root {
    @include flex(center, space-between, column);

    &.start {
        align-items: flex-start;
        place-content: flex-start;

        .main {
            align-items: flex-start;

            @include breakpoint(sm) {
                align-items: flex-start;
            }
        }
    }

    &.center {
        align-items: center;
        place-content: center;

        .main {
            align-items: center;

            @include breakpoint(sm) {
                align-items: center;
            }
        }

        .priceWrapper {
            align-items: center;
        }
    }

    &.end {
        align-items: flex-end;
        place-content: flex-end;

        .main {
            align-items: flex-end;

            @include breakpoint(sm) {
                align-items: flex-end;
            }
        }

        .priceWrapper {
            align-items: flex-end;
        }
    }

    &.small {
        .original {
            @include typography(12px, 12px, 400, 400);
        }

        .price {
            @include typography(14px, 14px, 700, 700);
        }
        
        .tooltip {
            .price {
                text-decoration-thickness: 1px;
                text-underline-offset: 1px;
            }

            .tooltipIcon {
                margin-left: 4px;
            }
        }

        .priceIcon {
            width: 16px;
            height: 16px;
            margin-right: 4px;
        }

        .perUnit {
            @include typography(extra-small-500);
        }
    }

    &.medium {
        .original {
            @include typography(14px, 14px, 500, 500);
        }

        .price {
            @include typography(16px, 16px, 700, 700);
        }
        
        .tooltip {
            .price {
                text-decoration-thickness: 1.25px;
                text-underline-offset: 4px;
            }

            .tooltipIcon {
                margin-left: 4px;
            }
        }

        .priceIcon {
            width: 16px;
            height: 16px;
            margin-right: 4px;
        }
    
        .perUnit {
            @include typography(14px, 14px, 500, 500);
        }
    }

    &.large {
        .original {
            @include typography(16px, 20px, 500, 500);

            &:not(.withStrikethrough) {
                @include typography(26px, 34px, 700, 700);
            }
        }

        .price {
            @include typography(26px, 34px, 700, 700);
        }
        
        .tooltip {
            .price {
                text-decoration-thickness: 1.5px;
                text-underline-offset: 8px;
            }

            .tooltipIcon {
                margin-left: 8px;
            }
        }

        .priceIcon {
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }

        .perUnit {
            @include typography(16px, 20px, 400, 400);
        }
    }

    &.fixedHeight {
        &.small {
            .original {
                min-height: 14px;
                line-height: 14px;
            }

            .price {
                min-height: 14px;
                line-height: 14px;
            }
        }

        &.medium {
            .original {
                min-height: 18px;
                line-height: 18px;
            }

            .price,
            .perUnit {
                min-height: 24px;
                line-height: 24px;
            }

            .taxes {
                min-height: 16px;
            }
        }

        &.large {
            .original {
                min-height: 24px;
                line-height: 24px;
            }

            .price,
            .perUnit {
                min-height: 32px;
                line-height: 32px;
            }

            @include breakpoint(sm) {
                .original {
                    min-height: 28px;
                    line-height: 28px;
                }

                .price {
                    min-height: 40px;
                    line-height: 40px;
                }

                .perUnit {
                    min-height: auto;
                    line-height: 1.5;
                }
            }
        }
    }

    &.inline {
        @include flex(center, space-between, row);

        @include breakpoint(sm) {
            @include flex(flex-start, center, column);
        }

        .main {
            @include flex(center, flex-end, row-reverse);

            .original {
                margin-left: 16px;
            }

            @include breakpoint(sm) {
                @include flex(center, flex-end, row-reverse);
            }
        }
    }

    &.inlineReverse {
        @include flex(center, space-between, row);

        @include breakpoint(sm) {
            @include flex(flex-start, center, column);
        }

        .main {
            @include flex(center, flex-end, row);

            .original {
                margin-right: 16px;
            }

            @include breakpoint(sm) {
                @include flex(center, flex-end, row);
            }
        }
    }
}

// Main

.main {
    @include flex(flex-start, center, column);
}

// --- Original Price Wrapper

.originalPriceWrapper {
    @include flex(center, center, row);

    .original {
        color: $color-gray-semi;
        
        &:not(.withStrikethrough) {
            font-weight: 500;
            color: $color-gray-dark;
        }
    }

    .discountLabel {
        margin-left: 8px;
        font-weight: 500;
    }
}

// --- Price Wrapper

.priceWrapper {
    @include flex(flex-start, center, column);

    .price {
        flex-shrink: 0;
        color: $color-gray-dark;

        &.noTaxes {
            margin-bottom: 4px;
        }
    }

    .taxes {
        margin-top: 2px;
        flex-shrink: 0;
    }

    .tooltip {
        .price {
            text-decoration: underline dashed $color-gray-semi;
        }

        .tooltipInner {
            @include flex(center, center, row);
        }

        .tooltipIcon {
            margin-left: 4px;
            color: $color-gray-semi;
        }
    }
}

// Tooltip

.tooltipContainer {
    z-index: z('product-card-tooltip');
}

.tooltipContent {
    max-width: 250px;
}

// Per Unit

.perUnit {
    margin-left: 16px;
    color: $color-gray-semi;

    @include breakpoint(sm) {
        margin-left: 0;
    }
}
