@use "@styles/helpers" as *;

.list {
    @include flex(flex-start, flex-start);

    flex-wrap: wrap;
}

.item {
    @include flex(flex-start, flex-start);

    width: 100%;
    padding: 4px 0;

    @include breakpoint(md) {
        width: 50%;
    }
}

.marker {
    @include typography(14px, 18px, 500, 500);
}

.label {
    @include typography(14px, 18px, 400, 400);

    margin-left: 8px;
}