@use "@styles/helpers" as *;

.mapContainer {
    @include rounded-corners;

    width: 100%;
    height: 200px;
    margin: 0 0 16px;
    background-color: $color-secondary;
}

.map {
    transition: opacity 0.3s ease;

    &.hidden {
        opacity: 0;
    }
}

.alert {
    margin-bottom: 16px;
}
