@use "@styles/helpers" as *;

.loader {
    height: 100%;
    @include flex(center, center);
}

.fastCheckoutSlot {
    color: $color-gray-dark;
    font-weight: 500;

    &.primary {
        color: $color-white;
    }
}

:export {
    colorGenius: $color-genius;
}