@use "@styles/helpers" as *;

.root {
    @include breakpoint(sm, max) {
        .content {
            height: 100%;
            max-height: 80vh !important;
        }
    }
}

.stepper {
    @include breakpoint(sm, max) {
        .stepperContent {
            height: 100% !important;
        }
    }
}

// Export

:export {
    breakpointSm: $screen-sm-min;
}