@use "@styles/helpers" as *;

.wrapper {
    @include rounded-corners;

    display: flex;
    flex-direction: column;
    background-color: $color-gray-light-ultra;
    margin: 12px 16px;

    @include breakpoint(lg) {
        padding: 16px 24px;
        margin: 0;
        background-color: $color-white;
    }
}

.title {
    @include typography(body-small-500);

    color: $color-gray-dark-subtle;
    padding: 16px 16px 0;

    @include breakpoint(lg) {
        font-size: 20px;
        font-weight: 500;
        padding: 0;
    }
}

.subtitle {
    @include typography(body-small);

    color: $color-gray-semi;
    padding: 8px 16px;

    @include breakpoint(lg) {
        margin-top: 16px;
        padding: 0;
    }
}

.mainButtonWrapper {
    padding: 8px 16px 16px;
}

.mainButton {
    @include typography(button);

    background-color: $color-white;
    box-shadow: 0 4px 4px rgb(0 0 0 / 2%);
    border: 1px solid $color-gray-light;
    color: $color-gray-dark-subtle;
    width: 100%;

    @include breakpoint(lg) {
        margin: 24px 0 0;
        padding: 12px 24px;
        width: 100%;
    }
}

.emagLogo {
    width: 50px;
    height: 24px;
    position: relative;
    margin-top: 0;
    margin-left: 8px;
}
