@use "@styles/helpers" as *;

.root {
    @include typography(small);

    display: inline-flex;
    align-items: center;
    cursor: default;
}

// --- Popover

.popoverContainer {
    display: inline-flex;
}

.popover {
    padding: 8px 16px;
}

.popoverContent {
    @include rounded-corners;
    @include typography(body-small-700);

    color: $color-gray-dark;
    width: auto !important;
}

// --- Tag

.container {
    @include flex(center, center);

    height: 20px;
    display: flex;
    border-radius: 4px;

    &.withText {
        padding: 0 10px 0 0;
        border-radius: 100px;
        margin-right: 4px;
    }
}

.icon {
    max-height: 20px;
    max-width: 20px;
}

.label {
    @include typography(small);

    margin-left: 4px;
    line-height: normal;
}
