@use "@styles/helpers" as *;

.inputWrapper {
    position: relative;
}

.input {
    @include typography(body);
    @include rounded-corners;

    width: 100%;
    border: 1px solid $color-gray;

    &:hover:not([disabled]) {
        border-color: $color-gray-semi;
    }

    &:focus:not([disabled]) {
        border-color: $color-gray-dark-subtle;
    }

    &.hasError {
        border-color: $color-error;
        box-shadow: inset 0 0 0 1px $color-error;
    }

    &:input-placeholder {
        color: $color-gray-semi;
    }

    &::placeholder {
        color: $color-gray-semi;
    }

    &[disabled] {
        background: $color-gray-light-ultra;
        color: $color-gray-semi;
        border-color: $color-gray-light;
    }
}

.rounded {
    border-radius: 100px;
}

.startIconContainer,
.endIconContainer {
    @include flex-center;
}

.startIconContainer {
    @include pos-left(8px);
}

.endIconContainer {
    @include pos-right(8px);
}

.inputLarge {
    padding: 13px;
}

.inputMedium {
    padding: 7px;
}

.inputSmall {
    padding: 6px 8px;
}

.withStartIcon {
    padding-left: 44px;
}

.withEndIcon {
    padding-right: 44px;
}

.error {
    @include flex(center);
    @include hidden;
    @include typography(small);

    color: $color-error;
    min-height: 18px;
    margin-left: 4px;
    margin-top: 2px;
    transition: opacity 0.2s ease;

    svg {
        margin-right: 4px;
    }

    &.visible {
        @include visible;
    }
}
