@use "@styles/helpers" as *;

.icon {
    position: relative;
    left: -1px;
    margin-bottom: 4px;
}

:export {
    colorRecommendation: $color-primary;
}