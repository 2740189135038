@use "@styles/helpers" as *;

.modalBtn {
    @include button-reset;
    @include typography(body-small-500);

    color: $color-gray-dark;
    padding: 0;
    line-height: 20px;
}

.filterIcon {
    color: $color-primary;
}

.filtersCount {
    @include flex(center, center);
    @include typography(small-700);

    color: $color-white;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: $color-primary;
    margin-left: 8px;
}