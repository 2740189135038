@use "@styles/helpers" as *;

.root {
    margin-bottom: 24px;
    margin-left: 16px;
    margin-right: 16px;

    &:empty {
        margin-bottom: 0;
    }

    @include breakpoint(md) {
        margin-bottom: 32px;
        margin-left: 0;
        margin-right: 0;

        &:empty {
            margin-bottom: 0;
        }
    }
}

.notice {
    &:not(:first-child) {
        margin-top: 8px;
    }
}

// Exports

:export {
    breakpoint: $screen-sm-min;
}
