@use "@styles/helpers" as *;

// Details

.details {
    @include flex(normal, center, column);

    @include breakpoint(sm) {
        flex-direction: row;

        &.maximized {
            flex-direction: column;
        }
    }
}

// --- Image

.image {
    @include flex(center, flex-start);

    position: relative;
    width: 100%;
    padding-bottom: 24px;

    @include breakpoint(sm) {
        width: 35%;
        min-width: 35%;
        padding: 0;
        flex-direction: column;

        &.isQuickView {
            width: 45%;
            min-width: 45%;
        }

        &.maximized {
            width: 100%;
        }
    }
}

// ------ Gallery

.gallery {
    z-index: z("product-details-gallery");
}

// ------ Labels

.labels {
    position: absolute;
    top: 16px;
    left: 0;
    z-index: z("product-details-labels");
}

// ------ Badge

.badge {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -14px;
    margin: auto;
    z-index: z("product-details-badge");

    &.maximized {
        position: relative;
        margin-top: 8px;
        margin-left: 4px;
        bottom: auto;
    }
}

// --- Info

.info {
    display: flex;
    flex-direction: column;

    @include breakpoint(sm) {
        width: 65%;
        padding-left: 32px;
        padding-right: 8px;

        &.isQuickView {
            width: 55%;
        }

        &.maximized {
            margin-left: 0;
            margin-top: 24px;
            width: 100%;
        }
    }

    @include breakpoint(md) {
        &.maximized {
            padding: 0;
        }
    }
}

.infoWrapper {
    width: 100%;

    @include breakpoint(sm) {
        @include flex(flex-start, flex-start, column);

        &.maximized {
            flex-direction: row;
            align-items: flex-start;
        }
    }
}

.infoInner {
    flex-grow: 1;

    &.maximized {
        padding-right: 24px;
    }
}

// ------ Name

.name {
    @include typography(20px, 26px, 500, 500);

    line-height: normal;
    color: $color-gray-dark-subtle;

    .link {
        @include typography(20px, 26px, 500, 500);

        padding: 0;
        line-height: normal;
    }

    .alignMaximized {
        justify-content: flex-start;
    }

    a:hover {
        text-decoration: underline;
    }
}

// ------ Tags

.tags {
    margin-top: 8px;
    font-size: 0;

    .tag {
        margin-top: 8px;
    }
}

// ------ Banner

.bannerWrapper {
    @include flex(center, flex-start, row);

    &:not(:empty) {
        margin-top: 16px;
    }

    .banner {
        max-width: 300px;
    }

    .banner.wide {
        min-width: 250px;
    }

    .bannerTooltipTitle {
        @include typography(body-small-500);
    }

    .bannerTooltipText {
        @include typography(body-small)
    }

    .bannerTooltipContainer {
        z-index: z('product-details-banner-tooltip');
    }
}

// ------ Price

.productPrice {
    flex-direction: row;
    align-items: flex-start !important;
    place-content: space-between !important;
    margin-top: 12px;

    .priceWrapper {
        @include flex(center, flex-start, row);
    }
    
    .taxes {
        margin-top: 0;
        margin-left: 12px;
    }

    .originalPrice,
    .unitPriceLabel {
        font-size: 16px !important;
        font-weight: 400 !important;
    }

    .unitPriceLabel {
        align-self: flex-end;
        margin-bottom: 8px;
    }

    .originalPrice:not(.withStrikethrough) {
        font-size: 26px !important;
        font-weight: 500 !important;
        line-height: 24px !important;

        @include breakpoint(sm) {
            font-size: 30px !important;
            font-weight: 500 !important;
            line-height: 32px !important;
        }
    }

    @include breakpoint(sm) {
        flex-direction: column;
        align-items: flex-start !important;
        place-content: flex-start !important;
        margin-top: 24px;

        .price {
            line-height: 48px;
        }

        .unitPriceLabel {
            align-self: flex-start;
            margin-bottom: 0;
        }
    }
}

// --- Actions Wrapper

.actionsWrapper {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;

    @include breakpoint(sm) {
        margin-top: 16px;

        &.maximized {
            @include flex(flex-end, flex-end, column);

            width: auto;

            .actions {
                @include flex(center, flex-end, row);
            }
        }
    }
}

// ------ Out of stock

.outOfStockWrapper {
    @include flex(center, flex-start, row);

    &:not(:empty) {
        margin-top: 16px;
    }

    &.hidden {
        display: none;
    }

    .outOfStock {
        @include typography(body-small);

        color: $color-error;
    }

    @include breakpoint(sm) {
        &:not(:empty) {
            margin-top: 8px;
        }

        &.hidden {
            display: flex;
        }
    }
}

// ------ Actions

.actions {
    margin-top: 8px;

    @include breakpoint(sm) {
        // margin-top: 16px;
    }
}

// ------ Variants switcher

.variants {
    @include flex(flex-start, flex-start, row);

    padding: 0;
    justify-content: flex-start;
    margin-top: 16px;

    &.withBorder {
        margin-left: -16px;
        margin-right: -16px;
        padding: 24px 16px;
        border-top: 1px solid $color-gray-light;
    }

    @include breakpoint(sm) {
        &.withBorder {
            margin-left: 0;
            margin-right: 0;
            padding: 24px 0 0;
        }
    }

    &.top {
        display: none;

        @include breakpoint(sm) {
            display: flex;

            &.maximized {
                display: none;
            }
        }
    }

    &.bottom {
        @include breakpoint(sm) {
            display: none;

            &.maximized {
                display: flex;
            }
        }
    }
}

// ------ Delivery comment

.deliveryCommentWrapper {
    &:not(:empty) {
        margin-top: 8px;
    }

    &.hidden {
        display: none;
    }

    @include breakpoint(sm) {
        &:not(:empty) {
            margin-top: 16px;
        }

        &.hidden {
            display: flex;
        }

        &.maximized:not(:empty) {
            margin-top: 16px;
        }
    }
}

.deliveryComment {
    @include breakpoint(sm) {
        &.maximized {
            max-width: 384px;
        }
    }
}

// --- Exports

:export {
    breakpointSm: $screen-sm-min;
    colorPrimary: $color-primary;
}
