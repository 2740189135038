@use "@styles/helpers" as *;

.root {
    @include flex(flex-start, flex-start, row);

    padding: 4px 12px;
    border-radius: 8px;

    .icon {
        position: relative;
        width: 18px;
        height: 18px;
        margin-right: 8px;
        flex-shrink: 0;
    }

    .text {
        @include typography(small-500);

        color: $color-gray-dark-subtle;

        span {
            margin-left: 4px;
            color: var(--text-color);
        }
    }
}
