@use "@styles/helpers" as *;

.title {
    @include typography(body);

    margin-bottom: 8px;
}

.message {
    @include typography(body-small);
}
