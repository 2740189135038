@use "@styles/helpers" as *;

.root {
    @include pos-bottom(0, fixed);
    @include flex(center);

    z-index: z("open-in-app-banner");
    background: $color-secondary;
    border-top: 2px solid $color-primary;
    padding: 16px 16px calc(16px + env(safe-area-inset-bottom));
}

.textContent {
    flex-grow: 1;
    margin: 0 12px;

    @include breakpoint(364px, max) {
        margin: 0 8px;
    }
}

.title {
    @include typography(body-small-500);

    color: $color-gray-dark;
}

.subtitle {
    @include typography(body-small);

    color: $color-gray-semi;

    @include breakpoint(352px, max) {
        display: none;
    }
}

.buttonsContainer {
    @include flex(center);
}

.openBtn {
    @include typography(body-small-500);
}

.dismissBtn {
    margin-left: 12px;

    @include breakpoint(364px, max) {
        margin-left: 8px;
    }
}

.closeIcon {
    color: $color-primary;
}
