@use "@styles/helpers" as *;

.root {
    .content {
        @include breakpoint(md) {
            width: 968px !important;
        }
    }
}

// Body

.body {
    padding-top: 16px;

    @include breakpoint(md) {
        padding-top: 0;
    }
}

// Carousel

.carouselContainer {
    margin-top: 0;

    &:first-child {
        .carouselHeader {
            margin-top: 0;
        }
    }

    &:not(:first-child) {
        margin-top: 16px;
    }
}

// Empty

.emptyContainer {
    padding-bottom: 64px;
}

// Export
 
:export {
    breakpoint: $screen-md-min;
}