@use "@styles/helpers" as *;

.root {
    position: relative;

    &.default{
        position: relative;
    }

    &.underlined {
        .breadcrumb {
            color: $color-gray-semi;
            border-bottom: 1px dashed $color-gray-semi;
        }
    }

    &.disableLast {
        .breadcrumb {
            &.last {
                color: $color-gray-semi;
            }
        }

        @include breakpoint(sm) {
            &:not(.last):hover {
                color: $color-primary;
            }
        }
    }
}

.skeleton {
    width: 100%;

    > * {
        width: 100%;
    }
}

.fade {
    @include pos-fill;

    pointer-events: none;

    &.start {
        &::before {
            @include pos-left;

            content: "";
            background: linear-gradient(to left, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 85%) 100%);
            width: 85px;
        }
    }

    &.end {
        &::after {
            @include pos-right;

            content: "";
            background: linear-gradient(to right, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 85%) 100%);
            width: 85px;
        }
    }

    @include breakpoint(md) {
        &.start::before,
        &.end::after {
            content: none;
        }
    }
}

.container {
    @include flex(center);

    position: relative;
    width: 100%;
    padding-bottom: 8px;
    overflow-x: auto;

    @include breakpoint(md) {
        overflow-x: hidden;
        padding-bottom: 0;
        min-width: 0;
    }
}

.breadcrumb {
    @include typography(body-small);

    flex-shrink: 0;

    @include breakpoint(sm) {
        color: $color-gray-dark-subtle;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 40px;
        display: block;
        flex-shrink: 1;

        &.last {
            max-width: initial;
        }
    }
}

.chevron {
    color: $color-gray-semi;
    margin: 0 6px;
    flex-shrink: 0;
}

.startAnchor,
.endAnchor {
    flex-shrink: 0;
}

.endAnchor {
    width: 1px;
}
