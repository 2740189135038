@use "@styles/helpers" as *;

.root {
    @include flex(flex-start, center, column);

    width: 100%;
}

.inner {
    @include flex(flex-start, center, row);

    width: 100%;
}

// Thumbnail

.thumbnail {
    position: relative;
    flex-shrink: 0;

    .loadingOverlay {
        @include pos-fill(0);
        @include flex(center, center);
        @include rounded-corners;

        z-index: z("product-card-loading-overlay");
        background: transparentize($color-gray-light-ultra, $amount: 0.1);
        font-size: 0;
    }
}

// Details

.details {
    @include flex(flex-start, space-between, column);

    align-self: stretch;
    flex-grow: 1;
    margin-left: 12px;
}

// -- Main

.main {
    width: 100%;
    @include flex(flex-start, flex-start);

    .title {
        @include typography(14px, 16px, 400, 400);
        @include clamp(2, 22);

        @include breakpoint(md) {
            padding-top: 0;
            padding-left: 0;
            @include clamp(2, 22);

            max-height: 47px;
            
            &.asLink {
                text-decoration: underline;
                text-decoration-color: transparent;
                transition: all .2s ease;
                
                &:hover {
                    text-decoration-color: inherit;
                }
            }
        }
    }
}

// --- Secondary

.secondary {
    @include flex(center, space-between, row);

    flex-grow: 1;
    width: 100%;
    margin-top: 8px;
    
    .price {
        font-weight: 500 !important;

        &.discounted {
            color: $color-discount;
        }

        &.unavailable {
            opacity: 0.4;
        }
    }

    .actions {
        @include flex(center, flex-end, row);

        flex-grow: 1;
    }
}

// --- Tertiary

.tertiary {
    width: 100%;
    margin-top: 12px;

    .error {
        @include typography(12px, 14px, 500, 500);

        margin-bottom: 4px;
        color: $color-error;
    }

    .info {
        @include typography(12px, 12px, 400, 400);
    
        color: $color-gray-semi;
    }

    &.external {
        padding: 0 4px;
    }
}

// --- Info


// Skeleton

.root.skeleton {
    .image {
        font-size: 0;
    }

    .secondary {
        .price {
            width: 30%;
        }
    }

    .tertiary {
        width: 100%;
        
        .info {
            width: 75%;
        }
    }
}