@use "@styles/helpers" as *;

.root {
    margin-left: 16px;

    &:not(.bordered) {
        border: none;
    }

    &.small {
        width: 30px;
        height: 30px;
    }

    &.medium {
        width: 46px;
        height: 46px;
    }

    &.gray {
        color: $color-gray-semi;
    }

    &:hover:not([disabled]),
    &:focus,
    &:active:not([disabled]) {
        background: transparent;
        color: $color-error;

        &:not(.bordered) {
            border: none;
        }

        &.bordered {
            border: 1px solid $color-error;
        }
    }

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .icon path:last-of-type {
        transition: fill 0.2s ease;
    }

    &:not(.isFavorite) {
        .icon path:last-of-type {
            fill: transparent;
        }
    }

    &.isFavorite {
        color: $color-error;

        .icon :global {
            @include animation(pop, 0.2s, ease);
        }

        .icon path:last-of-type {
            fill: $color-error;
        }

        &:not(.bordered) {
            border: none;
        }

        &.bordered {
            border: 1px solid $color-error;
        }
    }
}
