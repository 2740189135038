@use "@styles/helpers" as *;

.root {
    width: 100%;

    &:not(:first-child) {
        margin-top: 8px;
    }

    &.withPadding {
        padding: 0 16px;

        @include breakpoint(sm) {
            padding: 0 24px;
        }
    }

    &.withBorder {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid $color-gray-light;
    }

    &.inline {
        .fields {
            @include breakpoint(sm) {
                @include flex(flex-start, space-between, row);
            }
        }
    }

    .title {
        @include typography(14px, 14px, 400, 400);

        margin-bottom: 16px;
        color: $color-gray-semi;
    }
}
