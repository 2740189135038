@use "@styles/helpers" as *;

.root {
    position: relative;
    @include flex(center,center);

    width: 100%;
}

.androidStoreButton,
.iosStoreButton {
    position: relative;
    cursor: pointer;
}

.androidStoreButton {
    width: 135px;
    height: 40px;
}

.iosStoreButton {
    width: 120px;
    height: 40px;
    margin-left: 8px;
}
