@use "@styles/helpers" as *;

.root {
    @include button-reset;

    display: inline-flex;
    padding: 0;
    color: $color-primary;

    .icon {
        position: relative;
        top: -1px;
        transition: transform 0.2s ease-in-out;
    }


    @include with-hover {
        &:focus,
        &:hover {
            .icon {
                transform: translateX(-2px);
            }
        }
    }
}
