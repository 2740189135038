@use "@styles/helpers" as *;

.root {
    @include flex(flex-start, flex-start, column);

    width: 100%;
    background: $color-white;
    z-index: z("bottomActions");
    border-top: 1px solid $color-primary;
    padding: 16px;
    padding-bottom: calc(16px + env(safe-area-inset-bottom));
    @include pos-bottom(0, fixed);

    @include breakpoint(sm) {
        position: relative;
        padding: 0;
        border-top: 0;
    }
}

.buttonsContainer {
    @include flex(center, center, row-reverse);

    width: 100%;

    @include breakpoint(sm) {
        width: auto;
    }
}

.actions {
    flex-grow: 1;
    width: 280px;

    @include breakpoint(419.98px) {
        max-width: 322px;
    }
}

.productQuantity {
    @include breakpoint(420px, max) {
        place-content: center;

        .productQuantityEditor {
            width: 100% !important;
            place-content: center;
        }
    }
}

.productQuantityInputWrapper {
    flex-grow: 1;
}

.addButton {
    width: 100%;
}

.cartButton,
.similarButton,
.addButton {
    width: 100%;
}

.commentContainer {
    margin-top: 24px;
    width: 100%;
    display: none;

    svg {
        color: $color-gray-semi;
    }

    &.visible {
        display: flex;
    }
}

.favoritesToast {
    bottom: 80px !important;
    @include breakpoint(sm) {
        bottom: 12px !important;
    }
}

:export {
    breakpointSm1: 350px;
    breakpointSm2: 420px;
    breakpointSm: $screen-sm-min;
    breakpointMd1: 720px;
    breakpointMd2: 840px;
    breakpointXl: $screen-xl-max;
    breakpointXl1: 1510px;
}
