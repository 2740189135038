@use "@styles/helpers" as *;

.root {
    @include breakpoint(sm, max) {
        display: flex;
        flex-direction: column;
        height: 100%;

        .groups {
            @include flex(flex-start, flex-start, column);

            flex-grow: 1;
            overflow-y: auto;
            padding-top: 16px;

            .mapFormGroup {
                @include flex(flex-start, flex-start, column);

                flex: 1;
                margin-top: 0;
            }

            .mapFormGroupFields {
                @include flex(flex-start, flex-start, column);

                flex: 1;
            }

            .googleMapContainer {
                flex: 1;
                margin-bottom: 0;
            }
        }
    }
}

.alert {
    margin-bottom: 16px;
}

.actions {
    margin-top: 24px;
}
