@use "@styles/helpers" as *;

.icon {
    position: relative;
    margin-bottom: 4px;
}

.thumbnailsWrapper {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 12px;

    .thumbnails {
        @include flex(center, flex-start, row);
    }

    .thumbnail {
        flex-grow: 0;
        margin-right: 11px;
    }
}

:export {
    colorGift: $color-gift;
}