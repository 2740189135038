@use "@styles/helpers" as *;

.container {
    display: flex;
    position: relative;
    user-select: none;
    flex-shrink: 0;
    cursor: pointer;
    transition: all 0.2s ease;

    .checkboxIcon {
        @include flex-center;
        @include rounded-corners;

        position: relative;
        height: 20px;
        width: 20px;
        flex-shrink: 0;
        border: 1px solid $color-gray;
        background-color: $color-white;
        overflow: hidden;
        transition: all 0.2s ease, opacity 0.2s ease-in-out 0.2s;

        &:after {
            content: "";
            position: absolute;
            display: block;
            border: solid $color-white;
            border-width: 0 2px 2px 0;
            height: 9px;
            left: 6px;
            top: 2px;
            width: 5px;
            opacity: 0;
            transform: translateX(-8px) rotate(45deg);
            transition: all 0.2s ease;
        }

        &.large {
            width: 24px;
            height: 24px;
        }

        &.large:after {
            height: 10px;
            left: 8px;
            top: 3px;
            width: 5px;
        }

        &.loading {
            opacity: 0;
            transition: all 0.2s ease, opacity 0.2s ease-in-out 0s;
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        flex-shrink: 0;

        &:checked ~ .iconWrapper .checkboxIcon {
            background-color: $color-primary;
            border-color: $color-primary;

            &:after {
                opacity: 1;
                transform: translateX(0) rotate(45deg);
            }
        }

        &:not(.disabled, :checked) {
            &:focus,
            &:hover {
                ~ .iconWrapper .checkboxIcon {
                    border-color: $color-primary;
                }
            }
        }
    }

    &.disabled {
        cursor: default;
        opacity: 0.5;
    }
}

// Icon wrapper

.iconWrapper {
    @include flex(center);

    position: relative;

    .loadingWrapper {
        @include flex(center);

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
}

// Label wrapper

.labelWrapper {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .label {
        font-size: 16px;
        font-weight: 500;
        color: $color-gray-dark;
    }

    .subtitle {
        @include typography(body-small);

        color: $color-gray-semi;
    }
}

:export {
    primaryColor: $color-primary;
}
