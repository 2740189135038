@use "@styles/helpers" as *;

.root {
    @include flex(flex-start, space-between);

    background: $color-error-tint-1;
    color: $color-error;
    padding: 10px 16px;
    position: relative;
    width: 100%;
}

.content {
    @include typography(body-small-500);

    flex-grow: 1;
    max-width: $container-max-width;
    margin: 0 auto;
    @include flex(center, center);

    a {
        margin-top: 8px;
    }

    flex-wrap: wrap;

    @include breakpoint(sm) {
        padding: 0 32px;
        padding-right: 32px;

        a {
            margin-top: 0;
            margin-right: 8px;
        }
    }
}

.exitBtn {
    @include rounded-corners;

    background: $color-error;
    color: $color-white;
    padding: 6px 12px;
    margin-left: 12px;

    &:hover {
        background-color: $color-error-shade-1;
    }
}
