@use "@styles/helpers" as *;

.root {
    background-color: $color-gray-light-ultra;
}

.container {
    @include flex(flex-start, center, row);

    max-width: $container-max-width;
    margin: 0 auto;
    padding: 16px;
    
    @include breakpoint(lg) {
        min-height: 740px;
        padding: $container-padding 24px;
    }
}

.content {
    @include rounded-corners(24px);

    width: 100%;
    max-width: $account-content-width;
    background: $color-white;
    overflow: hidden;
    
    &.padded {
        padding: 16px 0;
    }

    &.flex {
        @include flex(flex-start, flex-start, column);
    }

    @include breakpoint(md) {
        &.padded {
            padding: 16px 24px;
        }
    }

    @include breakpoint(lg) {
        @include rounded-corners(24px);

        margin-top: 48px;
        height: 100%;
        
        &.padded {
            padding: 32px 40px;
        }
    }
}

