@use "@styles/helpers" as *;

.root {
    @include flex(flex-start, space-between);

    position: relative;
    width: 100%;
    min-height: 52px;
    background: var(--background-color, $color-warning-tint-1);
}

.announcement {
    position: relative;
    width: 100%;
    padding: 12px 16px;
    background: var(--background-color, $color-warning-tint-1);
    transition: background-color 0.1s ease;

    &.loading {
        opacity: 0.5;
    }

    &.link {
        &:hover {
            background: var(--background-hover-color, $color-warning-tint-1);
        }
    }

    @include breakpoint(md) {
        padding: 10px;

        &.withIcon {
            padding: 10px;
        }
    }
}

.loader {
    @include pos-fill(0);
    @include flex(center, center, row);

    z-index: 1;
}

.inner {
    flex-grow: 1;
    max-width: $container-max-width;
    margin: 0 auto;
    padding-right: 24px;
    min-height: 32px;
    line-height: 1.7;

    .container {
        @include flex(flex-start, center, row);

        min-height: 32px;
        opacity: 1;
        transition: opacity 0.2s ease 0.3s;

        .icon {
            @include flex(center, center, row);
            @include rounded-corners(30px);
        
            position: relative;
            width: 28px;
            height: 28px;
            margin: 4px 16px 4px 0;
            padding: 6px;
            flex-shrink: 0;
            background-color: $color-white;
        
            .iconInner {
                position: relative;
                width: 100%;
                height: 100%;
            }
        
            @include breakpoint(md) {
                margin: 0 12px 0 0;
                width: 32px;
                height: 32px;
            }
        }
        
        .content {
            @include flex(flex-start, flex-start, column);
        
            flex-grow: 1;
            flex-wrap: wrap;
            align-self: stretch;
        
            @include breakpoint(md) {
                @include flex(center, center, row);
        
                letter-spacing: .25px;
                flex-grow: 0;
                align-self: center;
            }
        }
        
        .title {
            @include typography(small-500);
        
            margin-bottom: 8px;
            color: var(--title-color);
        
            @include breakpoint(md) {
                position: relative;
                margin-right: 16px;
                padding-right: 16px;
        
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    right: -3px;
                    width: 6px;
                    height: 6px;
                    border-radius: 30px;
                    background-color: var(--separator-color, $color-gray-dark);
                }
            }
        }
        
        .description {
            @include typography(small);
        
            color: var(--description-color);
        
            span {
                font-weight: 600;
                color: var(--title-color);
            }
        
            @include breakpoint(md) {
                span {
                    font-size: 14px;
                }    
            }
        }
    }

    &.loading {
        .container {
            opacity: 0;
            transition: opacity 0.2s ease;
        }
    }
    
    @include breakpoint(md) {
        padding: 0 32px;
        text-align: center;
        line-height: 1.5;
        
        .container {
            @include flex(center, center, row);

            .title {
                margin-bottom: 0;
            }
        }
    }
}

.closeBtn {
    position: absolute;
    top: 12px;
    right: 10px;
    color: var(--title-color);

    @include breakpoint(md) {
        top: 0;
        bottom: 0;
        margin: auto;
        right: 12px;
    }
}

:export {
    colorPrimary: #{$color-primary};
    colorEta: #{$color-eta};
    colorWhite: #{$color-white};
}
