@use "@styles/helpers" as *;

.root {
    @include pos-fill(0, fixed);
    @include flex(flex-start, center, row);

    z-index: z("modal");
    height: 100%;
    overflow: hidden;
    background: none;

    &.center:not(.fullScreen) {
        overflow-y: scroll;
    }

    &.fullScreen {
        .background {
            background: white;
        }
    }

    // --- Position

    &.bottom {
        align-items: flex-end;
    }

    &.bottom.fullScreen {
        align-items: flex-start;
    }

    &.left {
        justify-content: flex-start;
    }

    &.right {
        justify-content: flex-end;
    }
}

.background {
    @include pos-fill(0, fixed);

    width: 100%;
    height: 150%;
    pointer-events: none;
    background: $color-overlay;
    opacity: 0;

    // Enter

    &.enter {
        opacity: 0;
    }

    &.enterActive {
        opacity: 1;
        transition: opacity 0.2s ease;

        &.responsive {
            transition: opacity 0.2s ease;
        }
    }

    &.enterDone {
        opacity: 1;
    }

    // Exit

    &.exit {
        opacity: 1;
    }

    &.exitActive {
        opacity: 0;
        transition: opacity 0.2s ease;

        &.responsive {
            transition: opacity 0.2s ease;
        }
    }

    &.exitDone {
        opacity: 0;
    }
}