@use "@styles/helpers" as *;

.root {
    margin: 24px -20px 8px;
    padding: 0 20px 8px;

    @include breakpoint(md) {
        margin: 4px -20px;
        padding: 4px 20px;
    }

    .viewMoreItem {
        height: 100%;
    }
}

// Header

.header {
    @include flex(center, space-between);
    
    &:not(.empty) {
        margin-bottom: 16px;
        margin-top: 16px;
        min-height: 34px;
    }

    @include breakpoint(md) {
        &:not(.empty) {
            margin-bottom: 24px;
            margin-top: 32px;
        }   

        &.withSubtitle {
            @include flex(flex-start, space-between);
        }
    }   
}

// --- Icon

.icon {
    @include flex(center, center);

    margin-right: 8px;
}

// --- Title

.titleContainer {
    @include truncate-text(100%);

    flex-grow: 1;
}

.title {
    @include typography(18px, 20px, 500, 500);
    @include truncate-text(100%);

    color: $color-gray-dark;
}

.skeleton {
    display: block;
    max-width: 320px;
}

// --- Subtitle

.subtitle {
    @include typography(body-small);

    color: $color-gray-semi;
    display: none;

    @include breakpoint(sm) {
        display: block;
        white-space: normal;
    }
}

.subtitleMobile {
    @include typography(body-small);

    color: $color-gray-semi;
    margin-bottom: 16px;

    @include breakpoint(sm) {
        display: none;
    }
}

.imageWrapper {
    display: none;

    @include breakpoint(sm) {
        display: block;
    }

    @include with-hocus {
        .image {
            transform: scale(101%) translate3d(0,0,0);
        }
    }
}

.image {
    @include rounded-corners;
    @include flex(center, center);

    transform: scale(100%) translate3d(0,0,0);
    transition: transform 0.3s ease;
    position: relative;
    height: 100%;
    overflow: hidden;
}

// Exports

:export {
    breakpoint: $screen-sm-min;
}
