@use "@styles/helpers" as *;

.root {
    display: flex;
    flex-direction: column;
    padding-left: 0;

    .header {
        padding: 0;
    }
}

.content {
    list-style-type: none;
    overflow: hidden;

    &.indented {
        padding-left: 16px;
    }

    &.indented.withImage {
        padding-left: 40px;
    }
}

.list {
    display: flex;
    flex-direction: column;

    .item {
        @include typography(body-small);

        line-height: normal;

        .link {
            color: $color-gray-semi;

            &:focus,
            &:hover {
                color: $color-primary-shade-2;
            }

            &.active {
                color: $color-primary;
            }

            &.indented.withImage {
                line-height: 1.4;
            }
        }

        &:last-of-type {
            .link {
                padding-bottom: 24px;
            }
        }
    }
}