/* stylelint-disable max-nesting-depth */
@use "@styles/helpers" as *;

// Default

.root.default {
    @include flex(center, space-between, row);
    
    flex-wrap: wrap;
    width: calc(100% + 8px);
    margin: 0 -4px -8px;
    padding: 0;

    .stat {
        align-self: stretch;
    }

    @include breakpoint(md, max) {
        .stat {
            flex: auto;
            width: calc(33% - 8px);
            margin: 0 4px 8px;
    
            &:nth-child(1),
            &:nth-child(2) {
                width: calc(50% - 8px);
            }
    
            &:not(:nth-child(1), :nth-child(2)) {
                .icon {
                    > svg {
                        color: $color-gray-semi;
                    }
                }
            }
        }
    }

    @include breakpoint(md) {
        width: calc(100% + 48px);
        margin: 0 -24px;
        padding: 0 12px;

        .stat {
            flex: 1;
            width: auto;
            margin: 0 12px;
        }
    }
}

// Minimal

.root.minimal {
    @include flex(flex-start, flex-start, row);

    .stat {
        flex: 0;

        .value {
            margin-top: 0;
        }

        &:not(:first-child) {
            margin-left: 8px;
        }
    }

    @include breakpoint(md) {
        .stat {
            &:not(:first-child) {
                margin-left: 8px;
            }
        }
    }
}
