@use "@styles/helpers" as *;

// Root

.root.modal {
    .header {
        background-color: $color-white;
        
        &:not(.withoutBreadcrumbs) {
            .title {
                min-height: 44px;
            }

            @include breakpoint(md) {
                .title {
                    min-height: 32px;
                }
            }
        }
    }

    .inner {
        z-index: 1;
        padding-top: 16px !important;
        padding-bottom: calc(80px + 24px + env(safe-area-inset-bottom)) !important;

        @include breakpoint(md) {
            padding-top: 0 !important;
            padding-bottom: 24px !important;
        }

        &.withoutHeader {
            @include breakpoint(md) {
                padding-top: 24px !important;
            }
        }
    }
}

// Breadcrumbs

.breadcrumbs {
    @include flex(center, flex-start, row);
    @include typography(body-small);

    margin-right: 16px;
    min-height: 30px;

    .breadcrumbsContainer {
        margin-top: 8px;
        padding-bottom: 8px;
    }

    @include breakpoint(md) {
        display: flex;
        
        .breadcrumbsContainer {
            margin-top: 0;
            padding-bottom: 0;
        }
    }
}
