@use "@styles/helpers" as *;

.root {
    @include flex(flex-start, flex-start, column);

    &:hover {
        .navBtn {
            color: $color-primary-shade-1;
        }
    }
}

.imageContainer {
    @include flex(center, center);

    img {
        @include rounded-corners(20px);
    }
}

.date {
    @include typography(body);

    margin: 12px 0 4px;
    color: $color-gray-semi;
}

.title {
    @include typography(h5);

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $color-gray-dark;
    line-height: 18px;
    min-height: 36px;

    @include breakpoint(md) {
        line-height: 20px;
        min-height: 40px;
    }
}

.navBtn {
    margin-top: 8px;
    padding: 0;
    color: $color-primary;

    &:hover {
        color: $color-primary-shade-1;
    }
}
