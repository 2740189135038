@use "@styles/helpers" as *;

.anchor {
    position: relative;
    width: 100%;
    height: 1px;
}

.root {
    @include flex(center, space-between, row);

    position: relative;
    z-index: 2;
    width: 100%;

    &.sticky {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;

        &:not(.hasTitle) {
            width: auto;
            right: 0;
        }
    }

    &.bordered {
        border-bottom: 1px solid $color-gray-light;
    }

    &.medium {
        &.withPadding {
            padding: 16px;
        }

        &.withPadding.responsive {
            padding: 16px;
        }

        .icon {
            height: 32px;
        }

        .title {
            @include typography(18px, 20px, 500, 500);

            line-height: 32px;
        }
    }

    &.large {
        &.withPadding {
            padding: 24px; 
        }

        &.withPadding.responsive {
            padding: 24px 16px;
        }

        .icon {
            height: 32px;
        }

        .title {
            @include typography(18px, 26px, 500, 500);

            line-height: 32px;
        }
    }

    // Responsive

    &.responsive {
        &.sticky.stuck {
            .closeButton {
                box-shadow: 0 8px 16px rgb(0 0 0 / 4%);
                background: $color-white;
            }
        }

        &.medium {
            .icon {
                height: 28px;
            }

            .title {
                line-height: 28px;
            }

            &.withPadding {
                padding: 16px;
            }
        }
    
        &.large {
            .icon {
                height: 28px;
            }
            
            .title {
                line-height: 28px;
            }

            &.withPadding {
                padding: 24px 16px;
            }
        }
    }
}

// Icon

.icon {
    @include flex(center, center, row);

    position: relative;
    padding-right: 12px;
    color: $color-primary;
    transition: all 0.2s ease;
}

// Title

.titleWrapper,
.title {
    line-height: 1.6;
}

.titleWrapper {
    @include flex(center, flex-start, row);

    flex-grow: 1;

    &:not(.btn) {
        .icon {
            border: none;
            background-color: transparent;
        }
    }
    
    &.btn {
        border: none;
        background-color: transparent;
        cursor: pointer;

        &:hover {
            .icon {
                transform: translateX(-4px);
            }
        }
    }
}

.title {
    flex-grow: 1;
    min-width: 0;
    line-height: 1;
    text-align: start;
    color: $color-gray-dark;
}

// Close Button

.closeButton {
    color: $color-primary;

    &:hover {
        background: $color-gray-light-ultra;
    }
}

// Exports

:export {
    breakpoint_sm: $screen-sm-min;
    breakpoint_md: $screen-md-min;
    breakpoint_lg: $screen-lg-min;
}
