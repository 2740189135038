@use "@styles/helpers" as *;

.root {
    padding: 16px;
    background-color: $color-white;
}

.discounts {
    margin-top: -4px;
    margin-bottom: 8px;
    padding-right: 16px;
    
    .discountLabel {
        @include flex(center, flex-end, row);
        @include typography(14px, 14px, 400, 400);

        color: $color-gray-dark-subtle;
        
        span {
            @include typography(16px, 16px, 600, 600);

            position: relative;
            display: inline-block;
            margin-left: 8px;
            font-weight: 600;
            color: $color-discount;
        }
    }
}

.btn {
    width: 100%;

    .btnContent {
        @include flex(center, space-between);

        width: 100%;
    }

    .btnLabel {
        @include flex(center, flex-start, row);

        .icon {
            margin-left: 4px;
        }
    }

    .btnSubLabel {
        @include flex(center, flex-end, row);

        flex-grow: 1;
        line-height: 1;

        .text {
            @include typography(14px, 14px, 400, 400);

            margin-right: 8px
        }

        .price {
            @include typography(16px, 16px, 600, 600);
        }
    }

    .icon {
        flex-grow: 0;
        flex-shrink: 1;
    }
}