@use "@styles/helpers" as *;

.root {
    @include flex(center, center, column);

    padding: 16px;
    max-width: 550px;
    margin: 0 auto;

    @include breakpoint(sm) {
        padding: 32px 16px;
    }
}

.imageContainer {
    margin-bottom: 16px;

    img {
        max-width: 100%;
        height: auto;
    }

    @include breakpoint(sm) {
        margin: 0 24px 16px;
        max-width: unset;
    }
}

.title {
    @include typography(h5);

    text-align: center;
    margin-bottom: 8px;
    word-break: break-word;
}

.subtitle {
    @include typography(body);

    color: $color-gray-semi;
    text-align: center;
    word-break: break-word;
}

.content {
    margin: 24px 0;

    @include breakpoint(sm) {
        margin: 40px 0;
    }
}
