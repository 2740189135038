@use "@styles/helpers" as *;

.root {
    &.modal {
        .modalContent {
            max-height: 75vh;
            height: auto;
        }

        .modalHeader {
            .title {
                @include typography(h4);

                margin-top: 8px;
            }
        }

        @include breakpoint(md, max) {
            .modalInner {
                display: flex;
                flex-direction: column;
                margin: 0;
                padding: 0;
            }
        }

        @include breakpoint(md) {
            .modalContent {
                max-width: 640px !important;
                max-height: inherit;
            }
        }
    }
}

// Body

.body {
    .children {
        padding: 16px 16px 0;

        @include breakpoint(md) {
            padding: 24px 24px 0;
        }
    }
}

// Description

.description {
    @include typography(14px, 16px, 400, 400);

    color: $color-gray-dark-subtle;

    &.error {
        color: $color-error;
    }
}

// List

.list {
    margin: 16px -16px 0;

    @include breakpoint(sm) {
        margin: 24px -24px 0;
    }
}

// --- Radio

.order {
    @include flex(flex-start, flex-start, column);

    border-top: 1px solid $color-gray-light;
    
    .radio {
        padding: 16px;
        width: 100%;
    
        .radioIcon,
        .radioLoader {
            margin-top: 8px;
        }
    
        &.disabled {
            opacity: 1;
    
            .header {
                .title {
                    opacity: 0.5;
                }
            }
        }
    
        @include breakpoint(md) {
            padding: 16px 24px;
        }
    }
}


// --- Errors / Warnings

.errors,
.warnings {
    margin-bottom: 8px;
    padding: 0 16px 0 60px;
    width: 100%;

    .error,
    .warning {
        @include typography(body-small-500);

        padding: 4px 16px;

        &:first-child {
            padding-top: 8px;
            border-radius: 8px 8px 0 0;
        }

        &:not(:first-child) {
            border-radius: 0;
        }

        &:last-child {
            margin-bottom: 16px;
            padding-bottom: 8px;
            border-radius: 0 0 8px 8px;
        }
    }

    @include breakpoint(md) {
        padding: 0 24px 0 68px;
    }
}

// --- Accordion

.accordion {
    flex-grow: 1;
    padding-right: 0;

    .header {
        @include flex(flex-start, space-between, row);

        padding: 0;
        min-height: 36px;
    }

    .headerInner {
        align-self: stretch;
    }

    .title {
        @include typography(14px, 14px, 500, 500);

        padding-right: 16px;
    }

    .iconWrapper {
        margin-top: 4px;
        padding: 2px;
        background-color: $color-gray-light-ultra;
        border-radius: 8px;
        color: $color-primary;
        cursor: pointer;
        transition: all .2s ease;

        .icon {
            background-color: transparent !important;
            color: $color-primary;
        }

        &:hover,
        &:active {
            background-color: $color-gray-light;
        }
    }

    .icon {
        @include rounded-corners(4px);

        flex-shrink: 0;
        transform-origin: 50% 50%;
        transition: background-color 0.2s ease;

        &:hover {
            background: $color-white;
        }
    }

    .details {
        @include rounded-corners;

        margin-top: 8px;
        padding: 8px 16px;
        background-color: $color-gray-light-ultra;
        justify-content: flex-start;
    }
}

// Voucher disclaimer

.voucherDisclaimer {
    @include flex(center);
    @include typography(body-small);


    border-top: 1px solid $color-gray-light;
    color: $color-gray-dark-subtle;
    padding: 16px;
    margin: 0 -16px;

    .voucherDisclaimerAlert {
        padding: 0;
    }

    .voucherDisclaimerIcon {
        color: $color-warning;
    }

    @include breakpoint(sm) {
        margin: 0 -24px;
        padding: 16px 22px;
    }
}

// Exports

:export {
    breakpoint: $screen-md-min;
}
