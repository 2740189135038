// Typography

@for $i from 8 through 44 {
    .text-#{$i} {
        font-size: $i * 1px;
    }
}

.text-semibold {
    font-weight: 600;
}

.text-capitalize {
    text-transform: capitalize;
}

// Spacing

@for $i from 1 through 20 {
    $unit: $i * 2;

    .p-#{$unit} {
        padding: $unit * 1px;
    }

    .pt-#{$unit} {
        padding-top: $unit * 1px;
    }

    .pr-#{$unit} {
        padding-right: $unit * 1px;
    }

    .pb-#{$unit} {
        padding-bottom: $unit * 1px;
    }

    .pl-#{$unit} {
        padding-left: $unit * 1px;
    }

    .py-#{$unit} {
        padding: $unit * 1px 0;
    }

    .px-#{$unit} {
        padding: 0 $unit * 1px;
    }

    .m-#{$unit} {
        margin: $unit * 1px;
    }

    .mt-#{$unit} {
        margin-top: $unit * 1px;
    }

    .mr-#{$unit} {
        margin-right: $unit * 1px;
    }

    .mb-#{$unit} {
        margin-bottom: $unit * 1px;
    }

    .ml-#{$unit} {
        margin-left: $unit * 1px;
    }

    .my-#{$unit} {
        margin: $unit * 1px 0;
    }

    .mx-#{$unit} {
        margin: 0 $unit * 1px;
    }
}

.no-margin {
    margin: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.overlay {
    position: fixed;
    inset: 0;
}

// Flexbox

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.align-items-end {
    align-items: flex-end;
}

.align-items-start {
    align-items: flex-start;
}

.align-items-baseline {
    align-items: baseline;
}

.align-items-stretch {
    align-items: stretch;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-start {
    justify-content: flex-start;
}

.justify-content-end {
    justify-content: flex-end;
}

.justify-content-around {
    justify-content: space-around;
}

.justify-content-between {
    justify-content: space-between;
}

.flex-grow {
    flex-grow: 1;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.flex-row-reverse {
    flex-direction: row-reverse;
}

.flex-column-reverse {
    flex-direction: column-reverse;
}

// Cursor

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}
