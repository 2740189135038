@use "@styles/helpers" as *;

.root {
    @include flex(center, center, column);

    .title {
        @include typography(16px, 18px, 500, 500);
    }

    .description {
        @include typography(14px, 14px, 400, 400);

        &:not(:first-child) {
            margin-top: 8px;
        }
    }

    .list {
        @include flex(center, center, row);

        flex-wrap: wrap;
        text-align: center;
        margin-top: -12px;

        &:not(:first-child) {
            margin-top: 24px;
        }

        .item {
            margin-top: 12px;
            margin-left: 24px;
            margin-right: 24px;

            .icon {
                @include flex(center, center, row);
    
                width: 64px;
                height: 64px;
                margin-bottom: 8px;
                padding: 12px;
                border-radius: 12px;
                background-color: $color-gray-light-ultra;

                img {
                    max-width: 100%;
                }
            }
    
            .value {
                @include typography(18px, 22px, 700, 700);
            }
    
            .label {
                @include typography(14px, 14px, 400, 400);
            }
        }
    }

    &:not(.centered) {
        @include flex(flex-start, center, column);

        .list {
            @include flex(flex-start, center, row);

            text-align: start;

            &:not(:first-child) {
                margin-top: 32px;
            }

            .item {
                margin-left: 0;
                margin-right: 48px;
            }
        }
    }
}