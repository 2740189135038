@use "@styles/helpers" as *;

.root {
    @include typography(14px, 16px, 500, 500);
    @include clamp(1, 28);

    padding: 0 14px;
    border-radius: 0 8px 8px 0;
    letter-spacing: .5px;

    &.rounded {
        border-radius: 8px;
    }

    @include breakpoint(md) {
        @include clamp(1, 32);

        padding: 0 16px;
    }
}