@use "@styles/helpers" as *;

.root {
    @include rounded-corners;

    padding: 0 !important;

    &.bordered {
        border: 1px solid $color-gray-light;
    }

    // Header

    .header {
        padding: 12px 24px;

        .inner {
            width: 100%;

            > * {
                width: 100%;
            }
        }
    }

    .title {
        @include flex(center, space-between, row);

        min-height: 32px;
    }

    .label {
        @include flex(center, flex-start, row);

        font-weight: 500;
        color: $color-gray-dark;
    }

    .count {
        @include flex(center, center);
        @include typography(small);

        width: 24px;
        height: 24px;
        margin-left: 8px;
        border-radius: 50%;
        line-height: 24px;
        font-weight: 600;
        background: $color-primary;
        color: $color-white;
    }

    // Content

    .noResults {
        padding: 0 20px;
    }

    .scrollbar {
        max-height: 242px;
        padding-bottom: 12px;
    }

    .search {
        padding: 0 16px;
    }

    .option {
        @include typography(body-small);

        padding: 8px 16px;
        border-radius: 0;
    }
}

:export { 
    breakpointXsm: 360px;
}