@use "@styles/helpers" as *;

.root {
    @include flex(flex-end, flex-start, row);

    .originalPrice {
        @include typography(body);

        margin-right: 8px;
        margin-bottom: 1px;
        color: $color-gray-semi;
    }

    .price {
        @include typography(h5);

        color: $color-gray-dark;

        &.discounted {
            color: $color-discount;

            ~ .perServingLabel {
                color: $color-discount;
            }
        }
    }

    .perServingLabel {
        @include typography(h5);

        color: $color-gray-semi;
    }
}

.isQuickView {
    @include breakpoint(md){
        .originalPrice {
            @include typography(14px, 14px, 500, 500);
        }

        .price {
            &.discounted {
                @include typography(14px, 18px, 500, 500);
            }
            
            ~ .perServingLabel {
                @include typography(14px, 14px, 500, 500);
            }
        }
    }
}