@use "@styles/helpers" as *;

// Prefix

.prefix {
    @include typography(body-small-500);

    color: $color-gray-dark;
    margin-right: 8px;
    line-height: 24px;
}

// Popover

.popoverContainer {
    z-index: z("popover-body");
    margin-top: 16px;
}

.popover {
    padding: 16px 0;
    min-width: 230px;
}

.popoverTitle {
    @include typography(body-500);

    color: $color-gray-dark-subtle;
    padding: 0 16px;
    margin-bottom: 8px;
}

// Select Button

.selectBtn {
    @include button-reset;
    @include typography(body-small);

    color: $color-gray-dark-subtle;
    padding: 0 8px;
    line-height: 24px;

    &:hover {
        color: $color-primary;

        .chevronIcon {
            color: $color-primary;
        }
    }
}

// Icons

.chevronIcon {
    color: $color-gray-semi;
}
