@use "@styles/helpers" as *;

.root {
    position: relative;
    overflow: hidden;
    transition: all 0.2s ease;

    &.expanded {
        overflow: visible;
        height: auto !important;
        max-height: initial !important;

        .expand {
            display: none;
        }
    }

    &:not(.desktop) {
        @include breakpoint(md) {
            overflow: visible;
            height: auto !important;
            max-height: initial !important;

            .expand {
                display: none;
            }
        }
    }

    &:not(.mobile) {
        @include breakpoint(md, max) {
            overflow: visible;
            height: auto !important;
            max-height: initial !important;

            .expand {
                display: none;
            }
        }
    }
}

.content {
    position: relative;
}

.expand {
    @include flex(center, flex-end);

    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(to right, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 100%) 50%, rgb(255 255 255 / 100%) 100%);
    text-align: end;
    padding: 0 0 0 150px;
    pointer-events: none;
}

.expandBtn {
    padding: 0;
    background: none !important;
    pointer-events: all;
}
