@use "@styles/helpers" as *;

.root {
    @include flex(center, flex-start, row);

    flex: 1;
    flex-wrap: wrap;
    width: 100%;

    .name {
        @include typography(h4);

        margin-right: 16px;
        margin-bottom: 8px;
    }

    .badges {
        @include flex(center, flex-start, row);

        flex-wrap: wrap;
        margin-left: -4px;

        .badge {
            margin-right: 8px;
            margin-bottom: 8px;
        }
    }

    @include breakpoint(md) {
        @include flex(center, flex-start, row);

        width: auto;

        .name {
            margin-bottom: 0;
        }

        .badges {
            @include flex(center, flex-start, row);

            margin-top: 0;
            margin-left: 0;

            .badge {
                margin-right: 0;
                margin-bottom: 0;
            }

            .badge:not(:first-child) {
                margin-left: 8px;
            }
        }
    }
}
