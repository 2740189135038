@use "@styles/helpers" as *;

.root {
    @include flex(center, center, row);
}

.image {
    max-width: 340px;
}

.title {
    @include typography(body-500);

    margin-top: 16px;
    max-width: 300px;
}