@use "styles/helpers" as *;

.header {
    margin: 24px 0 16px;
    
    @include breakpoint(md) {
        margin: 24px 0;
    }

    .title {
        @include breakpoint(md) {
            @include typography(18px, 20px, 500, 500);
        }
    }
}
