@use "@styles/helpers" as *;

.root {
    @include rounded-corners;

    display: block;
    width: 100%;
    font-size: 0;
    padding: 0;
    border: none !important;
    overflow: hidden;

    &:not(.simple) {
        @include breakpoint(sm) {
            padding-bottom: 38.8%;

            &.rendered {
                padding: 0;
            }
        }
    }

    .content {
        @include rounded-corners;

        height: 100%;
        background: $color-gray-light;
        overflow: hidden;
    }

    &.hoverable {
        @include breakpoint(sm) {
            .content {
                transform: scale(100%) translate3d(0,0,0);
                transition: transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);
            }

            .image {
                transform: scale(100%) translate3d(0,0,0);
                transition: transform 0.3s ease;
            }
    
            &:hover {
                .overlay {
                    opacity: 1;
                }

                // .content {
                //     transform: scale(99%) translate3d(0px,0px,0px);
                // }

                .image {
                    transform: scale(101%) translate3d(0,0,0);
                }
            }
        }
    }

    &.hideOnResponsive {
        @include breakpoint(sm, max) {
            display: none;
        }
    }

    &.hideOnDesktop {
        @include breakpoint(sm) {
            display: none;
        }
    }
}

.responsiveImage {
    width: 100%;
}

.image {
    width: 100% !important;
    height: auto !important;
    opacity: 1;
    transition: opacity .4s ease .2s;

    &.hidden {
        opacity: 0;
    }

    &.rounded {
        @include rounded-corners;

        overflow: hidden;
    }
}

.overlay {
    @include pos-fill(0);

    pointer-events: none;
    background-color: rgb(0 0 0 / 10%);
    opacity: 0;
    transition: opacity .2s ease;
}

.loader {
    @include pos-fill(0);

    line-height: 1;
    pointer-events: none;

    .skeleton {
        width: 100%;
        height: 100%;
    
        .skeletonInner {
            width: 100%;
            height: 100%;
        }
    }
}
