@use "@styles/helpers" as *;

.root {
    @include flex(flex-start, flex-end, row);

    @include breakpoint(450px, max) {
        @include flex(flex-start, center, column);
    }
}

.inner {
    @include flex(flex-start, center, row);
}

.imageContainer {
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid $color-secondary-shade-2;
    overflow: hidden;

    .imageInner {
        border: 6px solid white;
        border-radius: 16px;
        overflow: hidden;
        background-color: white;
    }
}

.content {
    @include flex(flex-start, space-between, column);

    max-width: 300px;
    margin-left: 32px;
    padding: 4px 0;
    align-self: stretch;

    .title {
        @include typography(18px, 20px, 700, 700);

        color: $color-gray-dark;
    }

    .date {
        @include typography(14px, 14px, 400, 400);

        color: $color-gray-semi;
    }

    @include breakpoint(450px, max) {

        @include flex(flex-start, center, column);
    }
}

.action {
    margin-top: 8px;

    @include breakpoint(450px, max) {
        width: 100%;
        margin-top: 24px;
    }
}

.btn {
    .btnText,
    .btnIcon {
        transition: all 0.2s ease;
    }

    &:hover {
        background: $color-secondary;

        .btnText {
            transform: translateX(2px);
        }

        .btnIcon {
            transform: translateX(5px);
        }
    }
}

:export {
    breakpoint: 450px;
}
