@use "@styles/helpers" as *;

.root {
    @include flex(center, space-between);

    padding: 8px 0;

    @include breakpoint(md) {
        padding: 16px 0;
    }
}
