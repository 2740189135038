@use "@styles/helpers" as *;

.root {
    @include flex(center, center, column);


    .line {
        .text {
            bold {
                font-weight: 500;
            }
        }
        
        &.withImage {
            @include flex(center, center, column);
            
            width: 100%;

            .image {
                width: 100%;
                max-width: 80px;
    
                img {
                    width: 100%;
                }
            }

            .text {
                margin-top: 8px;
            }
        }

        &.small {
            &:not(:first-child) {
                margin-top: 8px;

                &.withImage {
                    margin-top: 32px;
                }
            }

            .text {
                @include typography(body);
            }
        }

        &.medium {
            &:not(:first-child) {
                margin-top: 24px;

                &.withImage {
                    margin-top: 32px;
                }
            }

            .text {
                @include typography(16px, 18px, 500, 500);
            }
        }

        &.large {
            &:not(:first-child) {
                margin-top: 8px;
            }

            .text {
                @include typography(28px, 32px, 700, 700);

                color: $color-primary;
            }
        }
    }

    &:not(.centered) {
        @include flex(flex-start, center, column);

        .line {
            &.withImage {
                @include flex(center, flex-start, row);
                
                .text {
                    margin-top: 0;
                    margin-left: 24px;
                }
            }
        }
    }
}