@use "@styles/helpers" as *;

.group {
    &:not(:first-of-type) {
        margin-top: 16px;
        padding-top: 16px;

        &.bordered {
            border-top: 1px solid $color-gray-light;
        }
    }
}
