@use "@styles/helpers" as *;

.root {
    .content {
        @include rounded-corners;

        height: auto;
        width: 292px;
        min-height: 0;
        min-width: 0;
    }
}

// Main

.main {
    @include flex(center, center, column);
    @include typography(h6);
}

// --- Title

.title {
    text-align: center;
    margin-top: 16px;
    padding: 0 30px;
}

// --- Subtitle

.subtitle {
    @include typography(body);

    color: $color-gray-semi;
    text-align: center;
    margin-top: 8px;
}

// Actions

.actions {
    @include flex(center, center);

    margin-top: 24px;
}

.btn {
    width: 126px;
    margin: 0 4px;
    flex: 1;
}

// --- Cancel

.cancelButton {
    background: $color-gray-light-ultra;
    color: $color-gray-dark-subtle;

    &:hover {
        background: $color-gray-light;
    }
}

// --- Confirm

.confirmButton {
    background: $color-primary;
    color: $color-white;

    &:hover {
        background: $color-primary-shade-1;
    }
}

// --- Warning

.warningConfirmButton {
    background: $color-error;
    color: $color-white;

    &:hover {
        background: $color-error-shade-1;
    }
}

// Export

:export {
    confirmColor: $color-primary;
    warningColor: $color-error;
    whiteColor: $color-white;
}
