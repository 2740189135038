@use "@styles/helpers" as *;

// Steps

.steps {
    margin-top: 24px;

    @include breakpoint(md) {
        margin-top: 32px;
    }
}

.step {
    @include flex(flex-start);

    &:not(:first-child) {
        margin-top: 32px;
    }

    .index {
        @include flex(center, center);
        @include typography(body-small-500);

        flex-shrink: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        line-height: 30px;
        color: $color-primary;
        background: $color-secondary;
    }

    .text {
        @include flex(center, center, row);
        @include typography(14px, 18px, 400, 400);

        min-height: 32px;
        margin-left: 16px;
    }
}

