@use "@styles/helpers" as *;

.root {
    @include typography(body);

    line-height: 1.5;
    color: $color-gray-dark-subtle;

    p,
    ul,
    ol,
    h1,
    h2,
    h3 {
        color: $color-gray-dark-subtle;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 8px;

        &:not(:first-child) {
            margin-top: 8px;
        }
    }

    p, 
    ul,
    ol {
        @include typography(body);
    }

    a {
        color: $color-primary;
        transition: all .2s ease;

        &:hover {
            color: $color-primary-shade-2;
        }
    }

    img {
        @include rounded-corners;

        max-width: 100%;
        margin: 24px 0;
    }

    ul {
        margin-top: 4px;
        margin-bottom: 8px;
        padding-left: 24px;
    }

    ol {
        margin-top: 4px;
        margin-bottom: 8px;
        padding-left: 24px;
    }

    ul li {
        list-style: initial;
    }

    h1 {
        @include typography(22px, 28px, 500, 500);
    }

    h2 {
        @include typography(20px, 24px, 500, 500);
    }

    h3 {
        @include typography(18px, 20px, 500, 500);
    }

    @include breakpoint(md) {
        > ul {
            margin-top: 4px;
            margin-bottom: 8px;
            padding-left: 24px;
        }

        > ol {
            margin-top: 4px;
            margin-bottom: 8px;
            padding-left: 24px;
        }
    }
}