@use "@styles/helpers" as *;

.root {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;
    padding: 0 8px;

    @include breakpoint(sm) {
        margin: 0 -24px;
        padding: 0 16px;
    }
}

.root:not(.wide) .itemWrapper {
    margin: 8px;
    flex-shrink: 0;
    width: calc(50% - 16px);

    &:nth-of-type(3),
    &:nth-of-type(4),
    &:nth-of-type(5),
    &:nth-of-type(6) {
        display: none;
    }

    @include breakpoint(sm) {
        margin: 12px;
        width: calc(33% - 24px);

        &:nth-of-type(3) {
            display: block;
        }
    }

    @include breakpoint(960px) {
        width: calc(25% - 24px);

        &:nth-of-type(4) {
            display: block;
        }
    }

    @include breakpoint(1440px) {
        width: calc(33% - 24px);

        &:nth-of-type(4) {
            display: none;
        }
    }

    @include breakpoint(1600px) {
        width: calc(25% - 24px);

        &:nth-of-type(4) {
            display: block;
        }
    }
}

.root.wide .itemWrapper {
    margin: 8px;
    flex-shrink: 0;
    width: calc(50% - 16px);

    &:nth-of-type(3),
    &:nth-of-type(4),
    &:nth-of-type(5),
    &:nth-of-type(6) {
        display: none;
    }

    @include breakpoint(sm) {
        margin: 12px;
        width: calc(33% - 24px);

        &:nth-of-type(3) {
            display: block;
        }
    }

    @include breakpoint(960px) {
        width: calc(25% - 24px);

        &:nth-of-type(4) {
            display: block;
        }
    }

    @include breakpoint(1366px) {
        width: calc(20% - 24px);

        &:nth-of-type(5) {
            display: block;
        }
    }

    @include breakpoint(1440px) {
        width: calc(25% - 24px);

        &:nth-of-type(5) {
            display: none;
        }
    }

    @include breakpoint(1600px) {
        width: calc(20% - 24px);

        &:nth-of-type(5) {
            display: block;
        }

        &:nth-of-type(6) {
            display: none;
        }
    }

    @include breakpoint(1800px) {
        width: calc(16.66% - 24px);

        &:nth-of-type(6) {
            display: block;
        }
    }
}
