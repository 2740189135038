@use "@styles/helpers" as *;

.root {
    &.fullscreen {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    
        .container {
            flex-grow: 1;
            width: 100%;
            position: relative;
        }
    }
}
    
.container {
    max-width: $container-max-width;
    padding: $container-padding 0;
    margin: 0 auto;
    display: flex;
    
    @include breakpoint(md) {
        padding: $container-padding 24px;
        min-height: 50vh;
    }
}
    
.content {
    padding: 0 16px;
    flex-grow: 1;
    overflow-x: hidden;
    
    @include breakpoint(md) {
        margin: 0 -16px;
        visibility: hidden;
        opacity: 0;
    
        &.rendered {
            visibility: visible;
            opacity: 1;
        }
    }
}    
