@use "@styles/helpers" as *;

.root {
    @include flex(center, center, row);

    .actions {
        width: auto;

        &:not(:first-child) {
            margin-left: 8px;
        }

        .add,
        .edit,
        .adjust {
            height: 36px;
        }

        .edit {
            .quantityButton {
                width: 36px;
                min-width: 36px;
                height: 36px;
                border: none;
            }
        
            .quantityInput {
                width: 42px;
                height: 36px;
                margin: 0 8px;
            }
        }
    }

    .actionButton {
        height: 36px;
    
        &:not(:first-child) {
            margin-left: 8px;
        }
    
        .actionBtnContent {
            padding: 4px;
        }
    
        &.fixedWidth {
            width: 42px;
        }
    }
}
