@use "@styles/helpers" as *;

.modal {
    .modalContent {
        max-height: 80vh !important;
        height: auto !important;
        border-radius: 8px 8px 0 0 !important;
    }
}

.modalBody {
    padding: 24px;
    padding-bottom: calc(40px + env(safe-area-inset-bottom));
}

.popover {
    width: auto;
    min-width: 270px;
    max-width: 350px;
}

.popoverBody {
    padding: 24px;
}

.popoverTitle {
    @include typography(body-500);

    color: $color-gray-dark-subtle;
    margin-bottom: 16px;
}

.invoiceBtn {
    @include breakpoint(lg) {
        width: max-content;
    }
}

.invoiceLabel {
    display: block;
    @include truncate-text(300px);
}

.invoice {
    width: 100%;

    &:not(:first-of-type) {
        margin-top: 16px;
    }

    &:focus {
        border-color: initial;
    }
}

:export {
    breakpoint: $screen-md-min;
}
