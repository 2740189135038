@use "@styles/helpers" as *;

.root {
    margin: 24px 0;

    @include breakpoint(sm) {
        margin: 32px 0 24px;
    }
}

.chevron {
    display: flex;
}

.root a {
    outline: 0;
}
