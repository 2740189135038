@use "@styles/helpers" as *;

.root {
    max-width: 592px;
    margin: 24px 0 0;
}

.title {
    @include typography(body-small);

    margin-bottom: 8px;
    color: $color-gray-semi;
}

.variant {
    border-bottom: 1px solid $color-gray-light;

    .variantTitle {
        margin-left: 8px;
        font-size: 16px;
    }

    .variantQuantity {
        display: none;
        content-visibility: hidden;
    }
}

.addBtn {
    margin-left: 16px;
}

.skeletonContainer {
    @include flex(center, center, column);

    .variant {
        @include flex(center);
    
        width: 100%;
        padding-bottom: 12px;
        margin: 10px 0;

        .imageSkeleton {
            height: 48px;
            width: 48px;
        }

        .variantNameSkeleton {
            width: 100%;
            height: 24px;
            padding: 0 24px;
        }
    }

}
