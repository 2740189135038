@use "@styles/helpers" as *;

.mobile {
    display: initial;

    &.break-at-sm {
        @include breakpoint(sm) {
            display: none;
        }
    }

    &.break-at-md {
        @include breakpoint(md) {
            display: none;
        }
    }

    &.break-at-lg {
        @include breakpoint(lg) {
            display: none;
        }
    }

    &.break-at-xl {
        @include breakpoint(xl) {
            display: none;
        }
    }
}

.desktop {
    display: none;

    &.rendered {
        &.break-at-sm {
            @include breakpoint(sm) {
                display: inherit;
            }
        }
    
        &.break-at-md {
            @include breakpoint(md) {
                display: inherit;
            }
        }
    
        &.break-at-lg {
            @include breakpoint(lg) {
                display: inherit;
            }
        }

        &.break-at-xl {
            @include breakpoint(xl) {
                display: inherit;
            }
        }
    }
}