@use "@styles/helpers" as *;

.tabs {
    -webkit-tap-highlight-color: transparent;
}

.tabList {
    border: 0;
    margin-bottom: 0;
}

.tabPanel {
    display: none;

    &.selectedPanel {
        display: block;
    }
}

.tab {
    display: inline-block;
    position: relative;
    bottom: -1px;
    list-style: none;
    cursor: pointer;

    
    &:focus {
        box-shadow: none;
    }

    @media (hover: none) and (pointer: coarse) {
        &:focus-visible {
            outline: none !important;
    
            @include breakpoint(md) {
                outline: initial !important;
            }
        }
    }
}

// Basic Tabs

.tabs.basic .tabPanel {
    border-top: 1px solid $color-gray-light;
}

.tabs.basic .tabList .tab {
    font-weight: 500;
    border: 0;
    padding: 10px 0;
    margin-right: 24px;
    color: $color-gray-semi;

    &.selectedTab {
        color: $color-primary;
        border-bottom: 2px solid $color-primary;
    }

    &:last-of-type {
        margin-right: 0;
    }
}

// Bordered Tabs

.tabs.bordered .tabPanel {
    border: 1px solid $color-gray;
    border-radius: 0 8px 8px;
    padding: 16px;
}

.tabs.bordered .tabList .tab {
    padding: 10px 16px;
    color: $color-gray-semi;
    border: 1px solid $color-gray-light;
    border-bottom-color: transparent;
    border-radius: 8px 8px 0 0;
    margin-right: 8px;

    &.selectedTab {
        background-color: $color-white;
        color: $color-gray-dark-subtle;
        border-color: $color-gray;
        border-bottom-color: transparent;
    }
}

// Centered Tabs

.tabs.centered .tabList {
    text-align: center;
}

.tabs.centered .tabList .tab {
    &:first-of-type {
        margin-left: 0;
    }
}

.content {
    position: relative;

    &.isCollapsed {
        overflow: hidden;

        &::before {
            @include pos-fill(0);

            content: "";
            pointer-events: none;
            background: linear-gradient(to top, rgb(255 255 255) 0%, $color-white 54px, rgb(255 255 255 / 0%) 100%);
        }
    }

    .collapseContainer {
        padding: 24px 16px;
        @include pos-bottom;

        border-bottom: 1px solid $color-gray-light-ultra;
        background: $color-white;

        @include breakpoint(sm) {
            padding: 24px 0;
        }
    }
}
