@use "@styles/helpers" as *;

.root {
    @include flex(flex-start, flex-start, row);
}

.labels {
    top: 6px;

    @include breakpoint(sm) {
        top: 12px;
    }
}

.content {
    @include flex(flex-start, space-between, column);

    flex: 1;
    align-self: stretch;
    padding-left: 16px;
}

.brand {
    @include typography(14px, 14px, 400, 400);

    color: $color-gray-semi;
}

.name {
    @include typography(14px, 16px, 500, 500);

    color: $color-gray-dark;
}

.link {
    display: block;
}

.secondary {
    @include flex(flex-start, flex-end, column-reverse);
    
    margin-top: 8px;
    width: 100%;

    &:not(.removed) {
        @include flex(center, space-between, row-reverse);

        margin-top: 0;
    }

    @include breakpoint(sm) {
        @include flex(center, space-between, row-reverse);

        margin-top: 0;
    }
}

.actions {
    @include flex(center, flex-end, row);

    &:not(.removed) {
        align-self: flex-end;
    }

    &.removed {
        margin-top: 8px;
    }

    @include breakpoint(sm) {
        margin-top: 0;
    }
}

.price {
    @include typography(14px, 16px, 600, 600);

    text-transform: uppercase;
}

:export {
    breakpoint: $screen-md-min;
    colorPrimary: $color-primary;
}
