@use "@styles/helpers" as *;

.root {
    @include flex(center);

    margin-top: 8px;

    @include breakpoint(sm) {
        margin-top: 0;
    }
}

.iconWrapper {
    @include flex(center, center, row);
}

// Thumbnail

.thumbnail {
    @include typography(body-small-500);
    @include flex-center;
    @include rounded-corners;

    position: relative;
    background: $color-gray-light-ultra;
    border: 1px solid $color-gray-light-ultra;
    color: $color-gray-semi;
    height: 32px;
    width: 32px;
    margin-right: 4px;
    flex-shrink: 0;
    flex-grow: 1;

    > * {
        flex-grow: 1;
    }

    &.withHover {
        cursor: pointer;
        transition: all 0.15s ease;

        &:hover {
            background: $color-gray-light;
        }
    }

    .icon {
        @include flex(center, center, row);
    }

    &.extra {
        @include flex(center, center, row);

        .text {
            text-align: center;
        }
    }

    @include breakpoint(md) {
        width: 36px;
        height: 36px;
    }
}

.thumbnail img {
    @include rounded-corners;
}

// Skeleton

.thumbnail.skeleton {
    font-size: 0;

    span {
        height: 100%;
    }
}

// Exports

:export {
    breakpoint: $screen-sm-min;
}
