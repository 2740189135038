@use "@styles/helpers" as *;

.root {
    width: 100%;
    
    // Size Mixins

    @mixin size-large {
        .label {
            &:not(:first-child) {
                margin-top: 6px;
            }
        }
    }

    @mixin size-medium {
        .label {
            &:not(:first-child) {
                margin-top: 4px;
            }
        }
    }

    @mixin size-small {
        .label {
            &:not(:first-child) {
                margin-top: 4px;
            }
        }
    }

    // Desktop Sizes

    &.large {
        @include size-large;
    }

    &.medium {
        @include size-medium;
    }

    &.small {
        @include size-small;
    }

    // Responsive Sizes

    &.break-at-sm {
        @include breakpoint(sm, max) {
            &.responsive-large {
                @include size-large;
            }

            &.responsive-medium {
                @include size-medium;
            }

            &.responsive-small {
                @include size-small;
            }
        }
    }

    &.break-at-md {
        @include breakpoint(md, max) {
            &.responsive-large {
                @include size-large;
            }

            &.responsive-medium {
                @include size-medium;
            }

            &.responsive-small {
                @include size-small;
            }
        }
    }

    &.break-at-lg {
        @include breakpoint(lg, max) {
            &.responsive-large {
                @include size-large;
            }

            &.responsive-medium {
                @include size-medium;
            }

            &.responsive-small {
                @include size-small;
            }
        }
    }
}
