@use "@styles/helpers" as *;

.root {
    @include flex(center, flex-end);

    margin-top: 8px;

    @include breakpoint(sm) {
        margin-top: 24px;
    }
}

.summary {
    width: 100%;
    padding: 16px 0;
    @include flex(flex-start, flex-start, column);

    @include breakpoint(md) {
        max-width: 352px;
    }
}

.separator {
    border-top: 1px solid $color-gray;
    margin: 8px 0;
    width: 100%;
}

.table {
    
    .row {
        padding: 8px 16px;
        line-height: normal;
    
        .key {
            color: $color-gray-dark-subtle;
        }
    
        .value {
            min-width: 70px;
            color: $color-gray-dark-subtle;
        }
    }

    @include breakpoint(md) {
        margin: 0 -16px;
        width: calc(100% + 32px);
    }
}
