@use "@styles/helpers" as *;

.retryBtn {
    width: 100%;

    @include breakpoint(md) {
        width: auto;
    }
}

:export {
    breakpoint: $screen-md-min;
}
