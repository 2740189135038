@use "@styles/helpers" as *;

.root {
    position: relative;
}

.banner {
    @include pos-fill(0);
}

.content {
    @include flex(center, center, column);

    position: relative;
    width: 100%;
    min-height: 300px;
    padding: 24px 0 0;
    z-index: 1;

    @include breakpoint(sm) {
        min-height: 350px;
    }

    @include breakpoint(md) {
        min-height: 400px;
    }

    .details {
        width: 100%;
        padding: 16px 16px 24px;
        text-align: center;
        background: rgb(255 255 255 / 80%);

        .title,
        .description {
            margin: 0 auto;
            max-width: 960px;
        }

        .title {
            @include typography(22px, 34px, 700, 700, 'sm');

            color: $color-gray-dark;
        }

        .description {
            @include typography(14px, 18px, 500, 500, 'sm');

            margin-top: 8px;
            color: $color-gray-dark-subtle;
        }
    }

    .tagsWrapper {
        @include flex(center, center);

        padding: 24px 0;
        flex: 1;
        align-self: stretch;

        .tags {
            @include flex(center, center);
            
            margin: 0 auto -4px;
            padding: 0 16px;
            flex-wrap: wrap;
            max-width: 960px;
        
        }

        .tag {
            @include flex(center, center, row);
            @include rounded-corners;
            @include typography(14px, 14px, 500, 500);
        
            position: relative;
            margin-bottom: 6px;
            padding: 6px 12px;
            color: $color-gray-dark-subtle;
            background-color: $color-white;
            border: 1px solid transparent;
            transition: background-color .2s ease;
    
            .tagLabel {
                @include truncate-text(200px);
                @include typography(12px, 14px, 500, 500);

                line-height: 1;
            }

            &:hover {
                color: $color-white;
                background-color: $color-primary;
            }
    
            &:not(:first-child) {
                margin-left: 4px;
            }
    
        }

        .tag.primary {
            color: $color-primary;

            &:hover {
                color: $color-white;
            }
        }
    }

    @include breakpoint(sm) {
        padding: 32px 0 0;

        .details {
            padding: 16px 16px 24px;

            .description {
                margin-top: 16px;
            }
        }

        .tagsWrapper {
            padding: 32px 0;
        }
    }

    @include breakpoint(md) {
        .tagsWrapper {
            margin-bottom: -16px;

            .tag {
                margin-bottom: 16px;
                padding: 8px 16px;

                &:not(:first-child) {
                    margin-left: 16px;
                }
            }
        }


    }
}

