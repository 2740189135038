@use "@styles/helpers" as *;


.root {
    .carouselHeader {
        margin-top: 0;
    }
 

    @include breakpoint(md, max) {
        .carousel {
            margin: 0 -16px;
            padding: 0;
        }

        .slidesWrapper {
            padding: 0 16px;
        }
    }

    @include breakpoint(849.99px, max) { 
        .carousel {
            .navButton {
                display: none;
            }
        }
      
    }
}
