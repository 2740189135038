@use "styles/helpers" as *;

.spotlight {
    @include flex(center, center, row);

    width: 100%;
    gap: 8px;

    .item {
        @include typography(body-small);
        
        flex: 1;
        padding: 16px 8px;
        min-width: 0;
        background-color: $color-white;
        border-radius: 16px;
        text-align: center;

        .label {
            text-align: center;
        }

        &.active {
            font-weight: 500;
        }

        &:hover {
            background-color: $color-gray-light;
        }
    }
}

.menu {
    &:not(:first-child) {
        margin-top: 12px;
    }

    .item {
        .link {
            @include typography(body-small);
    
            padding-left: 8px;
            padding-right: 8px;
            border-radius: 0;
            color: $color-gray-dark-subtle;
            transition: color 0.2s ease, background-color 0.2s ease;
    
            .icon {
                color: $color-gray-dark-subtle;
            }

            &.active {
                color: $color-primary;

                .icon {
                    color: $color-primary;
                }
            }

            &:focus,
            &:hover {
                background-color: $color-gray-light;
                color: $color-primary;
    
                .icon {
                    color: $color-primary;
                }
            }
        }

        &:last-of-type {
            .link {
                border-radius: 0 0 8px 8px;
            }
    
            .icon {
                color: $color-gray-dark-subtle;
            }
        }
    }
}
