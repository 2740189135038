@use "@styles/helpers" as *;

.root {
    @include flex(center);
}

.content {
    margin-left: 12px;
    @include flex(flex-start, center, column);
}

.title {
    @include typography(body-small-500);
    @include clamp(1, 24);

    color: $color-gray-dark;
}

.message {
    @include typography(12px, 12px, 400, 400);

    color: $color-gray-dark;
}

.thumbnail {
    @include flex(center, center);
    @include rounded-corners;

    position: relative;
    min-width: 52px;
    width: 52px;
    overflow: hidden;
    align-self: stretch;

    img {
        @include pos-fill(0);

        height: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
}