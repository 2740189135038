@use "@styles/helpers" as *;

.root {
    @include pos-fill(-1px);
    @include flex(center, center, column);

    border-radius: 8px 8px 0 0;
    z-index: z("product-card-quantity-overlay");
    background: rgb(0 0 0 / 30%);
    font-size: 14px;
    color: $color-white;
}

.count {
    font-size: 42px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 5px;
}
