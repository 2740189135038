@use "@styles/helpers" as *;

// Regular breakpoints
$breakpoint-product-list-sm: 640px;
$breakpoint-product-list-md: 960px;
$breakpoint-product-list-lg: 1366px;
$breakpoint-product-list-xl: 1440px;
$breakpoint-product-list-xxl: 1660px;
$breakpoint-product-list-xxxl: 1800px;

// Wide breakpoints
$breakpoint-product-list-wide-sm: 640px;
$breakpoint-product-list-wide-md: 900px;
$breakpoint-product-list-wide-lg: 1260px;
$breakpoint-product-list-wide-xl: 1400px;
$breakpoint-product-list-wide-xxl: 1660px;
$breakpoint-product-list-wide-xxxl: 1800px;

.root {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;
    padding: 0 8px;

    @include breakpoint(sm) {
        margin: 0 -24px;
        padding: 0 12px;
    }
}

.root:not(.wide) .itemWrapper {
    margin: 8px 4px;
    flex-shrink: 0;
    width: calc(50% - 8px);

    @include breakpoint($breakpoint-product-list-sm) {
        margin: 12px 7px;
        width: calc(100% / 3 - 14px);
    }

    @include breakpoint($breakpoint-product-list-md) {
        width: calc(25% - 14px);
    }

    @include breakpoint($breakpoint-product-list-xl) {
        width: calc(100% / 3 - 14px);
    }

    @include breakpoint($breakpoint-product-list-xxl) {
        width: calc(25% - 14px);
    }
}

.root.wide .itemWrapper {
    margin: 8px 4px;
    flex-shrink: 0;
    width: calc(50% - 8px);
    @include breakpoint($breakpoint-product-list-wide-sm) {
        margin: 12px 7px;
        width: calc(100% / 3 - 14px);
    }

    @include breakpoint($breakpoint-product-list-wide-md) {
        width: calc(25% - 14px);
    }

    @include breakpoint($breakpoint-product-list-wide-lg) {
        width: calc(20% - 14px);
    }

    @include breakpoint($breakpoint-product-list-wide-xl) {
        width: calc(20% - 14px);
    }

    @include breakpoint($breakpoint-product-list-wide-xxl) {
        width: calc(20% - 14px);
    }

    @include breakpoint($breakpoint-product-list-wide-xxxl) {
        width: calc(100% / 6 - 14px);
    }
}

.banner {
    margin: 8px;

    @include breakpoint(sm) {
        margin: 28px 8px;

        &.first {
            margin-top: 12px;
        }

        &.last {
            margin-bottom: 0;
        }
    }
}

:export {
    sm: $breakpoint-product-list-sm;
    md: $breakpoint-product-list-md;
    lg: $breakpoint-product-list-lg;
    xl: $breakpoint-product-list-xl;
    xxl: $breakpoint-product-list-xxl;
    xxxl: $breakpoint-product-list-xxxl;
    wideSm: $breakpoint-product-list-wide-sm;
    wideMd: $breakpoint-product-list-wide-md;
    wideLg: $breakpoint-product-list-wide-lg;
    wideXl: $breakpoint-product-list-wide-xl;
    wideXxl: $breakpoint-product-list-wide-xxl;
    wideXxxl: $breakpoint-product-list-wide-xxxl;
}
