@use "@styles/helpers" as *;

.root {
    width: 100%;
}

.header {
    @include rounded-corners(16px);
    
    width: 100%;

    &:not(.simplified) {
        padding: 16px;
        background-color: $color-gray-light;
    }

    .title {
        @include typography(16px, 20px, 500, 500);

        color: $color-gray-dark;
    }

    .addIngredientsBtn {
        @include typography(14px, 16px, 500, 500);
        
        margin-top: 16px;
        width: 100%;
    }

    .error {
        @include typography(12px, 14px, 500, 500);

        margin-top: 8px;
        padding: 0 4px;
        color: $color-error;

        @include breakpoint(md) {
            margin-top: 16px;
        }
    }

    .info {
        @include typography(12px, 14px, 400, 400);

        margin-top: 16px;
        padding: 0 4px;
        color: $color-gray-dark;
    }
}

.list {
    margin-top: 16px;
    border-bottom: 1px solid $color-gray-light;
}

.anchor {
    @include pos-bottom(0, fixed);

    z-index: z("bottomActions");

    .anchorContent {
        @include flex(center, space-between);
    
        width: 100%;
        background: $color-white;
        box-shadow: 0 -4px 12px 0 #3333330d;
    
        &.hidden {
            transform: translateY(100%);
            transition: all .4s $ease-out-expo;
        }
    
        &.visible {
            transform: translateY(0);
            transition: all .4s $ease-out-expo;
        }
        
        &.padded {
            padding: 16px;
            padding-bottom: calc(16px + env(safe-area-inset-bottom));
        }

        .addIngredientsBtn {
            @include typography(14px, 16px, 500, 500);

            width: 100%;
        }
    }
}

.disclaimer {
    @include typography(small);

    margin-top: 16px;
    color: $color-gray-semi;

    @include breakpoint(lg){
        margin-top: 24px;
    }
}