@use "@styles/helpers" as *;

.contentWrapper {
    min-height: 0 !important;
}

.imageWrapper {
    @include flex(center, center);

    background-color: $color-secondary;
    padding: 32px 24px;
}

.content {
    @include flex(flex-start, flex-start, column);

    @include breakpoint(sm) {
        padding: 16px;
    }

    @include breakpoint(md) {
        padding: 0;
    }

    &.hasImage {
        .title {
            margin: 0;
        }
    }
}

.header {
    @include flex(center, space-between);

    margin-bottom: 16px;

    @include breakpoint(sm) {
        padding: 0 16px;
        margin-bottom: 8px;
    }

    @include breakpoint(md) {
        padding: 0;
        margin-bottom: 24px;
    }
}

.title {
    font-size: 16px;
    font-weight: 500;

    @include breakpoint(sm) {
        font-size: 26px;
    }

    @include breakpoint(md) {
        font-size: 26px;
    }

    margin-right: 40px;
    color: $color-gray-dark;
}

.description {
    @include typography(12px, 14px, 400, 400, sm);

    color: $color-gray-dark-subtle;
}

.buttonsContainer {
    @include flex(center, flex-start, row-reverse);

    width: 100%;
    margin-top: 24px;
    flex-wrap: wrap;

    @include breakpoint(md) {
        margin-top: 32px;
    }
}

.btn,
.link {
    width: 100%;
    max-width: 300px;

    @include breakpoint(280px, max) {
        font-size: 14px;
    }

    @include breakpoint(sm) {
        width: auto;
    }
}

.link {
    text-decoration: none;
}

.btn {
    &:not(:first-child) {
        margin-left: 0;
        margin-top: 16px;
    
        @include breakpoint(sm) {
            margin-right: 16px;
            margin-top: 0;
        }
    }
}

:export {
    breakpoint: $screen-sm-min;
}
