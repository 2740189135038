@use "@styles/helpers" as *;

.pagination {
    display: flex;
    cursor: pointer;
    justify-content: center;

    @include breakpoint(sm) {
        justify-content: flex-end;
    }
}

.pagination-previous-page,
.pagination-next-page,
.pagination-page,
.pagination-page-break {
    @include flex-center;

    font-weight: 500;
    width: 40px;
    height: 40px;

    a {
        @include flex(center, center);

        width: 100%;
        height: 100%;
    }
}

.pagination-previous-page,
.pagination-next-page {
    @include rounded-corners;

    background-color: $color-primary;
    color: $color-white;
    padding: 8px;
}

.pagination-page,
.pagination-page-break {
    color: $color-primary;
    margin-left: 8px;
}

.pagination-page-break {
    pointer-events: none;
}

.pagination-disabled {
    background-color: $color-secondary-shade-2;
    cursor: not-allowed;
}

.pagination li:nth-last-child(2) {
    margin-right: 24px;
}

.pagination li:nth-child(2) {
    margin-left: 24px;
}

.pagination-page.active,
.pagination-page:hover:not(.active) {
    @include rounded-corners;

    border: 1px solid $color-primary;
}

.pagination-page.active {
    padding: 0 16px;
}
