@use "@styles/helpers" as *;

// Overlay

.overlay {
    z-index: z("modal");
}

// Anchor

.anchor {
    position: relative;
}

// Content

.content {
    &.desktop.fullScreen,
    &.responsive.fullScreen {
        width: 100%;
        height: 100%;
        max-width: unset;
        max-height: unset;
        margin: 0;

        .header {
            margin-top: 4px;
        }

        .closeButton {
            top: 32px;
        }

        &.responsive {
            .closeButton {
                top: 18px;
            }
        }
    }

    // --- Header

    .header {
        &:not(.empty) {
            padding: 24px;
            border: 0;
        }
    }

    // --- Title

    .title {
        @include typography(18px, 26px, 500, 500);

        line-height: 1.6;
        overflow: hidden;
    }

    // --- Inner

    .inner {
        overflow: hidden;
        overflow-y: auto; 
        -webkit-overflow-scrolling: touch;  

        &.padded {
            margin: 0 -24px;
            padding: 24px 48px;
        }

        &.padded.hasTitle {
            margin: 0 -24px;
            padding: 0 48px 24px;
        }
    }

    // --- Close

    .closeButton {
        position: absolute;
        top: 30px;
        right: 24px;
        margin-left: 16px;
        flex-shrink: 0;
        color: $color-primary;
        z-index: 1;
        transition: all 0.2s ease;

        .closeIcon {
            height: auto;
            width: auto;
        }

        &.fixed {
            box-shadow: 0 8px 16px rgb(0 0 0 / 4%);
            background: $color-white;
        }
    }

    &.responsive {
        .header {
            &:not(.empty) {
                padding: 16px;
                border-bottom: 1px solid $color-gray-light;
            }
        }

        .title {
            line-height: 1.5;
        }

        .inner {
            overflow: hidden auto;
            height: 100%;

            &.padded {
                margin: 0 -16px;
                padding: 40px 32px;
            }

            &.padded.hasTitle {
                margin: 0 -16px;
                padding: 0 32px 24px;
            }
        }

        .closeButton {
            top: 15px;
            right: 14px;

            .closeIcon {
                width: 20px;
                height: 20px;
            }
        }
    }

    @include breakpoint(md) {
        min-height: 200px !important;
    }
}

// Exports

:export {
    breakpoint_sm: $screen-sm-min;
    breakpoint_md: $screen-md-min;
    breakpoint_lg: $screen-lg-min;

    // TODO: remove
    sm: $screen-sm-min;
    md: $screen-md-min;
    lg: $screen-lg-min;
    xl: $screen-xl-min;
    baseZIndex: z("modal")
}
