@use "@styles/helpers" as *;

.root {
    @include typography(small-500);
    @include rounded-corners;

    padding: 4px 12px;
}

:export {
    defaultColor: $color-gray-dark-subtle;
}
