@use "@styles/helpers" as *;

.root {
    &.break-at-sm {
        @include breakpoint(sm) {
            .responsiveImage {
                display: none;
            }

            &.rendered {
                .desktopImage {
                    display: flex;
                }
            }
        }
    }

    &.break-at-md {
        @include breakpoint(md) {
            .responsiveImage {
                display: none;
            }

            &.rendered {
                .desktopImage {
                    display: flex;
                }
            }
        }
    }

    &.break-at-lg {
        @include breakpoint(lg) {
            .responsiveImage {
                display: none;
            }

            &.rendered {
                .desktopImage {
                    display: flex;
                }
            }
        }
    }
}

.responsiveImage {
    display: flex;
}

.desktopImage {
    display: none;
}

:export {
    breakpoint_sm: $screen-sm-min;
    breakpoint_md: $screen-sm-min;
    breakpoint_lg: $screen-sm-min;
    breakpoint_xlg: $screen-sm-min;
}
