@use "@styles/helpers" as *;

.root {
    @include flex(center, center, row);

    font-size: 0;
    
    > * {
        height: 100%;
    }
    
    // Sizes
    
    @mixin size-small {
        height: 14px;

        .prefix,
        .suffix {
            line-height: 14px;
        }

        .prefix {
            @include typography(extra-small-500);

            border-width: 1px !important;
            padding: 0 2px 0 4px;
        }

        .separator {
            display: none;
        }

        .suffix {
            @include typography(10px, 10px, 700, 500);

            border-width: 1px !important;
            padding: 0 4px;
        }

        &.hasOffset {
            position: absolute;
            bottom: -7px;
        }

        &:not(.hasPrefix) {
            .suffix {
                padding: 0 6px;
                border-radius: 8px;
            }
        }
    }

    @mixin size-medium {
        height: 24px;
        
        .prefix,
        .suffix {
            line-height: 24px;
        }

        .prefix {
            @include typography(small-500);

            padding: 0 16px 0 8px;
        }

        .suffix {
            @include typography(16px, 16px, 700, 700);

            padding: 0 8px 0 4px;
        }

        &.hasOffset {
            position: absolute;
            bottom: -12px;
        }

        &:not(.hasPrefix) {
            .suffix {
                padding: 0 6px 0 8px;
                border-radius: 8px;
            }
        }
    }

    @mixin size-large {
        height: 28px;

        .prefix,
        .suffix {
            line-height: 28px;
        }

        .prefix {
            @include typography(16px, 16px, 500, 500);

            padding: 0 18px 0 12px;
        }

        .suffix {
            @include typography(18px, 18px, 700, 700);
            
            padding: 0 8px 0 4px;
        }

        &.hasOffset {
            position: absolute;
            bottom: -14px;
        }

        &:not(.hasPrefix) {
            .suffix {
                padding: 0 6px 0 8px;
                border-radius: 8px;
            }
        }
    }

    &.large {
        @include size-large;
    }

    &.medium {
        @include size-medium;
    }

    &.small {
        @include size-small;
    }

    // Responsive Sizes

    &.break-at-sm {
        @include breakpoint(sm, max) {
            &.responsive-large {
                @include size-large;
            }

            &.responsive-medium {
                @include size-medium;
            }

            &.responsive-small {
                @include size-small;
            }
        }
    }

    &.break-at-md {
        @include breakpoint(md, max) {
            &.responsive-large {
                @include size-large;
            }

            &.responsive-medium {
                @include size-medium;
            }

            &.responsive-small {
                @include size-small;
            }
        }
    }

    &.break-at-lg {
        @include breakpoint(lg, max) {
            &.responsive-large {
                @include size-large;
            }

            &.responsive-medium {
                @include size-medium;
            }

            &.responsive-small {
                @include size-small;
            }
        }
    }
}

// Prefix / Suffix

.prefix,
.suffix {
    @include flex(center);

    align-self: stretch;
    line-height: 1;
}

.prefix {
    position: relative;
    border-radius: 8px 0 0 8px;
    border-top: 2px solid;
    border-left: 2px solid;
    border-bottom: 2px solid;
    z-index: 1;
}

.suffix {
    position: relative;
    left: -1px;
    border-radius: 0 8px 8px 0;
    border-top: 2px solid;
    border-right: 2px solid;
    border-bottom: 2px solid;
    letter-spacing: 0.1em;
}

// Sizes

// --- Small

.small {
    height: 14px;

    .prefix,
    .suffix {
        line-height: 14px;
    }

    .prefix {
        @include typography(extra-small-500);

        border-width: 1px !important;
        padding: 0 2px 0 4px;
    }

    .separator {
        display: none;
    }

    .suffix {
        @include typography(10px, 10px, 700, 500);

        border-width: 1px !important;
        padding: 0 4px;
    }

    &:not(.hasPrefix) {
        .suffix {
            padding: 0 6px;
            border-radius: 8px;
        }
    }
}

// --- Medium

.medium {
    height: 24px;
    
    .prefix,
    .suffix {
        line-height: 24px;
    }

    .prefix {
        @include typography(small-500);

        padding: 0 16px 0 8px;
    }

    .suffix {
        @include typography(16px, 16px, 700, 700);

        padding: 0 8px 0 4px;
    }

    &:not(.hasPrefix) {
        .suffix {
            padding: 0 6px 0 8px;
            border-radius: 8px;
        }
    }
}

// --- Large

.large {
    height: 28px;

    .prefix,
    .suffix {
        line-height: 28px;
    }

    .prefix {
        @include typography(16px, 16px, 500, 500);

        padding: 0 18px 0 12px;
    }

    .suffix {
        @include typography(18px, 18px, 700, 700);
        
        padding: 0 8px 0 4px;
    }

    &:not(.hasPrefix) {
        .suffix {
            padding: 0 6px 0 8px;
            border-radius: 8px;
        }
    }
}

// Separator

.separator {
    @include flex(center);

    position: absolute;
    right: -2px;
    height: 100%;
    align-self: stretch;
    font-size: 0;
    flex-shrink: 0;

    > * {
        position: relative;
        left: -3px;
        height: 100% !important;
    }
}

