@use "@styles/helpers" as *;


.header {
    position: sticky;
    top: 0;
    height: $mobile-header-height;
    background-color: $color-white;
    z-index: z("header");

    @include breakpoint(lg) {
        visibility: hidden;
        opacity: 0;
        height: $desktop-header-height;

        &.hasCategoriesBar {
            height: calc($desktop-header-height + $desktop-header-categories-bar-height);
        }

        &.rendered {
            visibility: visible;
            opacity: 1;
        }
    }
}

.shadow {
    display: none;
    position: sticky;
    top: $mobile-header-height;
    width: 100%;
    z-index: z("header-shadow");
    
    &:before {
        content: "";
        position: absolute;
        top: -10px;
        display: block;
        width: 100%;
        height: 10px;
        box-shadow: 0 4px 10px rgb(0 0 0 / 7%);
    }

    @include breakpoint(lg) {
        display: block;
        top: $desktop-header-height;

        &.hasCategoriesBar{
            top: calc($desktop-header-height + $desktop-header-categories-bar-height);
        }
    }
}

.anchor {
    position: relative;
    height: 1px;
    pointer-events: none;

    @include breakpoint(lg) {
        display: none;
    }
}
