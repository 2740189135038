@use "styles/helpers" as *;

.viewMoreLink {
    @include rounded-corners;
    @include flex(center);
    @include typography(body-small-500);

    flex-shrink: 0;
    margin-left: 24px;
    padding: 6px 16px;
    background-color: $color-gray-light;
    color: $color-gray-dark;
    cursor: pointer;
    line-height: inherit;
    border: none;

    .viewMoreIcon {
        margin-left: 8px;
        color: $color-primary;
    }

    @include with-hocus {
        background-color: $color-primary;
        color: $color-white;

        .viewMoreIcon {
            color: $color-white;
        }
    }
}

.viewMoreCard {
    @include flex(center, center, column);
    @include rounded-corners;

    height: 100%;
    border: 1px solid $color-gray-light;
    transition: border-color .2s ease;
    
    .viewMoreContent {
        @include flex(center, center, column);
    }

    .viewMoreIcon {
        @include flex(center, center);
        @include rounded-corners;

        width: 48px;
        height: 48px;
        background: $color-gray-light;
        color: $color-primary;
        transition: background-color 0.2s ease;

        .icon {
            margin-right: 0;
        }
    }

    .viewMoreText {
        @include typography(body-500);

        color: $color-gray-dark;
        margin-top: 16px;
        transition: color 0.2s ease;
    }

    @include with-hocus {
        background-color: $color-gray-light-ultra;
        border-color: transparent;
        
        .viewMoreIcon {
            background: $color-primary;
            color: $color-white;
        }
    }
}

.recipesViewMoreCard {
    border-radius: 16px;
    transform: translateY(0);
    transition: box-shadow .2s ease, transform .2s ease;

    @include with-hocus {
        box-shadow: 0 4px 12px 0 #3333330d;
        background-color: $color-gray-light-ultra;
        transform: translateY(-1px);
        border-color: transparent;
    }
}