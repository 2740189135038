@use "@styles/helpers" as *;

.container {
    padding: 16px;
    margin: 0 -16px;
    background-color: transparent !important;

    @include breakpoint(md) {
        @include rounded-corners(20px);

        margin: 0;
        padding: 48px;
    }
}
