@use "@styles/helpers" as *;

.root {
    @include flex(flex-start, center);

    position: relative;
    padding: 16px;
    background-color: $color-white;

    @include breakpoint(md) {
        max-width: 640px;
        padding: 16px 24px;
    }
}

// Image

.imageContainer {
    position: relative;
    flex-shrink: 0;
    width: 48px;
    height: 48px;

    .image {
        @include rounded-corners;

        &.unavailable {
            opacity: 0.4;
        }
    }

    .badge {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -8px;
        margin: auto;
        height: 16px;
        line-height: 16px;

        .suffix {
            @include typography(12px, 12px, 700, 500);

            padding: 0 8px;
        }
    }

    @include breakpoint(md) {
        width: 80px;
        height: 80px;
    }
}

// Content

.content {
    @include flex(flex-start, center, column);

    position: relative;
    margin-left: 16px;
    flex-grow: 1;
    min-width: 0;
}

.main,
.secondary,
.footer {
    width: 100%;
    flex-grow: 1;
}

// --- Close

.removeBtn {
    position: absolute;
    top: 0;
    right: -4px;
    color: $color-gray-semi;
}

// --- Main

.main {
    @include flex(flex-start, center, column);

    .name {
        @include typography(body-500);

        padding-right: 32px;
        color: $color-gray-dark;
    }

    .detail {
        @include typography(body-small);

        color: $color-gray-semi;
    }
}

// --- Secondary

.secondary {
    margin-top: 4px;

    .error {
        @include typography(body-small);

        color: $color-error;
    }

    .label {
        margin-top: 8px;
    }
}

// --- Footer

.footer {
    @include flex(center, space-between, row);

    margin-top: 16px;
}

// --- Actions

.actionsContainer {
    @include flex(center, flex-start, row);

    align-self: flex-end;
    
    .adjustBtn,
    .deleteBtn,
    .replaceBtn {
        padding: 3px 14px;
    }

    .adjustBtn {
        .adjustBtnIcon {
            margin-bottom: 2px;
            margin-right: 12px;
        }
    }

    .replaceBtn {
        .replaceBtnIcon {
            margin-bottom: 2px;
            margin-right: 12px;
        }
    }

    .deleteBtn {
        color: $color-error;
        border: 1px solid $color-gray;

        &:hover {
            background: $color-gray-light-ultra;
        }
    }
}

// --- Price

.priceContainer {
    @include flex(flex-end, center, column);

    position: relative;
    margin-left: 16px;
    line-height: 16px;

    .originalPrice {
        margin-bottom: 2px;
    }

    .taxes {
        margin-top: 4px;
    }
}

// --- Loader

.loader {
    @include pos-fill(0);

    top: 5px;
    left: auto;
    right: 10px;
    width: 32px;
    height: 24px;
    margin: auto;
}

// Skeleton

.contentSkeletonWrapper {
    width: 100%;
}

:export {
    breakpoint: $screen-md-min;
}
