@keyframes pop {
    0% {
        transform: scale(1);
    }

    20% {
        transform: scale(0.9);
    }

    90% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}
