@use "@styles/helpers" as *;

.order {
    @include rounded-corners;

    padding: 12px;
    border: 1px solid $color-gray-light;
}

.actions {
    margin-top: 24px;
}
