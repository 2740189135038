@use "@styles/helpers" as *;

.subList {
    .item {
        .link {
            color: $color-gray-semi;

            &:focus,
            &:hover {
                color: $color-primary-shade-2;
            }

            &.active {
                color: $color-primary;
            }

            &.indented {
                padding: 12px 24px 12px 40px;

                &.withImage {
                    padding-left: 64px;
                    line-height: 1.4;
                }
            }
        }

        &:last-of-type {
            .link {
                padding-bottom: 24px;
            }
        }
    }
}

.link {
    color: $color-gray-semi;

    &:focus,
    &:hover {
        color: $color-primary-shade-2;
    }

    &.active {
        color: $color-primary;
    }

    &.indented {
        padding: 12px 24px 12px 40px;

        &.withImage {
            padding-left: 64px;
            line-height: 1.4;
        }
    }
}