@use "@styles/helpers" as *;

.root {
    overflow: hidden;
    
    .skeleton {
        margin: 0 -8px;
        padding: 16px 24px;
    
        &:not(:first-child) {
            border-top: 1px solid $color-gray-light;
        }
    }
}
