@use "@styles/helpers" as *;

.container {
    padding: 24px 16px;
    margin: 0 -16px;

    @include breakpoint(md) {
        @include rounded-corners(20px);

        margin: 0;
        padding: 48px;
    }
}

.title {
    @include typography(h4);

    margin-bottom: 8px;
    color: $color-gray-dark;
}

.content {
    p {
        @include typography(body);

        color: $color-gray-dark-subtle;
    }

    ul {
        margin-bottom: 8px;
    }

    ul > li {
        @include typography(body);

        color: $color-gray-dark-subtle;
        list-style: disc;
        margin-left: 32px;

        &:not(:last-of-type) {
            margin-bottom: 4px;
        }

        @include breakpoint(sm) {
            margin-left: 40px;
        }
    }

    p:not(:last-of-type) {
        margin-bottom: 8px;
    }
}
