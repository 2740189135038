@use "@styles/helpers" as *;

.Popover {
    @include rounded-corners;
    
    width: 370px;
    background: $color-white;
    box-shadow: 0 4px 16px rgb(0 0 0 / 8%);
    transition-property: none;
    z-index: z("popover");
}

.Popover-body {
    @include rounded-corners;
    @include content-fill;

    display: inline-flex;
    flex-direction: column;
    background: $color-white;
}
