@use "@styles/helpers" as *;

.root {
    @include breakpoint(sm) {
        overflow-y: scroll !important;
    }
}

.content {
    min-height: inherit !important;
    max-width: 640px !important;

    @include breakpoint(sm, max) {
        max-height: 80vh !important;
        height: auto !important;
    }
}

// Steps

.step {
    padding: 48px 24px 32px;

    @include breakpoint(sm) {
        padding: 64px 48px 48px;
    }

    .title {
        @include typography(20px, 26px, 500, 500);

        margin-bottom: 32px;
        text-align: center;

        @include breakpoint(sm) {
            margin-bottom: 40px;
        }
    }

    &.withTitle {
        padding-top: 32px;

        @include breakpoint(sm) {
            padding-top: 48px;
        }
    }

    &.withScrollbar {
        padding: 0;

        @include breakpoint(sm) {
            padding: 0;
        }

        .scrollbar {
            padding: 48px 24px 32px;
            max-height: 100vh;

            @include breakpoint(sm) {
                padding: 64px 48px 48px;
                max-height: initial;
            }
        }
    }
}

// --- Rating

.ratingStep {
    max-width: 500px;
    margin: 0 auto;

    .orderItem {
        @include rounded-corners;

        margin-top: 32px;
        padding: 12px;
        border: 1px solid $color-gray-light;
        transition: opacity 0.2s ease;

        &.disabled {
            opacity: 0.4;
        }
    }
}

// --- Testimonial

.testimonialStep {
    .textarea {
        font-weight: 400;
        border: 1px solid $color-gray-light;
        resize: none;

        &::placeholder {
            text-align: center;
        }
    }

    .actions {
        @include flex(center, center, row);

        margin-top: 24px;

        .btn {
            min-width: 100px;

            &:not(:first-child) {
                margin-left: 16px;
            }
        }
    }
}

// --- Low Rating

.lowRatingStep {
    .ratingCard {
        @include rounded-corners(16px);

        margin-bottom: 32px;
        padding: 24px 32px;

        &.boxed {
            border: 1px solid $color-gray;
        }

        .ratingCardActions {
            margin-top: 24px;

            .btn {
                @include typography(14px, 14px, 500, 500);
            }
        }

        @include breakpoint(sm) {
            padding: 24px 80px 32px;
        }
    }
}

// --- Success

.successStep,
.errorStep {
    max-width: 500px;
    margin: 0 auto;

    .icon {
        @include flex(center, center, row);

        width: 60px;
        height: 60px;
        margin: auto;
        border-radius: 100%;
        border: 2px solid $color-primary;
        color: $color-primary;
    }

    .title {
        @include typography(20px, 26px, 500, 500);

        margin-top: 24px;
        color: $color-gray-dark;
        text-align: center;
    }
}

.successStep {
    .icon {
        border: 2px solid $color-primary;
        color: $color-primary;
    }
}

.errorStep {
    .icon {
        border: 2px solid $color-error;
        color: $color-error;
    }
}

// Exports

:export {
    breakpoint_sm: $screen-sm-min;
    breakpoint_md: $screen-md-min;
    breakpoint_lg: $screen-lg-min;
}
