@use "styles/helpers" as *;

.root {
    display: flex;
    flex-direction: column;
}

.empty {
    padding: 80px 24px;

    @include breakpoint(md) {
        padding: 100px 24px;   
    }
}

.card {
    margin: 12px 0;
    width: 100%;
}
