@use "@styles/helpers" as *;

@use "../../../node_modules/rc-slider/assets/index.css";

.rc-slider {
    transition: opacity 0.2s ease;

    .rc-slider-rail {
        height: 2px;
        background-color: $color-secondary;
    }

    .rc-slider-track {
        height: 2px;
        background-color: $color-secondary-shade-2;
    }

    .rc-slider-handle {
        width: 40px;
        height: 20px;
        border-radius: 30px;
        margin-top: -8px;
        background-color: $color-primary;
        opacity: 1;
        border: 2px solid $color-secondary;
        transition: border-color .2s ease, opacity 0.2s ease;

        &:hover {
            border: 2px solid $color-secondary-shade-1;
        }

        &.rc-slider-handle-dragging {
            border: 2px solid $color-secondary-shade-2 !important;
            box-shadow: none !important;
        }
    }

    &.rc-slider-disabled {
        background-color: transparent !important;
        opacity: 0.5;
    }
}