@use "@styles/helpers" as *;

.header {
    @include flex(center);

    margin-bottom: 8px;
    padding: 8px 0;
}

.title {
    @include typography(body);
}

.icon {
    @include flex(center, center);

    margin-right: 8px;
}

.list {
    list-style-type: none;
}

.item {
    &.bordered {
        &:not(:last-of-type) {
            border-bottom: 1px solid $color-gray-light;
        }
    }
}