@use "@styles/helpers" as *;

.root {
    @include flex(flex-start, center, column);
}

.option {
    @include flex(center);

    padding: 12px 0;
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    transition: all .2s ease;
    text-align: start;
    cursor: pointer;

    &:hover {
        background: $color-gray-light-ultra;
    }

    .radio {
        @include flex(center, center);

        flex-shrink: 0;
        height: 20px;
        width: 20px;
        border: 1px solid $color-border;
        background-color: $color-white;
        border-radius: 50%;
        margin-right: 16px;
        position: relative;
    }

    .label {
        @include typography(body-small);
        
        flex-grow: 1;
        color: $color-gray-dark-subtle;
    }
    
    &.selected {
        .radio .fill {
            height: 10px;
            width: 10px;
            display: block;
            border: 4px solid transparent;
            border-radius: 50%;
            background: $color-primary;
        }
    }

    @include breakpoint(md) {
        padding: 12px 16px;
    }
}

