@use "@styles/helpers" as *;

// Root

.root {
    @include rounded-corners;
}

.root:not(.skeleton) {
    transition: all 0.15s ease;

    &:hover {
        cursor: pointer;

        // box-shadow: 0 0 0 2px $color-secondary-shade-2;

        .arrow {
            .icon {
                background-color: $color-gray-light;
                transform: translateX(4px);
            }
        }

        @include breakpoint(sm) {
            transform: translateY(-2px);
        }
    }

    &.selected {
        box-shadow: 0 0 0 2px $color-primary;

        .card {
            .image {
                transform: scale(0.94);
            }
        }

        @include breakpoint(sm) {
            transform: translateY(-2px);
        }
    }
}

// Fill - full height

.root.fill {
    height: 100%;

    .link,
    .card,
    .inner {
        height: 100%;
    }

    .imageWrapper,
    .detailsWrapper {
        height: 100%;
    }
}

// Thumbnail

.root.thumbnail {
    .imageWrapper {
        width: auto;
        max-width: 100%;

        > img {
            max-width: 100%;
        }
    }
}

// Card

.card {
    position: relative;
    border: none !important;
    background-color: white;
    box-shadow: 0 0 0 2px transparent;
    overflow: visible !important;
    transition: all 0.15s ease;
}

// --- Image

.imageWrapper {
    max-width: 200px;
    height: 100%;
    font-size: 0;

    .image {
        @include rounded-corners;

        position: relative;
        overflow: hidden;
        transition: all 0.15s ease;
    }
}

// --- Details

.detailsWrapper {
    @include flex(center, center, column);

    min-width: 0;
    text-overflow: ellipsis;

    .details {
        width: 100%;
    }

    .titleWrapper {
        @include flex(center, center, row);

        width: 100%;
    }

    .datesWrapper {
        @include flex(center, center, row);

        position: relative;
        width: 100%;
        padding-top: 12px;

        &:before {
            content: "";
            position: absolute;
            top: 6px;
            left: 0;
            width: 80px;
            height: 1px;
            background-color: $color-gray-light;
        }
    }
}

// --- Title

.title {
    @include typography(14px, 14px, 500, 500);

    width: 100%;
    color: $color-gray-dark;
    word-break: break-word;
}

// --- Dates

.dates {
    @include typography(12px, 12px, 500, 500);

    position: relative;
    width: 100%;
    color: $color-gray-semi;
}

// --- Arrow

.arrow {
    place-self: flex-end flex-end;
    font-size: 0;
    color: $color-primary;

    .icon {
        padding: 4px;
        border-radius: 16px;
        overflow: hidden;
        transition: all 0.15s ease;
    }
}

// --- Vertical

.card.vertical {
    .imageWrapper {
        padding: 8px;

        .image {
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }

    .detailsWrapper {
        @include flex(center, center, column);

        min-height: 120px;
        padding: 8px 24px 16px;
        text-align: center;

        @include breakpoint(sm) {
            min-height: 100px;
        }
    }

    .title {
        @include clamp(2, 24);

        max-height: 48px;
    }

    .dates {
        &:before {
            left: 0;
            right: 0;
            margin: auto;
        }
    }
}

// --- Horizontal

.card.horizontal {
    .inner {
        @include flex(flex-start, center, row);
    }

    .inlineImage {
        height: calc(100% - 16px);
        margin: 8px;
        border-radius: 8px;
    }

    .imageWrapper {
        padding: 8px;

        .image {
            height: 100%;

            img {
                position: relative;
                width: auto;
                height: 100%;
            }
        }
    }

    .detailsWrapper {
        @include flex(flex-start, space-between, column);

        flex: 1;
        padding: 16px;

        .titleWrapper,
        .datesWrapper {
            padding-right: 8px;
        }
    }

    .details {
        @include flex(flex-start, flex-start, column);
    }

    .title {
        @include clamp(2, 24);
    }

    .dates {
        @include clamp(2, 20);
    }

    .arrow {
        position: absolute;
        right: 12px;
        bottom: 8px;
    }
}

// Skeleton

.skeleton {
    .imageWrapper {
        flex: 1 !important;
        max-width: 140px !important;
    }

    .detailsWrapper {
        .titleWrapper,
        .datesWrapper {
            display: flex !important;
            width: 100% !important;
        }
    }
}

.imageSkeleton {
    width: 100%;
    padding-bottom: 110.5%;
    border-radius: 0;
}

// Exports

:export {
    primaryColor: $color-primary;
    skeletonBaseColor: $color-secondary-shade-1;
    skeletonHighlightColor: $color-secondary-shade-2;
}
