@use "@styles/helpers" as *;

.hat {
    position: absolute;
    z-index: 1;
    pointer-events: none;

    &.christmas {
        top: 5px;
        left: 51px;

        @include breakpoint(lg) {
            top: 9px;
            left: 5px;
        }
    }
}