@use "@styles/helpers" as *;

.root {
    @include flex(center, flex-start, row);

    position: relative;
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
}

.wrapper {
    @include flex(center, flex-start, row);

    position: relative;
    overflow: hidden;
    flex-grow: 1;
    width: 100%;

    &.entering,
    &.entered {
        opacity: 1;
        transition:
            opacity var(--enterDuration) ease-in var(--enterDelayDuration),
            height var(--enterDuration) ease-out var(--enterDelayDuration);
    }

    &.exited,
    &.exiting {
        opacity: 0;
        transition:
            opacity var(--exitDuration) ease-in var(--exitDelayDuration),
            height var(--exitDuration) ease-out var(--exitDelayDuration);
    }
}

.content {
    @include flex(center, flex-start, row);

    flex-grow: 1;
    width: 100%;

    &.entering,
    &.entered {
        opacity: 1;
        transition: opacity var(--enterDuration) ease var(--enterDelayDuration);
    }

    &.exited,
    &.exiting {
        opacity: 0;
        transition: opacity var(--exitDuration) ease var(--exitDelayDuration);
    }
}
