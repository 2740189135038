@use "@styles/helpers" as *;

.root {
    @include rounded-corners;

    position: relative;
    display: flex;
    flex-direction: column;
    background: $color-white;
    outline: 0;
    overflow: hidden;

    &.desktop.fullScreen,
    &.responsive.fullScreen {
        width: 100%;
        height: 100%;
        max-width: unset;
        max-height: unset;
        margin: 0;
    }

    // --- Header

    .header {
        &:not(.empty) {
            padding: 24px;
            border: 0;
        }
    }

    // --- Title

    .title {
        @include typography(18px, 26px, 500, 500);

        line-height: 1.6;
        overflow: hidden;
    }

    // --- Body

    .body {
        overflow: hidden;

        &.padded {
            margin: 0 -24px;
            padding: 24px 48px;
        }

        &.padded.hasTitle {
            margin: 0 -24px;
            padding: 0 48px 24px;
        }
    }

    // --- Close

    .closeButton {
        position: absolute;
        top: 30px;
        right: 24px;
        margin-left: 16px;
        flex-shrink: 0;
        color: $color-primary;
        z-index: 1;
        transition: all 0.2s ease;

        .closeIcon {
            height: auto;
            width: auto;
        }

        &.fixed {
            box-shadow: 0 8px 16px rgb(0 0 0 / 4%);
            background: $color-white;
        }
    }

    &.responsive {
        .header {
            &:not(.empty) {
                padding: 16px;
                border-bottom: 1px solid $color-gray-light;
            }
        }

        .title {
            line-height: 1.5;
        }

        .body {
            overflow: hidden auto;
            height: 100%;

            &.padded {
                margin: 0 -16px;
                padding: 40px 32px;
            }

            &.padded.hasTitle {
                margin: 0 -16px;
                padding: 0 32px 24px;
            }
        }

        .closeButton {
            top: 15px;
            right: 14px;

            .closeIcon {
                width: 20px;
                height: 20px;
            }
        }
    }

    @include breakpoint(md) {
        min-height: 200px !important;
    }
}

// --- Center

.root.center {
    width: auto;
    height: auto;
    min-width: 640px;
    max-width: 640px;
    min-height: 400px;
    max-height: unset;
    margin: min(8%, 120px) 24px 40px;
    opacity: 0;

    &.wide {
        width: 100%;
        max-width: 968px;
    }

    &.responsive {
        min-width: unset;
        margin: auto 24px;
    }

    &.fullScreen {
        margin: 0;
    }

    // Enter

    &.enter {
        opacity: 0;
        transform: translateY(-75px);
    }

    &.enterActive {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.2s ease, transform 0.4s $ease-out-expo;
    
        &.responsive {
            transition: opacity 0.2s ease, transform 0.4s $ease-out-expo;
        }
    }

    &.enterDone {
        opacity: 1;
        transform: translateY(0);
    }

    // Exit

    &.exit {
        opacity: 1;
        transform: translateY(0);
    }

    &.exitActive {
        opacity: 0;
        transform: translateY(-15px);
        transition: opacity 0.2s ease, transform 0.2s $ease-out-cubic;

        &.responsive {
            transition: opacity 0.2s ease, transform 0.2s $ease-out-cubic;
        }
    }

    &.exitDone {
        opacity: 0;
        transform: translateY(-15px);
    }
}

// --- Left / Right

.root.left,
.root.right {
    min-width: 320px;
    max-width: 320px;
    height: 100%;
    margin: 0;
}

.root.left.fullScreen,
.root.right.fullScreen {
    min-width: unset;
    max-width: unset;
    border-radius: 0;
}

// ------ Left

.root.left:not(.fullScreen) {
    border-radius: 0 8px 8px 0;
    transform: translateX(-100%);

    // Enter

    &.enter {
        transform: translateX(-100%);
    }
    
    &.enterActive {
        transform: translateX(0);
        transition: transform 0.4s $ease-out-expo;

        &.responsive {
            transition: transform 0.4s $ease-out-expo;
        }
    }

    &.enterDone {
        transform: translateX(0);
    }

    // Exit

    &.exit {
        transform: translateX(0%);
    }

    &.exitActive {
        transform: translateX(-100%);
        transition: transform 0.2s $ease-out-cubic;

        &.responsive {
            transition: transform 0.2s $ease-out-cubic;
        }
    }

    &.exitDone {
        transform: translateX(-100%);
    }
}

.root.left.fullScreen {
    opacity: 0;

    // Enter

    &.enter {
        opacity: 0;
        transform: translateX(-75px);
    }

    &.enterActive {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 0.2s ease, transform 0.4s $ease-out-expo;

        &.responsive {
            transition: opacity 0.2s ease, transform 0.4s $ease-out-expo;
        }
    }

    &.enterDone {
        opacity: 1;
        transform: translateX(0);
    }

    // Exit

    &.exit {
        opacity: 1;
        transform: translateX(0);
    }

    &.exitActive {
        opacity: 0;
        transform: translateX(-25px);
        transition: opacity 0.2s ease, transform 0.2s $ease-out-cubic;

        &.responsive {
            transition: opacity 0.2s ease, transform 0.2s $ease-out-cubic;
        }
    }

    &.exitDone {
        opacity: 0;
        transform: translateX(-25px);
    }
}

// ------ Right

.root.right:not(.fullScreen) {
    border-radius: 8px 0 0 8px;
    transform: translateX(100%);

    // Enter
    
    &.enter {
        transform: translateX(100%);
    }

    &.enterActive {
        transform: translateX(0);
        transition: transform 0.4s $ease-out-expo;

        &.responsive {
            transition: transform 0.4s $ease-out-expo;
        }
    }

    &.enterDone {
        transform: translateX(0);
    }

    // Exit

    &.exit {
        transform: translateX(0);
    }

    &.exitActive {
        transform: translateX(100%);
        transition: transform 0.2s $ease-out-cubic;

        &.responsive {
            transition: transform 0.2s $ease-out-cubic;
        }
    }

    &.exitDone {
        transform: translateX(100%);
    }
}

.root.right.fullScreen {
    opacity: 0;

    // Enter

    &.enter {
        opacity: 0;
        transform: translateX(75px);
    }

    &.enterActive {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 0.2s ease, transform 0.4s $ease-out-expo;

        &.responsive {
            transition: opacity 0.2s ease, transform 0.4s $ease-out-expo;
        }
    }

    &.enterDone {
        opacity: 1;
        transform: translateX(0);
    }

    // Exit

    &.exit {
        opacity: 1;
        transform: translateX(0);
    }

    &.exitActive {
        opacity: 0;
        transform: translateX(25px);
        transition: opacity 0.2s ease, transform 0.2s $ease-out-cubic;

        &.responsive {
            transition: opacity 0.2s ease, transform 0.2s $ease-out-cubic;
        }
    }

    &.exitDone {
        opacity: 0;
        transform: translateX(25px);
    }
}

// --- Bottom

.root.bottom:not(.fullScreen) {
    max-height: 100%;
    margin: 0;
    padding-bottom: env(safe-area-inset-bottom);
    border-radius: 8px 8px 0 0;
    transform: translateY(100%);

    &.responsive {
        width: 100%;
    }

    // Enter

    &.enter {
        transform: translateY(100%);
    }

    &.enterActive {
        transform: translateY(0);
        transition: transform 0.4s $ease-out-expo;

        &.responsive {
            transition: transform 0.4s $ease-out-expo;
        }
    }

    &.enterDone {
        transform: translateY(0);
    }

    // Exit

    &.exit {
        transform: translateY(0);
    }

    &.exitActive {
        transform: translateY(100%);
        transition: transform 0.2s $ease-out-cubic;

        &.responsive {
            transition: transform 0.2s $ease-out-cubic;
        }
    }

    &.exitDone {
        transform: translateY(100%);
    }
}

.root.bottom.fullScreen {
    border-radius: 0;
    opacity: 0;

    // Enter

    &.enter {
        opacity: 0;
        transform: translateY(75px);
    }

    &.enterActive {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.2s ease, transform 0.4s $ease-out-expo;

        &.responsive {
            transition: opacity 0.2s ease, transform 0.4s $ease-out-expo;
        }
    }

    &.enterDone {
        opacity: 1;
        transform: translateY(0);
    }

    // Exit

    &.exit {
        opacity: 1;
        transform: translateY(0);
    }

    &.exitActive {
        opacity: 0;
        transform: translateY(25px);
        transition: opacity 0.2s ease, transform 0.2s $ease-out-cubic;

        &.responsive {
            transition: opacity 0.2s ease, transform 0.2s $ease-out-cubic;
        }
    }

    &.exitDone {
        opacity: 0;
        transform: translateY(25px);
    }
}