@use "@styles/helpers" as *;

.title {
    @include typography(14px, 14px, 500, 500);

    color: $color-gray-dark-subtle;
    padding: 8px 24px 16px;
}

.list {
    &.horizontal {
        @include flex(center, flex-start, row);

        flex-wrap: wrap;
    }

    &:not(.horizontal) {
        .item {
            &.withBorder {
                &:not(:first-child) {
                    border-top: 1px solid $color-gray-light;
                }
            }
        }
    }
}
