@use "@styles/helpers" as *;

// Root / Bar

.root {
    position: fixed;
    bottom: 0;
    background: $color-white;
    padding: 16px;
    height: 60px;
    left: 0;
    right: 0;
    width: 100%;
    border: 0;
    outline: 0;
    z-index: z("bottomActions");
    padding-bottom: calc(16px + env(safe-area-inset-bottom));
    box-shadow: 0 -4px 14px rgb(0 0 0 / 3%);

    @include breakpoint(lg) {
        @include rounded-corners;

        width: $categories-navigation-width;
        top: $desktop-header-height + $container-padding;
        position: sticky;
        align-self: flex-start;
        flex-shrink: 0;
        display: block;
        margin-right: 32px;
    }
}

// Modal

.modal {
    .modalContent {
        height: 65vh !important;
    }
}

// Path

.path {
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-right: 40px;
}

.title {
    margin-left: 8px;
}

// Segment

.segment {
    @include typography(body-small-500);

    font-weight: 500;
    color: $color-primary;
    background: transparent;
    border: 0;
    outline: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 40px;
    flex-shrink: 10;
    cursor: pointer;

    &:first-of-type {
        @include flex(center);

        min-width: 20px;
    }

    &:last-of-type {
        color: $color-gray-dark;
        flex-shrink: 1;
        text-align: left;
    }
}

// Chevron

.chevron {
    color: $color-gray;
    flex-shrink: 0;
}

// List

.list {
    margin: 0 -24px;
}

// Category Link

.categoryLink {
    @include flex(center, space-between);
    @include button-reset;
    @include typography(body-small);

    width: 100%;
    padding: 12px 24px;
    min-height: 52px;
    border-bottom: 1px solid $color-gray-light;
    color: $color-gray-dark-subtle;

    &.active {
        color: $color-primary;
    }

    .categoryLinkButtonContent {
        @include flex(center, space-between);

        width: 100%;
    }
}

.categoryLinkContent {
    @include flex(center, flex-start);
}

.categoryLinkIcon {
    @include flex(center, flex-start);

    max-width: 24px;
    padding-right: 4px;
}

.categoryLinkName {
    padding-left: 4px;
}
