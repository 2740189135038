@use "styles/helpers" as *;

.root {
    @include flex(center, space-between);
    @include rounded-corners(4px);
    @include typography(small);
    @include button-reset;

    height: 24px;
    width: 100%;
    max-width: 100%;
    padding: 0 6px;
    color: var(--color);
    background-color: var(--background-color);
    transition: background-color 0.2s ease;
    cursor: pointer;

    @include with-hocus($hover: true, $focus: true) {
        background-color: var(--background-hover-color);
    
        .arrow {
            transform: translateX(2px);
        }
    }

    &.full {
        width: 100%;
    }

    &.tall {
        height: 28px;

        .name {
            @include clamp(2, 20);
            
            word-spacing: 1px;
            text-align: start;
            white-space: unset;
        }
    }

    .content {
        .name {
            font-weight: 500
        }
    }
}

.content {
    @include flex(center, flex-start);

    overflow: hidden;
}

.icon {
    position: relative;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    flex-shrink: 0;
}

.name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.arrow, .info {
    justify-self: flex-end;
    margin-left: 8px;
    flex-shrink: 0;
    transition: all 0.2s ease;
}

:export {
    color: $color-primary;
}