@use "@styles/helpers" as *;

.content {
    @include rounded-corners;

    padding: 16px 24px;
    background: $color-gray-light-ultra;
}

.text {
    @include typography(body);
}