@use "@styles/helpers" as *;

.root {
    display: flex;
    position: relative;
    user-select: none;
    flex-shrink: 0;
    cursor: pointer;
    transition: all 0.2s ease;

    &.hoverable:not(.disabled) {
        &:focus,
        &:hover,
        &.focused {
            background: $color-gray-light;
        }
    }

    .radioIcon {
        @include flex-center;

        position: relative;
        height: 20px;
        width: 20px;
        flex-shrink: 0;
        border: 1px solid $color-border;
        background-color: $color-white;
        border-radius: 50%;
        transition: all 0.2s ease, opacity 0.2s ease-in-out 0.2s;

        &:after {
            content: "";
            position: absolute;
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: $color-primary;
            transform: scale(0);
            opacity: 0;
            transition: all 0.1s ease-in;
        }

        &.tick:after {
            background: transparent;
            border-radius: 0;
            border: solid $color-white;
            border-width: 0 2px 2px 0;
            height: 9px;
            left: 6px;
            top: 2px;
            width: 5px;
            opacity: 0;
            transform: translateX(-8px) rotate(45deg);
            transition: all 0.2s ease;
        }

        &.large {
            width: 24px;
            height: 24px;
        }

        &.large.tick:after {
            height: 10px;
            left: 8px;
            top: 3px;
            width: 5px;
        }

        &.loading {
            opacity: 0;
            transition: all 0.2s ease, opacity 0.2s ease-in-out 0s;
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .iconWrapper .radioIcon {
            background-color: $color-white;

            &.tick {
                background-color: $color-primary;
                border-color: $color-primary;
            }

            &.tick:after {
                opacity: 1;
                transform: translateX(0) rotate(45deg);
            }

            &:after {
                opacity: 1;
                transform: scale(1);
            }
        }

        &:not(:disabled, :checked) {
            &:focus,
            &:hover {
                ~ .iconWrapper .radioIcon {
                    background-color: $color-white;
                }

                ~ .iconWrapper .radioIcon.tick {
                    border-color: $color-primary;
                }
            }
        }
    }

    &.disabled {
        cursor: default;
        opacity: 0.5;
    }
}

// Icon wrapper

.iconWrapper {
    position: relative;

    .loadingWrapper {
        @include flex(center);

        position: absolute;
        top: 0;
        z-index: 1;
    }
}

// Label wrapper

.labelWrapper {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .label {
        font-size: 16px;
        font-weight: 500;
        color: $color-gray-dark;
    }

    .subtitle {
        @include typography(body-small);

        color: $color-gray-semi;
    }

    &:not(.hasSubtitle) {
        line-height: 20px;

        &.large {
            line-height: 24px;
        }
    }
}

:export {
    primaryColor: $color-primary;
}
