@use "@styles/helpers" as *;

.ratingCard {
    width: 100%;
    margin: 16px 16px 40px;

    @include breakpoint(md) {
        margin: 24px 0 40px;
    }
}

.actionBtn {
    width: 100%;

    @include breakpoint(sm) {
        width: auto;
        min-width: 230px;
    }

    @include breakpoint(md) {
        min-width: 155px;
        width: 100%;
    }
}

.sgr {
    margin-top: 16px;
    padding-top: 24px;
    border-top: 1px solid $color-gray-light;

    @include breakpoint(sm) {
        margin-top: 24px;
        padding-top: 0;
        border-top: none;
    }
}

.summary {
    margin-top: 32px;

    @include breakpoint(md) {
        margin-top: 24px;
    }
}

.sgr + .summary {
    margin-top: 16px;

    @include breakpoint(md) {
        margin-top: 24px;
    }
}