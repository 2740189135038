@use "@styles/helpers" as *;

.embeddedServiceSidebar {
    .sidebarHeader[embeddedService-chatHeader_chatHeader] {
        border-bottom: 1px solid $color-gray-light !important;
    }

    .embeddedServiceSidebarFormField .slds-style-inputtext {
        border-radius: 8px !important;
        border-color: $color-border !important;
        margin-top: 4px !important;
    }

    h2[embeddedService-chatHeader_chatHeader] {
        font-size: 16px !important;
        font-weight: 500 !important;
        color: $color-gray-dark !important;
    }

    .minimizeButton,
    .closeButton {
        width: 18px !important;
        height: 18px !important;
        min-width: unset !important;
        min-height: unset !important;
        cursor: pointer !important;

        lightning-icon * {
            fill: $color-gray-semi !important;
        }
    }

    .closeButton {
        margin-left: 16px !important;
    }

    .embeddedServiceSidebarButton {
        border-radius: 8px !important;
    }

    .embeddedServiceSidebarFormField .uiInput .uiLabel-left {
        font-size: 12px !important;
        color: $color-gray-dark !important;
        font-weight: 500 !important;
    }

    .endChatButton.saveTranscriptButton {
        margin-top: 16px !important;
    }

    .embeddedServiceSidebarMinimizedDefaultUI {
        background: $color-primary !important;
        border: 0 !important;
        border-radius: 100px 100px 10px !important;
        box-shadow: 0 4px 12px rgb(0 0 0 / 20%) !important;
        bottom: 80px !important;
        width: 100px !important;
        max-height: 40px;
        font-size: 12px !important;
        min-width: unset !important;

        .message {
            @include truncate-text(100%);
        }

        @include breakpoint(lg) {
            bottom: 24px !important;
            right: 24px !important;
            font-size: 16px !important;
            max-height: 48px !important;
            width: 200px !important;
            border-radius: 100px 100px 10px !important;

            .message {
                font-weight: 500;
            }
        }
    }
}
