@use "@styles/helpers" as *;

.noResults {
    @include flex(center, center, row);
    @include typography(body-small);

    min-height: 44px;
    margin-top: 4px;
    color: $color-gray-semi;
}

.search {
    margin-bottom: 8px;
}

.list {
    margin-top: 4px;
}

.options {
    margin-top: 4px;
    @include flex(flex-start, center, column);
}

.option {
    @include flex(flex-start, flex-start, row);

    padding: 12px 0;
    width: 100%;

    .content {
        @include flex(flex-start, flex-start, row);
    }

    .checkbox {
        @include flex(center, center);
        @include rounded-corners;

        flex-shrink: 0;
        height: 24px;
        width: 24px;
        border: 1px solid $color-border;
        background-color: $color-white;
        margin-right: 12px;
        position: relative;

        &.rounded {
            align-self: center;
            width: 20px;
            height: 20px;
            border-radius: 100%;
        }
    }

    .label {
        @include typography(body-small);
        @include clamp(2, 24);

        color: $color-gray-dark-subtle;
        flex-grow: 1;
    }

    &.selected .checkbox {
        background-color: $color-primary;
        border-color: $color-primary;
        position: relative;

        &::after {
            position: absolute;
            content: "";
            border: solid $color-white;
            border-width: 0 2px 2px 0;
            height: 10px;
            left: 8px;
            top: 3px;
            width: 4px;
            transform: rotate(45deg);
        }

        &.rounded {
            &:after {
                top: 3px;
                left: 6px;
                width: 4px;
                height: 8px;
            }
        }
    }

    @include breakpoint(md) {
        padding: 12px 16px;

        &:hover {
            background-color: $color-gray-light-ultra;
        }
    }
}
