@use "@styles/helpers" as *;

.root {
    @include flex(flex-start, space-between, column);

    &.withBorder {
        border-bottom: 1px solid $color-gray-light;
        border-radius: 0;

        @include breakpoint(md) {
            border-radius: 8px;
            border: 1px solid $color-gray-light;
        }
    }

    &.withPadding {
        padding: 0 16px 16px;

        @include breakpoint(md) {
            padding: 16px;
        }
    }
}

.wrapper {
    @include flex(flex-start, flex-start, row);

    width: 100%;
}

.inner {
    @include flex(flex-start, center, column);

    width: 100%;

    @include breakpoint(sm) {
        @include flex(flex-start, center, row);
    }

    @include breakpoint(md) {
        @include flex(flex-start, center, column);
    }
}

// Sizes

// --- Small

.small {
    .label {
        @include typography(12px, 12px, 400, 400);

        min-height: 18px;
    }

    .value {
        @include typography(14px, 14px, 500, 500);
    }

    .subValue {
        @include typography(12px, 12px, 400, 400);
    }
}

// --- Icon Small

.icon-small {
    .icon {
        width: 32px;
        height: 32px;
        margin-top: 2px;
    }
}

// --- Medium

.medium {
    .label {
        @include typography(body-small);

        min-height: 21px;
    }

    .value {
        @include typography(body-500);
    }

    .subValue {
        @include typography(body-small);
    }
}

// --- Icon Medium

.icon-medium {
    .icon {
        height: 40px;
        width: 40px;
    }
}

// Icon

.icon {
    @include rounded-corners;
    @include flex(center, center);

    height: 40px;
    width: 40px;
    flex-shrink: 0;
    color: $color-gray-semi;
    background: $color-gray-light-ultra;

    &.start {
        margin-right: 16px;
    }

    &.end {
        margin-left: 16px;
    }
}

// Header

.content {
    flex: 1;
}

// --- Label

.label {
    color: $color-gray-semi;
}

// --- Value

.value {
    color: $color-gray-dark;
}

// --- Subvalue

.subValue {
    color: $color-gray-dark-subtle;
}

// Content

.children {
    margin-top: 16px;
    margin-left: 0;
    width: 100%;

    @include breakpoint(sm) {
        margin-top: 0;
        margin-left: 16px;
        width: auto;

        &.outside {
            margin-top: 16px;
            margin-left: 0;
        }
    }
    
    @include breakpoint(md) {
        margin-top: 16px;
        margin-left: 0;
        width: 100%;
    }
}

// Export

:export {
    white: $color-white;
}
