@use "@styles/helpers" as *;

.root {
    @include flex(center, center);
}

.fullscreen {
    @include pos-fill(0, fixed);
    @include flex(center, center);

    background: $color-white;
    z-index: z("global-loader");
}

.fullWidth {
    @include flex(center, center);

    width: 100%;
}

.transparent {
    background: rgb(255 255 255 / 65%);
}

.clipLoader {
    animation-name: spin !important;
}

// Animation

@keyframes spin {
    0% {
        transform: rotate(-45deg);
    }

    50% {
        transform: rotate(45deg);
    }

    100% {
        transform: rotate(315deg);
    }
}

// Export

:export {
    gray: $color-gray-semi;
    white: $color-white;
    primaryColor: $color-primary;
    secondaryColor: $color-secondary;
    transparent: rgb(0 0 0 / 0%);
}
