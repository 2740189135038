@use "@styles/helpers" as *;

.root {
    width: 100%;

    .autocomplete {
        @include flex(flex-start, space-between, row);

        position: relative;
        flex-grow: 1;
    }

    .actions {
        margin-top: 8px;

        @include breakpoint(sm) {
            margin-top: 0;
            margin-left: 16px;
        }
    }

    &.withStickyActions {
        @include breakpoint(sm, max) {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding-bottom: 0;

            .groups {
                flex-grow: 1;
                overflow-y: auto;
            }
        }
    }
}

.groups {
    .mapFormGroup {
        margin-top: 8px;
    }

    @include breakpoint(sm, max) {
        @include flex(flex-start, flex-start, column);

        padding-top: 16px;

        .mapFormGroup {
            flex: 1;
            margin-top: 0;
        }

        .mapFormGroupFields {
            height: 100%;
            padding-bottom: 8px;
        }

        .googleMapContainer {
            height: 100%;
        }
    }
}

:export {
    breakpoint: $screen-sm-min;
}
