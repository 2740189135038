@use "@styles/helpers" as *;

.root {
    .titleWrapper {
        @include flex(center, space-between, row);

        .fullWidth {
            width: 100%;
        }

        .title {
            @include typography(h4);
        }
    }

    .withoutTitle {
        @include flex(center, flex-end, row);

        @include breakpoint(lg) {
            margin-bottom: 24px;
        }
    }
}