@use "@styles/helpers" as *;

.form {
    &.center {
        .label {
            text-align: center;
        }

        .textarea {
            &::placeholder {
                text-align: center;
            }

            .error {
                place-content: center;
            }
        }

        .actions {
            @include breakpoint(sm) {
                @include flex(center, center, row);
            }
        }
    }
}

.formGroup {
    &.highlightGroup {
        @include rounded-corners(16px);

        border-bottom: 0;
        padding: 24px 16px;
        margin-top: 24px;
        background: $color-gray-light-ultra;

        @include breakpoint(md) {
            padding: 24px;
        }
    }
}

.label {
    @include typography(body-500);

    margin-bottom: 16px;
    color: $color-gray-dark;

    @include breakpoint(md) {
        margin-bottom: 32px;
        text-align: start;
    }
}

.textarea {
    font-weight: 400;
    border: 1px solid $color-gray-light;
    line-height: 1.5;
    resize: none;
}

.actions {
    @include flex(center, center, column);

    margin-top: 24px;

    .btn {
        width: 100%;
        border: none;

        &:not(:first-child) {
            margin-top: 16px;
        }
    }

    @include breakpoint(sm) {
        @include flex(center, flex-end, row);

        .btn {
            width: auto;
            min-width: 120px;

            &:not(:first-child) {
                margin-top: 0;
                margin-left: 16px;
            }
        }
    }
}

.info {
    @include flex(flex-start, center, row);
    @include typography(body-small);

    margin-top: 4px;
    color: $color-gray-dark-subtle;

    .icon {
        flex-shrink: 0;
        margin-top: 2px;
        margin-right: 8px;
    }

    a {
        color: $color-primary;

        &:hover {
            color: $color-primary-shade-1;
        }
    }
}
