@use "@styles/helpers" as *;

.root {
    position: relative;
    flex-shrink: 0;
    width: var(--width);
    height: var(--height);
    max-width: var(--width);
    max-height: var(--height);
}

.image {
    transition: opacity 0.2s ease;

    &.rounded {
        @include rounded-corners;

        overflow: hidden;
    }
    
    &.unavailable {
        opacity: 0.4;
    }
}

.labels {
    position: absolute;
    top: 12px;
    left: -1px;
}

.tags {
    position: absolute;
    bottom: 8px;
    left: 8px;
}

.badge {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}