@use "@styles/helpers" as *;

.label {
    @include typography(body-small-500);

    color: $color-gray-dark-subtle;
    margin-bottom: 4px;
    display: block;
}

.requiredMarker {
    margin-left: 4px;
    color: $color-error;
}
