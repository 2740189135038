@use "styles/helpers" as *;

.root {
    &.subtle {
        background: $color-gray-light-ultra;
        color: $color-primary;

        &:hover:not([disabled]) {
            color: $color-white;
            background: $color-primary-shade-1;
        }
    
        &[disabled] {
            color: $color-white;
            background: $color-secondary-shade-2;
            cursor: not-allowed;
        }
    }

    &.small {
        @include typography(body-small-500);

        padding: 2px 4px;
    }

    @include breakpoint(sm) {
        &.small {
            padding: 2px 14px;
        }
    }
}