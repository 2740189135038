@use "@styles/helpers" as *;

.imageWrapper {
    @include flex(center, center);

    margin: 0 -32px -0;
    padding: 40px 80px;
    background: $color-secondary;

    @include breakpoint(md) {
        margin: 0 -32px;
        padding: 32px;
    }
}

.body {
    @include flex(center, center, column);

    padding: 32px 24px 40px;
    max-width: 460px;
    margin: 0 auto;
}

.title {
    @include typography(h5);

    text-align: center;
}

.subtitle {
    @include typography(body);

    margin-top: 16px;
    color: $color-gray-semi;
    text-align: center;
    white-space: pre-wrap;
}

.submitBtn {
    margin-top: 32px;
}

.success {
    max-width: 450px;
    margin: 0 auto;
    padding: 56px 32px;
}

// Exports

:export {
    breakpoint: $screen-sm-min;
}
