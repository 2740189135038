@use "@styles/helpers" as *;

.root {
    padding: 24px 0 0;

    @include breakpoint(md) {
        padding: 0;
    }
}

.successModal {
    height: auto !important;
    border-radius: 8px 8px 0 0;
    padding-bottom: calc(16px + env(safe-area-inset-bottom));
}

.label {
    @include typography(body-small-500);

    color: $color-gray-dark-subtle;
    margin-bottom: 8px;
}

.section {
    margin-top: 16px;

    &:first-of-type {
        margin-top: 0;
    }
}

.list {
    margin: 0 -16px;

    @include breakpoint(md) {
        margin: 0;
    }
}

.textarea {
    resize: none;
}

.footer {
    @include flex(center, center, column);

    button {
        width: 100%;
    }

    @include breakpoint(sm) {

        @include flex(center, flex-end, row);

        button {
            width: auto;
        }
    }
}

.chatButton {
    margin-bottom: 16px;

    @include breakpoint(md) {
        margin-bottom: 0;
        margin-right: 16px;
    }
}

.product {
    @include flex(center);

    padding: 16px;

    .imageContainer {
        max-width: 24px;
        max-height: 24px;
        margin-right: 16px;
    }

    .nameContainer {
        @include typography(body-small);

        color: $color-gray-dark;
    }

    &:not(:last-of-type) {
        border-bottom: 1px solid $color-gray-light;
    }

    @include breakpoint(md) {
        padding: 16px 0;
    }
}
