@use "@styles/helpers" as *;

.root {
    @include flex(center, flex-start, row);
    
    background: $color-white;
    z-index: 1;
    
    &.padded {
        padding: 14px 16px;
    }

    &.bordered {
        border-bottom: 1px solid $color-gray-light;
    }

    &.shadowed {
        // box-shadow: 0px 2px 6px 0px #3333330d;
    }
}

.title {
    @include typography(18px, 20px, 500, 500);

    color: $color-gray-dark;
    line-height: 1;
}

.count {
    @include flex(center, center, row);
    @include typography(12px, 12px, 600, 600);
    @include rounded-corners(30px);
    
    position: relative;
    width: 24px;
    height: 24px;
    margin-left: 8px;
    margin-top: 2px;
    background-color: $color-secondary;
    color: $color-primary;
    line-height: 1;
    letter-spacing: .5px;
}