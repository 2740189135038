@use "@styles/helpers" as *;

.root {
    padding-bottom: calc(24px + env(safe-area-inset-bottom));
}

.root .productsAccordion {
    padding: 0;
    
    .productsAccordionContent {
        background-color: $color-gray-light-ultra;
        border-bottom: 1px solid $color-gray;
    }

    .productsAccordionHeader {
        background-color: $color-primary;
        color: $color-white;
        padding: 12px 24px;
    }

    .productsAccordionTitle {
        @include flex(center);
        @include typography(body-small-500);

        > div {
            margin-left: 16px;
        }
    }

    .productsAccordionIcon {
        color: $color-white;
        stroke-width: 2;
    }
}
