@use "@styles/helpers" as *;

// Root

.root {
    flex: 1;
    height: 100%;
    padding: 0;
    background-color: $color-white;
}

// Container

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.inner {
    max-height: 100%;
    width: 100%;
    height: 100%;

    :global .simplebar-track.simplebar-vertical {
        width: 8px;
        right: 0;
    
        .simplebar-scrollbar::before {
            background-color: $color-gray-semi;
        }
    }
}

// Main

.main {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    background-color: transparent;
    max-height: 100%;
    overflow-y: auto;

    .header {
        &:not(:last-child) {
            border-bottom: 1px solid $color-gray-light;
        }
    }

    .footer {
        &:not(:first-child) {
            border-top: 1px solid $color-gray-light;
        }
    }
}

// Submit

.submit {
    box-shadow: 0 -4px 12px 0 #3333330d;

    // border-top: 1px solid $color-gray-light;
    z-index: 1;
}
