@use "@styles/helpers" as *;

.notice {
    padding: 12px 16px;
    border-radius: 0;

    &.withIcon {
        padding-left: 12px;
    }

    .noticeIcon {
        margin-right: 10px;
    }
    
    &:not(:first-child) {
        border-top: 1px solid $color-gray-light;
    }
}