@use "@styles/helpers" as *;

.root {
    margin: 16px -16px 0;

    @include breakpoint(sm) {
        margin: 40px 0 0;
    }
}

#tabs {
    .tabListWrapper {
        overflow-y: scroll;
        padding-bottom: 8px;

        @include breakpoint(sm) {
            overflow: visible;
            padding-bottom: 0;
        }
    }

    .tabList {
        @include flex(flex-start, flex-start, row);

        padding: 0 16px 1px;
        border-bottom: 1px solid $color-gray-light;

        &:after {
            content: "";
            display: block;
            min-width: 16px;
            width: 16px;
            height: 16px;
        }

        @include breakpoint(sm) {
            padding: 0;

            &:after {
                display: none;
            }
        }
    }

    .tab {
        white-space: nowrap;
        @include typography(body-500);
    }

    .tabPanel {
        border-top: none;
    }

    .tabContent {
        padding: 16px;
        max-width: 980px;

        @include breakpoint(sm) {
            padding: 24px 0 0;
        }
    }
}

.section {
    margin-bottom: 24px;
}

.sectionTitle {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    color: $color-gray-dark;
}

.sectionSubtitle {
    @include typography(body-small);

    color: $color-gray-semi;
    margin-bottom: 4px;
}

.sectionBody {
    @include typography(body-small);

    color: $color-gray-dark-subtle;

    p {
        margin-bottom: 8px;
    }
}

.disclaimer {
    @include typography(small);

    background: $color-gray-light-ultra;
    color: $color-gray-dark-subtle;
    padding: 12px 16px;
}

.skuWrapper {
    margin-top: 16px;
    width: 100%;

    .sku {
        @include typography(small);

        color: $color-gray-semi;
    }
}

.tableWrapper {
    background: $color-gray-light-ultra;
}

.tableContent {
    padding: 8px;

    tr td:nth-child(1) {
        font-weight: 500;
        white-space: nowrap;
    }


    tr td:nth-child(2) {
        width: 100%;
        padding-left: 16px;
        word-break: break-word;
    }

    tr td {
        border-bottom: 1px solid $color-gray-light;
        padding: 8px;
    }

    tr:last-of-type td {
        border-bottom: none;
    }


    @include breakpoint(sm, max) {
        table {
            width: 100%;
        }

        tr {
            @include flex(flex-start, flex-start, column);
        }
        
        tr:not(:last-child) {
            border-bottom: 1px solid $color-gray-light;
        }

        tr td {
            border-bottom: none;
        }

        tr td:nth-child(1) {
            padding-bottom: 0;
        }

        tr td:nth-child(2) {
            padding-top: 4px;
            padding-left: 8px;
        }
    }
}

.nestedTableContent {
    border-spacing: 10px;
    border: 1px solid $color-gray-light-ultra;
    border-collapse: collapse;

    tr td:nth-child(2) {
        font-weight: 500;
        white-space: nowrap;
        text-align: right;
    }

    tr td:nth-child(1) {
        width: 100%;
    }

    tr td {
        padding: 8px;
        border: 0;
    }

    tr:nth-of-type(2n+1) {
        background: $color-gray-light-ultra;
    }

    tr.isParent td:nth-child(1) {
        font-weight: 700;
    }

    tr:not(.isParent) td:nth-child(1) {
        padding-left: 16px;
    }
}

.sameBrandContent {
    border-bottom: 1px solid $color-gray-light-ultra;
    max-width: none !important;

    .carouselContainer {
        margin-top: 0;
        padding-top: 0;

        .carouselHeader {
            margin-top: 0;
            min-height: 34px;
        }
    }
}
