@use "@styles/helpers" as *;

.root {
    @include flex(flex-start, space-between, row);

    width: 100%;
    padding: 12px 16px;
    
    &:not(:first-child) {
        border-top: 1px solid $color-gray-light;
    }

    &.link {
        &:hover {
            cursor: pointer;
            background-color: $color-gray-light-ultra;
        }
    }

    &.skeleton {
        font-size: 0;
        line-height: initial;
        align-items: center !important;

        .content {
            padding-right: 0;
            font-size: initial;
        }
    }

    &.inline {
        @include flex(center, center, row);
    }

    &:not(.inline) {
        .actions {
            align-self: flex-end;
        }
    }

    @include breakpoint(sm) {
        padding: 12px 24px;
    }
}

.imageContainer {
    position: relative;
    max-width: 48px;
    max-height: 48px;
    flex-shrink: 0;

    .loadingOverlay {
        @include pos-fill(0);
        @include flex(center, center);
        @include rounded-corners;

        z-index: z("product-card-loading-overlay");
        background: transparentize($color-gray-light-ultra, $amount: 0.1);
        font-size: 0;
    }

    .image {
        @include rounded-corners;

        overflow: hidden;

        &.unavailable {
            opacity: 0.4;
        }
    }

    .badge {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -7px;
        margin: auto;
    }
}

.imageContainer + .content {
    padding-left: 12px;
}

.content {
    @include flex(flex-start, space-between, column);

    flex-grow: 1;
    padding-right: 12px;
    overflow: hidden;

    .main {
        width: 100%;
        padding-right: 16px;
        min-width: 0;

        .brand {
            @include typography(12px, 12px, 400, 400);

            color: $color-gray-semi;
        }

        .name {
            @include typography(14px, 14px, 400, 400);

            line-height: 1.5;
            color: $color-gray-dark;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .secondary {
        @include flex(center, space-between, row);

        flex-shrink: 0;

        .taxes {
            margin-top: 4px;
        }

        .error {
            @include typography(small-500);

            color: $color-error;
            line-height: 1.5;
            text-align: left;
        }
    }

    &:not(.inline) {
        padding-right: 0;

        .secondary {
            width: 100%;
            margin-top: 8px;

            .priceContainer {
                margin-right: 8px;
            }
        }
    }

    &.inline {
        @include flex(center, space-between, row);

        .secondary {
            @include flex(flex-end, center, column);

            .error {
                text-align: right;
            }
        }
    }
}

.actions {
    flex-shrink: 0;

    &.fixedWidth {
        min-width: 140px;
    }
}

// Skeleton

.contentSkeletonWrapper {
    width: 100%;

    .contentSkeleton {
        &.small {
            width: 25%;
        }

        &.medium {
            width: 50%;
        }

        &.large {
            width: 100%;
        }
    }
}
