@use "@styles/helpers" as *;

.selectBtn {
    @include button-reset;
    @include typography(body-small);

    color: $color-gray-dark-subtle;
    padding: 0 8px;
    line-height: 24px;

    &.selected {
        color: $color-primary;
    }

    &:hover {
        color: $color-primary;

        .chevronIcon {
            color: $color-primary;
        }
    }
}

.popoverContainer {
    z-index: z("popover-body");
    margin-top: 16px;
    min-width: 270px;
    max-width: 400px;
}

.popover {
    padding: 16px 0;
    min-width: 230px;
}

.popoverTitle {
    @include typography(body-500);

    color: $color-gray-dark-subtle;
    padding: 0 16px;
    margin-bottom: 8px;
}

.filtersCount {
    @include flex(center, center);
    @include typography(small-700);

    color: $color-white;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: $color-primary;
    margin-left: 8px;
}

.search {
    margin-top: 8px;
    padding: 0 16px;   
}

.scrollbar {
    max-height: 240px;
}