@use "@styles/helpers" as *;

.list {
    &:not(:last-of-type) {
        margin-bottom: 20px;
        border-bottom: 1px solid $color-gray-light;
    }

    &.unavailable {
        margin-top: 20px;
    }
}