@use "@styles/helpers" as *;

.root {
    @include typography(body);

    color: $color-gray-semi;
    margin-bottom: 8px;
}

.brandLink {
    border-bottom: 1px dotted $color-gray-semi;
    transition: color border 0.3s ease;

    &:hover {
        color: $color-primary;
        border-bottom: 1px dotted $color-primary;
    }
}
