@use "@styles/helpers" as *;

.root {
    @include rounded-corners;

    width: 100%;
    border: 1px solid $color-gray-light;
    overflow: hidden;
    margin-bottom: 16px;
}

.title {
    @include typography(body-500);

    padding: 16px 16px 8px;
}

.scrollbar {
    max-height: 400px;
}

// .list {
//     padding-bottom: 16px;
// }

.filter {
    &:not(:first-child) {
        margin-top: 8px;
    }

    .name {
        @include typography(body-small);

        padding: 8px 16px;
        color: $color-gray-dark-subtle;
    }

    .value {
        @include typography(body-small);

        width: 100%;
        padding: 10px 16px;
        border-radius: 0;
        color: $color-primary;

        .content {
            @include flex(flex-start, flex-start, row);

            width: 100%;
        }

        .icon {
            @include flex(center, center, row);

            min-width: 20px;
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }

        .label {
            @include clamp(2, 20);
        }

        &:hover {
            background-color: $color-gray-light-ultra;
        }
    }
}

.clear {
    border-top: 1px solid $color-gray-light;

    .clearBtn {
        @include typography(body-small);

        width: 100%;
        border-radius: 0;
        color: $color-primary;
        justify-content: flex-start;
    }

    .clearBtnContent {
        @include flex(center, flex-start, row);
    }

    .clearBtnIcon {
        @include flex(center, center, row);

        width: 20px;
        height: 20px;
        margin-right: 8px;
    }
}
