@use "@styles/helpers" as *;

.root {
    &.horizontal {
        border-left: 1px solid $color-gray-light;
    }

    &:not(.horizontal) {
        border-top: 1px solid $color-gray-light;
    }
}
