@use "styles/helpers" as *;

.root {
    .body {
        @include breakpoint(md) {
            min-width: 550px;
            max-width: 550px;
        }
    }

    .header {
        .title {
            @include typography(18px, 18px, 500, 500);
    
            padding-left: 28px;
            text-align: center;
    
            @include breakpoint(md) {
                padding-left: 32px;
            }
        }
    }

    .content {
        padding: 0 16px 24px;
        
        .description {
            @include typography(14px, 14px, 400, 400);

            padding: 0 8px;
            text-align: center;
            color: $color-gray-semi;
        }

        .table {
            width: calc(100% + 32px);
            margin: 16px -16px 0;
            padding-bottom: 0;
            border-bottom: none;

            .separator {
                border-top-color: $color-gray-light;
            }
        }

        .info {
            margin-top: 8px;
            padding: 0;

            .icon {
                color: $color-warning;
            }

            .text {
                @include typography(12px, 12px, 400, 400);

                color: $color-gray-semi;
            }
        }
        
        
        @include breakpoint(md) {
            padding: 8px 40px 0;

            .description {
                padding: 0;
                text-align: start;
            }

            .info {
                margin-top: 8px;
            }
        }
    }

    .actions {
        margin-top: 0;
        border-top: none;
        
        .inner {
            padding: 16px;
        }

        @include breakpoint(md) {
            .inner {
                padding: 24px;
            }
        }
    }
}