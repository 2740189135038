@use "@styles/helpers" as *;

.details {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    font-size: 0;

    .info,
    .content {
        font-size: initial;
    }

    .image {
        margin-bottom: 8px;
        padding: 0 0 24px;

        .imageSkeleton {
            height: 100%;
            padding-bottom: 80%;
        }

        @include breakpoint(sm) {
            padding: 0;
        }
    }

    .info {
        .infoInner {
            @include flex(flex-start, flex-start, column);

            align-self: stretch;
            height: 100%;

            .name {
                max-width: 60%;
            }

            .tags {
                margin-top: 24px;
                max-width: 90%;
            }

            .price {
                margin-top: 32px;
                max-width: 200px;
            }

            .actions {
                margin-top: 24px;
                max-width: 320px;
            }

            > * {
                width: 100%;
            }
        }
    }

    @include breakpoint(sm) {
        // margin-top: 16px;
        margin-bottom: 48px;
        flex-direction: row;

        .image {
            width: 35%;
            height: auto;
            margin-bottom: 0;

            .imageSkeleton {
                height: 0;
                padding-bottom: 100%;
            }
        }

        .info {
            width: 65%;

            .infoInner {
                margin-left: 16px;
                padding-left: 16px;
            }
        }

        &.isQuickView {
            .image {
                width: 45%;
            }

            .info {
                width: 55%;
            }
        }
    }
}

.carousel {
    margin-top: 24px;
    height: 160px;
}

// Exports

:export {
    breakpoint: $screen-sm-min;
}
