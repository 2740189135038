@use "@styles/helpers" as *;

.root {
    @include flex(center, center);

    width: 100%;
}

.thumbnail {
    @include flex(center, center);

    border: 1px solid transparent;
    margin: 0 4px;
    cursor: pointer;

    &.active {
        border-color: $color-primary;
        border-radius: 8px;
    }

    img {
        border-radius: 8px;
        max-height: 60px;
        max-width: 60px;
    }
}
