@use "@styles/helpers" as *;

// Header

.header {
    align-items: flex-start;
}

.title {
    @include flex(flex-start, flex-start, column);

    flex-wrap: wrap;
    width: 100%;
    margin-bottom: -8px;

    .text {
        @include typography(20px, 26px, 500, 500);

        margin-bottom: 8px;
    }

    
    .recipePrice {
        @include flex(center, center, row);
        @include rounded-corners;

        margin-bottom: 4px;
        background-color: $color-gray-light;
        padding: 6px 12px;

        .price,
        .originalPrice,
        .perServingLabel {
            @include flex(center, center, row);
            @include typography(12px, 14px, 500, 500);

            line-height: 1.5;
            margin-bottom: 0;
        }
    }

    @include breakpoint(sm) {
        @include flex(center, center, column);

        padding: 0 16px 0 48px;
        text-align: center;
    }
}

// Description

.description {
    @include typography(14px, 16px, 400, 400);
    
    margin-bottom: 16px;
    padding: 0 16px;

    .bold {
        font-weight: 500;
    }

    .link {
        font-weight: 500;
        color: $color-primary;
        transition: color .2s ease;

        &:hover {
            color: $color-primary-shade-1;
        }
    }
    @include breakpoint(sm) {
        margin-bottom: 24px;
        padding: 0 24px;
        text-align: center;
    }
}

// List

.list {
    .item {
        padding-left: 16px;
        padding-right: 16px;
    
        @include breakpoint(sm) {
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}

// Banner
.promoBannerContainer {
    width: 100%;
    @include flex(center, flex-start, row);

    .promoBanner {
        width: fit-content;
        margin: 0 16px 16px;
    }
    
    @include breakpoint(sm) {
        @include flex(center, center, row);

        .promoBanner {
            min-width: 228px;
            margin: 0 8px 16px;
        }
    }
}

// Export

:export {
    breakpointSm: $screen-sm-min;
}