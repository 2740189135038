@use "@styles/helpers" as *;

// Root

.root {
    position: relative;
    
    .carousel{
        &.withBackground {
            .slider {
                border-radius: 8px;
                background-color: $color-gray-light;
                overflow: hidden;
    
                @include breakpoint(sm) {
                    padding: 8px 16px 8px 0 !important;
                }
            }
        }
    }

    .carousel:not(.withDescription) .slider {
        padding: 0 16px !important;
        
        @include breakpoint(sm) {
            padding: 0 24px !important;
        }
    }

    .carouselCards {
        gap: clamp(8px, 2vw, 16px);
    }
}

// Description

.description {
    @include flex(center, center, column);
    @include typography(14px, 14px, 400, 400);

    width: 200px;
    height: 100%;
    text-align: center;
    color: $color-primary;
    padding: 16px 0 16px 36px;

    .text {
        margin-top: 16px;
    }

    span {
        @include typography(14px, 16px, 500, 500);

        display: block;
    }
}

// --- Navigation Buttons

.navButton {
    z-index: 11 !important;

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }

    @include breakpoint(sm, max) {
        &.prev {
            left: 6px;
        }

        &.next {
            right: 6px;
        }
    }
}

// --- Fade

.fade {
    position: absolute;
    top: 0;
    bottom: 5px;
    margin: auto;
    width: 50px;
    height: calc(100% - 16px);
    z-index: 10;
    transition: all 0.4s ease;
    cursor: pointer;

    &.small {
        width: 20px;
    }

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }

    &.left {
        width: 50px;
        left: 0;
        background:
            linear-gradient(
                to left,
                transparentize($color-gray-light, 1) 0%,
                transparentize($color-gray-light, 0) 100%
            );
    }

    &.right {
        right: 0;
        background:
            linear-gradient(
                to right,
                transparentize($color-gray-light, 1) 0%,
                transparentize($color-gray-light, 0) 100%
            );
    }
}

// Slides

.slide {
    width: 200px;

    &.padded {
        padding: 16px 2px;
    }

    &.wide {
        width: 350px;
    }

    &.thumbnail {
        width: 100px;
    }
}

// Card

.card {
    width: 100%;
    height: 100%;
}

// Progress bar

.progressBar {
    border-radius: 0 0 8px 8px;
    height: 5px !important;
    transform: translateY(5px);

    @include breakpoint(lg) {
        transform: translateY(-5px);
    }
}
