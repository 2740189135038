@use "@styles/helpers" as *;

.root {
    @include flex(center);

    margin: 16px 0;
}

.btnWrapper:not(:empty) {
    margin-right: 12px;
}