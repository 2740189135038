@use "@styles/helpers" as *;

.root {
    @include flex(center, flex-start, row);
}

// Used this method instead of text-decoration: line-through because of misalignment on Safari (line was not centered)
.lineThrough {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 50%;
        height: 1px; 
        background-color: currentcolor;
    }
}

.icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

.hasGradient {
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}
