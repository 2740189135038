@use "@styles/helpers" as *;

.icon {
    @include flex(center, center, row);

    position: relative;
    font-size: 0;

    img {
        max-width: 100%;
        max-height: 100%;
    }

    &.small {
        width: 18px;
        height: 18px;
    }

    &.medium {
        width: 24px;
        height: 24px;
    }

    &.large {
        width: 24px;
        height: 24px;
    }
}
