@use "@styles/helpers" as *;

// Header

.title {
    @include breakpoint(sm) {
        text-align: center;
    }
}

// Description

.description {
    @include typography(body);
    
    margin-bottom: 16px;
    padding: 0 16px;

    @include breakpoint(sm) {
        margin-bottom: 24px;
        padding: 0 24px;
        text-align: center;
    }
}

// List

.list {
    .item {
        .radio {
            padding-left: 16px;
            padding-right: 16px;
        
            @include breakpoint(sm) {
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }
}

// Export

:export {
    breakpointSm: $screen-sm-min;
}