@use "@styles/helpers" as *;

// Select Button

.selectBtn {
    @include button-reset;
    @include typography(body-small-500);

    color: $color-gray-dark;
    padding: 0;
    line-height: 20px;
}

// Modal

.modal {
    .modalContent {
        max-height: 80vh !important;
        height: auto !important;
        border-radius: 8px 8px 0 0 !important;
    }
    
    .modalBody {
        padding: 0;
        padding-bottom: calc(env(safe-area-inset-bottom));
    }
}

// Icons

.chevronIcon {
    color: $color-gray-semi;
}

.sortIcon {
    color: $color-primary;
}