@use "@styles/helpers" as *;

.image {
    max-width: 100%;
    height: auto;
    transition: all .2s ease;
}

.hidden {
    @include hidden;
}

.error {
    @include content-fill;

    max-height: 300px;
}

.skeletonContainer {
    @include flex(center, center);

    position: relative;
}

.skeleton {
    @include pos-fill;
}
