@use "@styles/helpers" as *;

.root {
    @include flex(center);
}

.content {
    margin-left: 12px;
    @include flex(flex-start, center, column);
}

.message {
    @include typography(body-small);

    color: $color-gray-dark;
}

.thumbnail {
    @include flex(center, center);

    img {
        @include rounded-corners;
    }
}

.message + .button {
    margin-top: 4px;
}

.button {
    @include typography(small);

    line-height: 20px;

    &:hover {
        background: $color-white;
        color: $color-info;
    }
}
