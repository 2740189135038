@use "@styles/helpers" as *;

.root {
    .content {
        height: 65vh !important;
        border-radius: 8px 8px 0 0 !important;
    }

    .inner {
        @include flex(flex-start, center,column);

        margin: 0 -16px;
        padding: 0 16px 24px;
        padding-bottom: 16px !important;
        flex-grow: 1;
        overflow: hidden;
    }
}

.list {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;

    .accordion {
        &:not(:first-child) {
            border-top: 1px solid $color-gray-light;
        }
        
        .accordionHeader {
            padding: 12px 20px 12px 16px;
        }
        
        .accordionTitleLabel {
            @include typography(body-small-500);
        
            color: $color-gray-dark;
        }

        .accordionContent {
            padding-bottom: 24px;
        }

        .search {
            margin-bottom: 16px;
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}

.actions {
    width: 100%;
    @include flex(center);

    border-top: 1px solid $color-gray-light;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
}

.clearBtn {
    flex-shrink: 0;
    margin-right: 16px;
}

.viewBtn {
    flex-grow: 1;
}
