@use "@styles/helpers" as *;

.root {
    @include flex(center, center, column);
}

// Button

.btnWrapper {
    width: 100%;
    flex: 1;
    
    &:not(:first-of-type) {
        border-top: 1px solid $color-gray-light;
    }
}
