@use "styles/helpers" as *;

.root {
    padding: 16px;

    .title {
        @include flex(center, flex-start, row);
        .icon {
            color: $color-primary;
        }
        .text {
            @include typography(body);

            margin-left: 8px;
        }
    }

    .items {
        margin-top: 16px;

        .item {
            @include flex(center, flex-start, row);

            .image {
                @include rounded-corners;
                width: 48px;
                height: 48px;
                position: relative;
                overflow: hidden;
            }

            .details {
                margin-left: 16px;

                .name {
                    @include typography(body-500);
                }

                .barcode {
                    @include flex(center, flex-start, row);
                    @include typography(12px, 12px, 400, 400);

                    color: $color-gray-semi;

                    .icon {
                        margin-right: 4px;
                        stroke-width: 2;
                    }
                }
            }
        }

        @include breakpoint(sm) {
            margin-top: 28px;

            .item {
                .image {
                    width: 64px;
                    height: 64px;
                }

                .details {
                    margin-left: 32px;
                }
            }
        }
    }

    @include breakpoint(md) {
        padding: 0;
    }
}