@use "@styles/helpers" as *;

.root {
    width: 100%;
}

.textarea {
    @include rounded-corners;
    @include typography(body);

    border: 1px solid $color-gray;
    padding: 13px;
    width: 100%;
    outline: 0;
    transition: background-color 0.2s ease, border-color 0.2s ease, color 0.2s ease, box-shadow 0.2s ease;

    &:hover:not[disabled] {
        border-color: $color-gray-semi;
    }

    &:focus:not([disabled]) {
        border-color: $color-gray-dark-subtle;
    }

    &.hasError {
        border-color: $color-error;
        box-shadow: inset 0 0 0 1px $color-error;
    }

    &:input-placeholder {
        color: $color-gray-semi;
    }

    &::placeholder {
        color: $color-gray-semi;
    }

    &[disabled] {
        background: $color-gray-light-ultra;
        color: $color-gray-semi;
        border-color: $color-gray-light;
    }
}

.label {
    margin-bottom: 8px;
}

.error {
    @include flex(center);
    @include hidden;
    @include typography(small);

    color: $color-error;
    margin-left: 4px;
    margin-top: 2px;
    min-height: 20px;
    transition: opacity 0.2s ease;

    svg {
        margin-right: 4px;
    }

    &.visible {
        @include visible;
    }
}
