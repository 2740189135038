@use "@styles/helpers" as *;

.btn {
    @include typography(button);
    @include rounded-corners;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    outline: 0;
    box-shadow: none;
    background: transparent;
    color: $color-gray-dark-subtle;
    cursor: pointer;
    transition: opacity 0.2s ease, border 0.2s ease, background-color 0.2s ease;

    .iconStart {
        margin-right: 8px;
        display: flex;
        align-items: center;
    }

    .iconEnd {
        margin-left: 8px;
        display: flex;
        align-items: center;
    }

    .content {
        @include flex-center;

        position: relative;
        font-weight: inherit;
        font-size: inherit;
        transition: all 0.2s ease;
    }

    .loadingWrapper {
        @include pos-fill;
        @include flex-center;
    }
}

.btnLarge {
    padding: 10px 14px;
}

.btnMedium {
    padding: 6px 14px;
}

.btnSmall {
    @include typography(button-small);

    padding: 2px 14px;
}

.btnLargeIcon {
    padding: 10px;
}

.btnMediumIcon {
    padding: 6px;
}

.btnSmallIcon {
    padding: 2px;
}

.btnRounded {
    border-radius: 100px;
}

.btnPrimary {
    background: $color-primary;
    color: $color-white;

    &:hover:not([disabled]) {
        background: $color-primary-shade-1;
    }

    &:focus:not([disabled]) {
        background: $color-primary-shade-2;
    }

    &:active:not([disabled]) {
        background: $color-primary-shade-2;
    }

    &[disabled] {
        opacity: 0.4;
        cursor: not-allowed;
    }
}

.btnSecondary {
    background: transparent;
    border: 1px solid $color-primary;
    color: $color-primary;

    &:hover:not([disabled]) {
        border: 1px solid $color-primary-shade-1;
        background: $color-secondary;
        color: $color-primary-shade-1;
    }

    &:focus {
        background: $color-secondary-shade-1;
        border: 1px solid $color-primary-shade-2;
        color: $color-primary-shade-2;
    }

    &:active:not([disabled]) {
        background: $color-secondary-shade-1;
        border: 1px solid $color-primary-shade-2;
        color: $color-primary-shade-2;
    }

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.4;
    }

    &.btnLarge {
        padding: 11px 15px;
    }

    &.btnMedium {
        padding: 7px 15px;
    }

    &.btnSmall {
        padding: 3px 15px;
    }

    &.btnLargeIcon {
        padding: 11px;
    }

    &.btnMediumIcon {
        padding: 7px;
    }

    &.btnSmallIcon {
        padding: 3px;
    }
}

.btnSubtle {
    background: $color-secondary;
    color: $color-primary;

    &:hover:not([disabled]) {
        color: $color-primary-shade-1;
        background: $color-secondary-shade-1;
    }

    &:focus:not([disabled]) {
        color: $color-primary-shade-2;
        background: $color-secondary-shade-2;
    }

    &:active:not([disabled]) {
        color: $color-primary-shade-2;
        background: $color-secondary-shade-2;
    }

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.4;
    }
}

.btnEmpty {
    background: transparent;
    color: $color-primary;

    &:hover:not([disabled]) {
        background: $color-gray-light-ultra;
    }

    &:focus:not([disabled]) {
        color: $color-primary-shade-2;
        background: $color-gray-light;
    }

    &:active:not([disabled]) {
        color: $color-primary-shade-2;
        background: $color-gray-light;
    }

    &[disabled] {
        opacity: 0.4;
        cursor: not-allowed;
    }
}

.btnLoading {
    .content {
        opacity: 0;
        visibility: hidden;
    }
}

.btnReadOnly {
    pointer-events: none;
}

:export {
    primaryColor: $color-primary;
    whiteColor: $color-white;
    breakpoint: $screen-md-min;
}
