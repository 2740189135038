@use "@styles/helpers" as *;

.root {
    @include rounded-corners;

    display: flex;
    flex-direction: column;
    background-color: $color-gray-light-ultra;
    margin: 12px;
}

.root .accordion {
    padding: 0 16px;

    .accordionTitle {
        @include flex(center);
    
        .avatar {
            @include flex(center, center, column);
    
            background-color: $color-gray-light;
            border-radius: 50%;;
            flex-shrink: 0;
        }
        
        .avatarGeniusBadge {
            margin-top: 8px;
        }
    
        .avatarIcon {
            color: $color-gray-semi;
        }
    
        .nameContainer {
            @include typography(body-small-500);
    
            flex-grow: 1;
            color: $color-gray-dark;
            margin-left: 20px;
            text-align: left;
        }
    
        .name {
            @include truncate-text(170px);
        }
    }

    .accordionHeader {
        padding: 12px 0;
    }
}

.menu {
    padding-bottom: 8px;
    
    .item {
        .link {
            .icon {
                color: $color-primary;
            }
        }
    }

    .logout {
        .link {
            color: $color-error;

            .icon {
                color: $color-error;
            }
        }
    }
}
