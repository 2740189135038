@use "@styles/helpers" as *;
@use "./OrderDetails.module.scss" as *;

// TITLE

.titleContainer {
    @include flex(flex-start, center, column);

    padding: 0 16px 16px;
    border-bottom: 1px solid $color-gray;

    .desktopActions {
        margin-bottom: 8px;
    }

    @include breakpoint(sm) {
        @include flex(center, space-between, row);
    }

    @include breakpoint(md) {
        padding: 0 0 24px;

        .desktopActions {
            margin-bottom: 0;
        }
    }
}

// DETAILS

.detailsContainer {
    @include flex(flex-start, flex-start, column);

    margin-top: 16px;

    @include breakpoint(sm) {
        @include flex(flex-start, space-between, row);
    }

    @include breakpoint(md) {
        margin-top: 24px;
    }
}

.details {
    width: 100%;
    flex-grow: 1;
    min-width: 0;

    .detailsBlock {
        flex: 1;

        .detailsBlockValue {
            span {
                color: var(--text-color);
            }
        }

        .detailsBlockDeliveryNotice {
            @include typography(body-500);

            margin: 8px 0;

            span {
                color: var(--text-color);
            }
        }

        &:not(:first-child) {
            padding-top: 16px;
        }
    }

    @include breakpoint(md) {
        @include flex(stretch, flex-start, row);

        .detailsBlock {
            flex: 1;

            &:not(:first-child) {
                margin-top: 0;
                margin-left: 16px;
            }
        }
    }
}

// ACTIONS

.mobileActions {
    display: block;
    margin-top: 16px;
    padding: 0 16px;

    &.empty {
        display: none;
    }

    @include breakpoint(sm) {
        display: none;
    }
}

:export {
    breakpoint: $screen-sm-max;
}
