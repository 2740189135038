@use "@styles/helpers" as *;

.root {
    z-index: z("footer");
    position: relative;
}

.main {
    padding: 40px 0;
    background: $color-gray-light;
    box-shadow: 0 4px 14px rgb(0 0 0 / 3%);
    position: relative;
}


.mainWrapper,
.subWrapper {
    margin: 0 auto;
    max-width: $container-max-width;
    width: 100%;
    padding: 0 16px;

    @include breakpoint(sm) {
        padding: 0 24px;
    }
}

.mainWrapper {
    display: flex;
    flex-direction: column;

    @include breakpoint(lg) {
        flex-direction: row;
    }
}


// Mobile app

.appContainer {
    width: 100%;
    @include flex(center, center, column);

    @include breakpoint(sm) {
        flex-direction: row;
    }
    
    @include breakpoint(lg) {
        padding-right: 64px;
        width: 60%;
        @include flex(center, flex-start);
    }

}

.appContent {
    .appCta {
        @include typography(16px, 18px, 500, 500);
    
        text-align: center;
        margin-top: 24px;
    
        @include breakpoint(sm) {
            text-align: left;
            margin-top: 0;
        }
    }
    
    .appButtons {
        margin-top: 8px;
        margin-bottom: 32px;
        
        @include breakpoint(sm) {
            justify-content: flex-start;
        }
    }
}

.appMockContainer{
    @include flex(center, center);

    padding: 0 24px;
    max-width: 270px;
    
    @include breakpoint(sm) {
        padding: 0;
        max-width: 400px;
    }

    @include breakpoint(lg) {
        max-width: unset;
    }

    .appMock {
        width: 240px;
        height: auto;
        margin: 8px 0 16px;
    
        @include breakpoint(md) {
            width: 303px;
            margin: 0;
        }
    
        @include breakpoint(lg) {
            width: 403px;
        }
    }
}

// Social Buttons

.socialButtons {
    @include flex(center, center);
    
    order: -1;
    flex-grow: 1;
    width: 100%;
    margin-bottom: 32px;

    .socialButton {
        @include flex(center, center);
    
        margin: 0 8px;
        padding: 6px;
        border-radius: 8px;
        color: $color-primary;
    
        &:hover {
            background-color: $color-gray-light-ultra;
            color: $color-primary-shade-2;
        }
    }

    @include breakpoint(md) {
        width: auto;
        margin-bottom: 0;
        order: 0;
    }
}

// Sub

.subWrapper {
    @include flex(center, space-between, column);

    flex-wrap: wrap;
    color: $color-gray-dark-subtle;

    @include breakpoint(md) {
        flex-direction: row;
    }

    @include breakpoint(lg) {
        margin-bottom: 0;
    }
}

.sub {
    padding: 32px 0;
    border-bottom: 4px solid $color-primary;
}

// Copyright / Logo

.copyright {
    @include typography(12px, 14px);

    margin-top: 16px;
    color: $color-gray-semi;
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
    

    @include breakpoint(md) {
        width: 270px;
        margin-top: 0;
        text-align: right;
    }

    @include with-hover {
        color: $color-primary;
    }
}

.logo {
    cursor: pointer;
    
    @include breakpoint(md) {
        width: 270px;
    }
}