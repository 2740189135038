@use "@styles/helpers" as *;

.navigation {
    @include flex(center, center);

    position: relative;
}

.backButton {
    @include pos-left;

    color: $color-primary;
}

.navigationTitle {
    @include typography(body-500);
}
