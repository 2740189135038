@use "@styles/helpers" as *;

.root {
    margin-top: 16px;
    display: flex;
    flex-direction: column;

    @include breakpoint(sm) {
        margin-top: 32px;
    }
}

.title {
    @include typography(body-small);

    margin-bottom: 8px;
    color: $color-gray-semi;
}

.element {
    @include typography(body-small);
    @include flex(center);

    margin: 4px 0;

    .label {
        color: $color-gray-dark-subtle;
        line-height: normal;
    }

    .genius {
        @include flex(center);

        .popoverButton {
            @include typography(body);
            @include button-reset;

            padding: 0;
            margin-left: 5px;
            font-weight: 500;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .icon {
        color: $color-primary;
        margin-right: 12px;
        min-width: 24px;
        @include flex(center, center);
    }

    @include breakpoint(md) {
        margin: 6px 0;
    }
}

.popover {
    max-width: 240px;
    padding: 12px 16px;

    .popoverTitle {
        @include flex(center);
        @include typography(body-500);

        color: $color-gray-dark;
    }

    .geniusBadge {
        margin-left: 8px;
    }

    .popoverBody {
        @include typography(body-small);

        line-height: 1.5;
        margin-top: 8px;
        color: $color-gray-semi;
    }

    @include breakpoint(sm) {
        max-width: 300px;
    }
}
