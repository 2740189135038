@use "styles/helpers" as *;

.root {
    @include flex(flex-start, center, column);
    
    width: 100%;
}

.row {
    @include flex(flex-start, space-between);
    @include typography(body);

    width: 100%;
    padding: 4px 16px;
    color: $color-gray-dark-subtle;

    &:global(.size-large) {
        font-size: 18px;
    }

    &:global(.size-small) {
        font-size: 14px;
    }

    .keyContainer {
        @include flex(center, space-between);
    }

    .key,
    .value {
        @include clamp(2, 24);

        &:global(.bold) {
            font-weight: 500;
        }
    }

    .value {
        margin-left: 16px;
        flex-shrink: 0;
        min-width: 60px;
        text-align: right;
    }

    @include breakpoint(lg) {
        color: $color-gray-dark;
    }
}

.separator {
    border-top: 1px solid $color-gray-light;
    margin: 8px 16px;
    width: calc(100% - 32px);
}

.rowGroup {
    @include rounded-corners(16px);

    width: calc(100% - 32px);
    background-color: $color-gray-light;
    margin: 16px;
    padding: 12px 4px;
}

.infoBtn {
    color: $color-gray-dark-subtle;
    margin-left: 4px;

    &:hover {
        color: $color-primary;
    }
}

.deleteBtn {
    color: $color-primary;
    margin-left: 8px;

    &:hover {
        color: $color-primary-shade-1;
    }
}