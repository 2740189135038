@use "@styles/helpers" as *;

.viewMore{
    height: 100%;
}

.carousel {
    margin: 0 -16px;

    @include breakpoint(sm) {
        margin: 0;
    }
}

.carouselSlidesWrapper {
    padding: 0 16px;

    @include breakpoint(sm) {
        padding: 0;
    }
}


// Header

.header {
    @include flex(center, space-between);

    margin-bottom: 16px;

    @include breakpoint(sm) {
        margin-bottom: 24px;
    }

    @include breakpoint(md) {
        margin-bottom: 24px;
    }

    &:not(.empty) {
        min-height: 34px;
    }

    .title {
        @include typography(18px, 24px, 500, 500);
        @include truncate-text(100%);
    }
}



// Exports

:export {
    breakpoint: 960px;
    breakpointSm: $screen-sm-min;
    breakpointMd: $screen-md-min;
}