@use "@styles/helpers" as *;

.root {
    &:not(.inline) {
        @include breakpoint(sm, max) {
            .details {
                @include flex(flex-start, center, column);
            }

            .dates {
                @include flex(flex-start, center, column);

                width: 100%;

                .infoBlock {
                    &:not(:first-of-type) {
                        margin-left: 0;
                        margin-top: 6px;
                        padding-top: 6px;
                    }
                }
            }

            .total {
                width: 100%;
                margin-top: 6px;
                margin-left: 0;
                padding-top: 6px;
                text-align: left;

                .infoBlock {
                    .infoBlockValue {
                        font-weight: 700;
                    }
                }
            }

            .infoBlock {
                width: 100%;

                .infoBlockValue {
                    font-weight: 400;
                }

                .infoBlockContent {
                    @include flex(flex-start, space-between, row);

                    width: 100%;

                    .infoBlockLabel {
                        min-width: 100px;
                    }
                }
            }
        }
    }
}

// Header

.header {
    @include flex(center, space-between);

    margin-bottom: 8px;
    min-height: 32px;

    @include breakpoint(md) {
        margin-bottom: 24px;
    }
}

// --- Title

.title {
    @include flex(center, flex-start, row);

    flex-wrap: wrap;
    width: calc(100% + 8px);
    margin: 0 -4px;

    .name {
        @include typography(16px, 18px, 500, 500);

        margin-right: 12px;
        margin-left: 4px;
    }

    .badge {
        margin-right: 8px;
        margin-top: 4px;
        margin-bottom: 4px;

        &:last-of-type {
            margin-right: 0;
        }

        &.deliveryBadge {
            width: 100%;
            padding: 10px 12px;

            @include breakpoint(sm) {
                width: auto;
                padding: 4px 12px;
            }
        }
    }

    @include breakpoint(sm) {
        width: 100%;
        margin: 0;

        .name {
            margin-left: 0;
        }

        .badge {
            &.deliveryBadge {
                width: auto;
                padding: 4px 12px;
            }
        }
    }
}

// Summary

.summary {
    @include flex(flex-start, flex-start, column);

    @include breakpoint(sm) {
        @include flex(center, space-between, row);
    }
}

// --- Details

.details {
    @include flex(center, space-between, row);

    width: 100%;
    flex: 1;

    .dates {
        @include flex(center);

        text-align: start;

        .infoBlock {
            &:not(:first-of-type) {
                margin-left: 48px;
            }
        }

        @include breakpoint(sm) {
            width: auto;
        }
    }

    .total {
        margin-left: 16px;
        text-align: end;
    }
}

// Products

.products {
    @include flex(center, flex-end, row);

    margin-top: 4px;
    margin-right: -4px;

    @include breakpoint(sm) {
        @include flex(center, flex-end, row);

        margin-top: 16px;
    }
}

// Actions

.actions {
    @include flex(center, flex-start, row);

    width: 100%;
    margin-top: 16px;
    padding-left: 0;

    .viewDetailsBtn {
        padding: 6px 16px;

        &:hover {
            background: $color-secondary;
        }
    }

    @include breakpoint(sm) {
        width: auto;
        margin-top: 0;
        padding-left: 0;

        .viewDetailsBtn {
            width: auto;
            padding: 4px 16px;
            margin-left: 16px;
        }
    }
}

// Exports

:export {
    breakpoint: $screen-sm-min;
}
