@use "@styles/helpers" as *;

.root {
    @include flex(flex-start, flex-start, column);
    @include typography(body-small);
    @include rounded-corners(0);

    width: 100%;
    background-color: $color-white;
    padding: 8px 12px 8px 16px;
    justify-content: flex-start;
    border-width: 1px;
    line-height: 24px;
    letter-spacing: .15px;

    .content {
        width: 100%;

        >span {
            flex-grow: 1;
            text-align: left;
        }
    }

    .startIcon {
        margin-left: 2px;
        margin-right: 16px;
    }

    .endIcon {
        @include flex(center, center, row);
        @include rounded-corners(8px);

        width: 32px;
        height: 32px;
        padding-left: 2px;
        background-color: $color-white;
        color: $color-gray-semi;
    }

    .label {
        padding: 9px 0;
    }

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
        background: $color-gray-light-ultra;

        .endIcon {
            color: $color-primary;
        }
    }
}