@use "@styles/helpers" as *;

.root {
    @include flex(center, center, column);

    position: relative;
}

.hat {
    position: absolute;
    z-index: 1;
    pointer-events: none;

    &.christmas {
        top: -11px;
        left: -13px;
    }
}

.avatar {
    @include flex(center, center, column);

    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;

    &.subtle {
        color: $color-primary;
        background-color: $color-secondary;
    }
}

.geniusBadge {
    &.fixed {
        @include flex(center, center);

        position: absolute;
        top: -6px;
        right: -6px;
        z-index: 1;
        background: $color-white;
        border-radius: 50%;
        border: 1px solid $color-white;
    }
}
