@use "@styles/helpers" as *;

.root {
    @include flex(center, center, column);

    min-height: 100vh;
    max-width: 550px;
    margin: 0 auto;
    padding-top: 40px;
}

.ctaBtn {
    min-width: 196px;
}

:export {
    breakpoint: $screen-sm-min;
}
