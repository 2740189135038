@use "@styles/helpers" as *;

.root {
    @include rounded-corners(16px);

    overflow: hidden;
}

.banner {
    @include flex(center, center);

    position: relative;
    height: 100%;
    overflow: hidden;

    .label {
        position: absolute;
        top: 16px;
        left: 0;
        z-index: 1;

        @include breakpoint(sm) {
            top: 24px;
        }        
    }

    &.fill {
        .desktopWrapper {
            width: var(--desktopWidth);
            height: var(--desktopHeight);
        }
        
        .responsiveWrapper {
            width: var(--responsiveWidth);
            height: var(--responsiveHeight);
        }
    }
}
