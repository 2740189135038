@use "@styles/helpers" as *;

.root {
    @include rounded-corners;

    width: $categories-navigation-width;
    position: sticky;
    top: $desktop-header-height + $container-padding;
    align-self: flex-start;
    flex-shrink: 0;
    display: block;
    margin-right: 32px;
}

.scrollbar {
    max-height: calc(100vh - #{$desktop-header-height} - 2 * #{$container-padding});
    padding-right: 12px;
}

.title {
    @include typography(h5);

    position: sticky;
    padding: 0 0 16px;
    background: $color-white;
    top: 0;
    z-index: 1;
}

.list {
    display: flex;
    flex-direction: column;
    padding-bottom: 6px;
    padding-right: 10px;
}

.listSkeleton {
    margin: 0 8px 0 16px;
}

.listItemWrapper {
    padding: 10px 0;
    position: relative;

    &.active .listItem {
        color: $color-primary;
    }
}

.listItem {
    @include flex(center, flex-start);

    color: $color-gray-dark-subtle;
    border: none;
    padding: 0;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;

    &:hover {
        color: $color-primary-shade-2;
    }
}

.listItemIcon {
    @include flex(center, center);

    width: 26px;
    flex-shrink: 0;
}

.listItemText {
    margin-left: 16px;
}

.subList {
    display: flex;
    flex-direction: column;
    margin: 16px 0 0;
}

.subListItemWrapper {
    margin: 2px 0;
    padding: 8px 0 8px 46px;
    position: relative;

    &.active .subListItem {
        color: $color-primary;
    }
}

.subListItem {
    color: $color-gray-semi;
    display: inline-flex;
    border: none;
    padding: 0;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;

    &:hover {
        color: $color-primary-shade-2;
    }
}
