@use "@styles/helpers" as *;

.wrapper {
    @include flex(center);

    &.readonly {
        pointer-events: none;
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.display {
    @include flex(center);

    color: $color-gray;
    position: relative;
    line-height: 1;

    &.inner {
        @include pos-fill;

        overflow: hidden;
        color: $color-rating;

        .symbol {
            color: $color-rating;

            path:last-of-type {
                fill: $color-rating;
            }
        }
    }
}

.symbolWrapper {
    @include button-reset;
    @include flex(center, center);

    position: relative;
    cursor: pointer;
}

.symbol {
    @include flex(center, center);

    color: $color-gray;
    line-height: 1;

    path:last-of-type {
        fill: $color-gray;
    }

    &.active {
        color: $color-rating;

        path:last-of-type {
            fill: $color-rating;
        }
    }
}
