@use "@styles/helpers" as *;

.root {
    @include flex(center, center);
}

.tax {
    @include flex(center, center); 
    @include rounded-corners(4px);

    &.small {
        height: 18px; 

        .icon {
            max-height: calc(100% - 4px);
            padding: 0 2px 0 4px;
        }
    
        .text {
            @include typography(12px, 12px, 400, 400);
            
            padding: 2px 4px 2px 2px;
        }
    }

    &.medium {
        height: 20px; 

        .icon {
            max-height: calc(100% - 4px);
            padding: 0 2px 0 4px;
        }
    
        .text {
            @include typography(12px, 12px, 400, 400);
            
            padding: 2px 4px;
        }
    }

    &.large {
        height: 30px;

        .icon {
            max-height: calc(100% - 8px);
            padding: 0 2px 0 4px;
        }
    
        .text {
            @include typography(18px, 18px, 400, 400);
            
            padding: 2px 4px;
        }
    }
}