@use "../../../node_modules/simplebar-react/dist/simplebar.min.css";

.simplebar-offset {
    max-width: 100%;
}

.simplebar-track.simplebar-vertical {
    top: 0;
    width: 9px;
    right: 4px;
}

.simplebar-scrollbar:before {
    background: #b7b7b7;
}
