@use "@styles/helpers" as *;
@use "./OrderDetails.module.scss" as *;

.header {
    @include flex(center, space-between);

    padding: 0 16px 12px;
    border-bottom: 1px solid $color-gray;

    @include breakpoint(md) {
        padding: 0 0 16px;
    }
}

// Title

.title {
    @include typography(h5);

    color: $color-gray-dark;
}

// Alert

.retryPaymentAlert {
    margin: 8px 16px 24px;

    .retryBtn {
        width: 100%;
    }

    @include breakpoint(md) {
        margin: 8px 0 24px;

        .retryBtn {
            width: auto;
        }
    }
}

// Products

.products {
    margin-top: 16px;

    .product {
        padding-left: 16px;
        padding-right: 16px;

        @include breakpoint(md) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

// Actions

.mobileActions {
    display: block;
    margin-top: 16px;
    padding: 0 16px;

    &.empty {
        display: none;
    }

    @include breakpoint(sm) {
        display: none;
    }
}

.actionBtn {
    @include breakpoint(md) {
        min-width: 0;
        width: initial;
    }
}

// Exports

:export {
    breakpoint: $screen-sm-max;
}
