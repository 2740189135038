@use "@styles/helpers" as *;

.root {
    width: 100%;
}

// Empty State

.emptyState {
    min-height: 300px;
}

// Item

.item {
    width: 100%;

    &:not(.withRadio) {
        padding-top: 12px;
        padding-bottom: 12px;
        
        &:not(:last-child) {
            border-bottom: 1px solid $color-gray-light;
        }
    }

    &.withRadio {
        .radio {
            padding-top: 12px;
            padding-bottom: 12px;
        }

        &:not(:last-child) {
            .radio {
                border-bottom: 1px solid $color-gray-light;
            }
        }
    }
}

// Radio

.radio {
    @include flex(flex-start, center, row);

    .radioIconWrapper {
        @include flex(center, center, row);

        height: var(--thumbnailSize);
    }

    .ingredient {
        margin-left: 12px;
    }

    @include breakpoint(sm) {
        .ingredient {
            margin-left: 16px;
        }
    }
}