/* stylelint-disable declaration-block-no-duplicate-properties */

@use "@styles/helpers" as *;

.root {
    @include flex(flex-start, flex-start, column);

    height: 100%;
    overflow-y: auto;
    padding-bottom: env(safe-area-inset-bottom);

    @include breakpoint(md) {
        min-height: 0;
        height: auto;
    }
}

.children {
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
}
