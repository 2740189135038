@use "@styles/helpers" as *;

.root {
    will-change: opacity;

    &.entering,
    &.entered {
        visibility: visible;
        pointer-events: all;
        opacity: 1;
        transition: opacity var(--enterDuration) ease var(--enterDelay);
    }

    &.exiting {
        pointer-events: none;
        opacity: 0;
        transition: opacity var(--exitDuration) ease var(--exitDelay);
    }

    &.exited {
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
    }

    // Scale

    &.withScaleDown {
        will-change: opacity, transform;

        &.entering,
        &.entered {
            transform: scale(1);
            transition:
                opacity var(--enterDuration) ease var(--enterDelay),
                transform var(--enterDuration) $ease-out-expo var(--enterDelay),;
        }

        &.exiting,
        &.exited {
            transform: scale(var(--exitScale));
            transition:
                opacity var(--exitDuration) ease var(--exitDelay),
                transform var(--exitDuration) $ease-out-expo var(--exitDelay);
        }

        &.exited {
            transform: scale(var(--enterScale));
        }
    }

}
