@use "@styles/helpers" as *;

.root {
    font-size: 0;
}

.container {
    z-index: z("popover");
    position: relative;
    visibility: hidden;
}

.contentWrapper {
    position: relative;
    pointer-events: none;

    &.visible {
        visibility: visible;
        pointer-events: all;
    }
}

.content {
    @include rounded-corners;

    box-shadow: 0 4px 16px rgb(0 0 0 / 8%);
    background: $color-white;
    z-index: -999;

    &.entering {
        opacity: 1;
        transform: translateX(0) translateY(0);
        transition: visibility 0.3s ease, opacity 0.3s ease, transform 0.4s cubic-bezier(0.33, 1, 0.68, 1);

        &.fast {
            transition: visibility 0.2s ease, opacity 0.2s ease, transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);
        }
    }

    &.entered {
        opacity: 1;
        transform: translateX(0) translateY(0);
    }

    &.exiting {
        opacity: 0;
        transition: visibility 0.2s ease, opacity 0.2s ease, transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);
        pointer-events: none;

        &.fast {
            transition: visibility 0.15s ease, opacity 0.15s ease, transform 0.2s cubic-bezier(0.33, 1, 0.68, 1);
        }
    }

    &.exited {
        opacity: 0;
    }

    &.top.exiting,
    &.top.exited {
        transform: translateY(-20px);
    }

    &.bottom.exiting,
    &.bottom.exited {
        transform: translateY(20px);
    }

    &.left.exiting,
    &.left.exited {
        transform: translateX(-20px);
    }

    &.right.exiting,
    &.right.exited {
        transform: translateX(20px);
    }
}

.arrowContainer {
    padding: 0 !important;
}

.arrow {
    width: 18px;
    height: 12px;
    overflow: hidden;
    border: none !important;
    z-index: 1;

    &:before {
        @include pos-fill;

        content: "";
        width: 12px;
        height: 12px;
        margin: auto;
        background-color: var(--backgroundColor, $color-white);
        transform-origin: 50% 50%;
        transform: rotate(135deg);
        box-shadow: 0 0 4px 0 rgb(0 0 0 / 6%);
    }

    &.top {
        bottom: -11px !important;
        transform: translateX(3px);

        &:before {
            bottom: 12px !important;
        }
    }

    &.bottom {
        top: -12px !important;
        transform: translateX(3px);

        &:before {
            top: 12px !important;
        }
    }

    &.left {
        right: -10px !important;
        transform: translateY(5px);

        &:before {
            right: 11px !important;
        }
    }


    &.right {
        left: -10px !important;
        transform: translateY(5px);

        &:before {
            right: -6px !important;
        }
    }
}
