@use "@styles/helpers" as *;

.root {
    @include breakpoint(sm, max) {
        height: 100%;
    }
}

.form {
    @include breakpoint(sm, max) {
        height: 100%;
    }
}
