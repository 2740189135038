@use "@styles/helpers" as *;

.wrapper,
.inner {
    @include flex(center, flex-start, row);

    align-self: stretch;
}

.root {
    @include flex(center);
    @include typography(body);

    padding: 0;
    border: 0;
    outline: none;
    box-shadow: none;
    background: none;
    color: $color-gray-dark-subtle;
    cursor: pointer;

    .icon {
        margin-right: 16px;
        color: $color-primary;
    }

    .image {
        @include flex(center, center, row);

        margin-right: 16px;
        width: 24px;
        height: 24px;
        max-width: 24px;
        max-height: 24px;
        line-height: 1;
    }

    .label {
        flex: 1;
        text-align: start;
        line-height: 1.5;
    }

    .badge {
        margin-left: 8px;
    }

    &:not(.withPopover):focus,
    &:not(.withPopover):hover {
        color: $color-primary-shade-2;
    }

    &.active {
        color: $color-primary;
    }

    &.withPopover {
        cursor: default;
    }
}

// Popover

.popover {
    margin-top: 8px;
}

.popoverContent {
    padding: 12px 0;
    min-width: 200px;
}

.popoverItem {
    border-radius: 0;
    padding: 12px 24px;

    &:hover {
        background-color: $color-gray-light-ultra;
    }
}

// Skeleton

.skeleton {
    width: 100%;
}
