// Colors

// -- Primary
$color-primary: #008000;
$color-primary-shade-1: #060;
$color-primary-shade-2: #005200;

// -- Secondary
$color-secondary: #e5f2e5;
$color-secondary-shade-1: #cfe7cf;
$color-secondary-shade-2: #b4dab4;

// -- Accent
$color-accent: #f00;

// -- Greys
$color-gray-dark: #333;
$color-gray-dark-subtle: #595959;
$color-gray-semi: #999;
$color-gray: #dedede;
$color-gray-light: #f4f4f4;
$color-gray-light-ultra: #f9f9f9;

// -- White
$color-white: #fff;

// -- Black
$color-black: #000;

// -- Error
$color-error: #ff7070;
$color-error-tint-1: #fff0f0;
$color-error-tint-2: #fdd;
$color-error-shade-1: #e66565;

// -- Success
$color-success: #9fd25d;
$color-success-tint-1: #eafbd4;

// -- Info
$color-info-shade-1: #07AFF6;
$color-info: #50b8d9;
$color-info-tint-1: #e5f8ff;

// -- Warning
$color-warning: #d5ab06;
$color-warning-tint-1: #fef7dc;

// --- Discount
$color-discount: #F00;

// --- Genius
$color-genius: #670169;
$color-genius-tint-1: #fff5fa;
$color-genius-gradient: linear-gradient(71.97deg, #090171 11.12%, #ff005a 79.12%);

// --- Gifts
$color-gift: #4f007f;

// --- Promotion
$color-promotion: #fe823d;
$color-promotion-tint-1: #ffebdf;

// --- Loyalty
$color-loyalty: #CB0000;
$color-loyalty-tint-1: #FFEAEA;
$color-loyalty-tint-2: #ffdddd;

// --- Buy More Save More

$color-bg-buy-more: #FFDBDB;
$color-buy-more: #F00;

// -- Misc
$color-background: #fff;
$color-border: #dedede;
$color-border-green: rgba($color-primary, 0.2);
$color-overlay: rgb(51 51 51 / 40%);
$color-rating: #fac40b;
$color-eta: #FF820F;
$color-new: #07AFF6;

// Layout

// -- Container
$container-max-width: 1800px;
$container-padding: 32px;
$container-blog-max-width: 1336px;

// -- Navigation
$categories-navigation-width: 285px;
$account-navigation-width: 285px;
$account-content-width: 960px;

// -- Checkout
$checkout-summary-width: 320px;

// -- Cart
$cart-width: 320px;
$cart-title-height: 32px;

// -- Header
$desktop-header-height: 142px;
$desktop-header-main-bar-height: 78px;
$desktop-header-sub-bar-height: 64px;
$desktop-header-categories-bar-height: 46px;
$mobile-header-height: 64px;

// --- Homepage
$desktop-homepage-mega-menu-side: 285px;
$mobile-delivery-info-bar-height: 72px;

// --- Landing Pages
$alpha-template-container-width: 1300px;
$alpha-template-content-width: 994px;

// --- Recipes v2
$recipes-layout-container-width: 1624px;
$recipes-layout-content-width: 1488px;

// Breakpoints

// -- SM
$screen-sm-min: 640px;
$screen-sm-min-value: 640;
$screen-sm-max: 639.98px;
$screen-sm-max-value: 639.98;

// -- MD
$screen-md-min: 1024px;
$screen-md-min-value: 1024;
$screen-md-max: 1023.98px;
$screen-md-max-value: 1023.98;

// -- LG
$screen-lg-min: 1260px;
$screen-lg-min-value: 1260;
$screen-lg-max: 1259.98px;
$screen-lg-max-value: 1259.98;

// -- XL
$screen-xl-min: 1440px;
$screen-xl-min-value: 1440;
$screen-xl-max: 1439.98px;
$screen-xl-max-value: 1439.98;

// Breakpoints maps
$breakpoints: (
    sm: (
        min: $screen-sm-min,
        max: $screen-sm-max,
    ),
    md: (
        min: $screen-md-min,
        max: $screen-md-max
    ),
    lg: (
        min: $screen-lg-min,
        max: $screen-lg-max
    ),
    xl: (
        min: $screen-xl-min,
        max: $screen-xl-max
    )
);

// Typography

$typography: (
    h1: (
        size-small: 42px,
        size-large: 68px,
        weight: 700
    ),
    h2: (
        size-small: 32px,
        size-large: 42px,
        weight: 700,
        line-height-small: 48px,
        line-height-large: 63px
    ),
    h3: (
        size-small: 26px,
        size-large: 34px,
        weight: 700,
        line-height-small: 36px,
        line-height-large: 48px
    ),
    h4: (
        size-small: 20px,
        size-large: 26px,
        weight: 500
    ),
    h5: (
        size-small: 18px,
        size-large: 20px,
        weight: 500
    ),
    h6: (
        size-small: 18px,
        size-large: 18px,
        weight: 700
    ),
    body: (
        size-small: 14px,
        size-large: 16px,
        weight: 400
    ),
    body-500: (
        size-small: 14px,
        size-large: 16px,
        weight: 500
    ),
    body-700: (
        size-small: 14px,
        size-large: 16px,
        weight: 700
    ),
    body-small: (
        size-small: 14px,
        size-large: 14px,
        weight: 400
    ),
    body-small-500: (
        size-small: 14px,
        size-large: 14px,
        weight: 500
    ),
    body-small-700: (
        size-small: 14px,
        size-large: 14px,
        weight: 700
    ),
    small: (
        size-small: 12px,
        size-large: 12px,
        weight: 400
    ),
    small-500: (
        size-small: 12px,
        size-large: 12px,
        weight: 500
    ),
    small-700: (
        size-small: 12px,
        size-large: 12px,
        weight: 700
    ),
    extra-small: (
        size-small: 10px,
        size-large: 10px,
        weight: 400
    ),
    extra-small-500: (
        size-small: 10px,
        size-large: 10px,
        weight: 500
    ),
    button: (
        size-small: 16px,
        size-large: 16px,
        weight: 500,
        line-height-small: 24px,
        line-height-large: 24px
    ),
    button-small: (
        size-small: 14px,
        size-large: 14px,
        weight: 500,
        line-height-small: 24px,
        line-height-large: 24px
    )
);

// z-index

$z-layers: (
    "footer": 1200,
    "chat-button": 1300,
    "popover-body": 1300,
    "bottomActions": 1400,
    "menu": 1400,
    "menu-trigger": 1401,
    "header-shadow": 1490,
    "homepage-intro": 1495,
    "header": 1500,
    "open-in-app-banner": 1550,
    "popover": 1600,
    "drawer": 1700,
    "modal": 1750,
    "autocomplete": 1900,
    "global-loader": 2000,
    "cookies-banner": 10000,
    "cookies-modal": 10100,
    /* product - card*/
    "product-card-labels": 1,
    "product-card-quantity-overlay": 4,
    "product-card-badge": 5,
    "product-card-favorites-button": 5,
    "product-card-tooltip": 9,
    "product-card-loading-overlay": 10,
    /* product - cart card*/
    "product-cart-card-loading-overlay": 10,
    /* product - details*/
    "product-details-toolbar": 1450,
    "product-details-banner-tooltip": 1600,
    "product-details-gallery": 1,
    "product-details-labels": 2,
    "product-details-badge": 2,
    "product-details-zoom-icon": 2,
);

// easings

// --- cubic
$ease-in-out-cubic: cubic-bezier(0.65, 0, 0.35, 1);
$ease-out-cubic: cubic-bezier(0.33, 1, 0.68, 1);

// --- circ
$ease-in-out-circ: cubic-bezier(0.85, 0, 0.15, 1);
$ease-out-circ: cubic-bezier(0, 0.55, 0.45, 1);

// --- expo
$ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);

// --- quart
$ease-out-quart: cubic-bezier(0.25, 1, 0.5, 1);
