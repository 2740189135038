@use "@styles/helpers" as *;

.root {
    padding: 0 24px;
}

// Header

.header {
    @include flex(center, space-between);

    padding: 16px 0;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    .inner {
        @include flex(flex-start, flex-start, column);

        width: 100%;

        @include breakpoint(sm) {
            @include flex(center, space-between, row);
        }
    }

    .iconWrapper {
        @include flex(center, center, row);

        background-color: transparent;
        border: none;
        outline: none;
        margin-left: 16px;
    }

    .icon {
        color: $color-gray-semi;
        transform-origin: 50% 45%;

        &.expanded {
            transform: rotate(-180deg);
        }
    }

    &.animated {
        transform-origin: 50% 50%;

        .icon {
            transition: all 0.2s ease;

            &.expanded {
                transform: scale(1, -1);
            }
        }
    }
}

.extra {
    padding-top: 8px;
    padding-bottom: 16px;
    flex: 1;
}

// Content

.content {
    position: relative;
    overflow: hidden;

    &.entering,
    &.entered {
        opacity: 1;
        transition: opacity 0.2s ease-in-out, max-height 0.3s cubic-bezier(0.33, 1, 0.68, 1), transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);
    }

    &.exited,
    &.exiting {
        opacity: 0;
        transition: opacity 0.2s ease-in-out, max-height 0.3s cubic-bezier(0.33, 1, 0.68, 1), transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);
    }

    .contentInner {
        position: relative;
    }
}
