@use "@styles/helpers" as *;

.title {
    @include typography(18px, 18px, 500, 500);
}

.subtitle {
    @include typography(14px, 14px, 400, 400);
}

.title,
.subtitle {
    max-width: 220px;
}
