@use "@styles/helpers" as *;

.streetInputWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 -6px;
    padding: 0 6px;
}

.streetInputIcon {
    color: $color-primary;
}

.streetInput {
    margin-right: 16px;
    flex: 2 1;

    .input {
        text-overflow: ellipsis;
    }
}

.streetNumberInput {
    width: 104px;
}

.resultsRoot {
    @include rounded-corners;

    box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
    position: absolute;
    top: 54px;
    left: 0;
    right: 0;
    background: $color-white;
    margin: 0;
    z-index: z("autocomplete");
    overflow-x: auto;
    height: 220px;
    border: 1px solid $color-gray;
}

.result {
    @include flex(flex-start, flex-start, column);

    width: 100%;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 12px 16px;
    border-top: 1px solid $color-gray-light;
    text-align: left;

    &:focus,
    &:hover {
        background-color: $color-gray-light-ultra;
    }

    &:first-child {
        border-top: none;
    }
}

.resultTitle {
    @include typography(body-500);

    color: $color-gray-dark;
}

.resultSubtitle {
    @include typography(body-small);

    color: $color-gray-semi;
}

.noResults {
    @include flex(center, center, column);

    overflow: hidden;
    height: 200px;
}

.noResultsImage {
    @include flex-center;

    margin-bottom: 0;

    @include breakpoint(sm) {
        img {
            width: 75px;
            height: 75px;
        }
    }
}

.noResultsTitle {
    margin-bottom: 0;
    padding: 8px 24px 0;
    color: $color-gray-dark;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.noResultsSubtitle {
    @include flex(center, center);

    color: $color-gray-semi;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding: 8px 0;
    max-width: 360px;
}

:export {
    breakpoint: $screen-md-min;
}
