@use "@styles/helpers" as *;

.root {
    @include breakpoint(sm, max) {
        display: flex;
        flex-direction: column;
        height: 100%;

        .groups {
            flex-grow: 1;
            overflow-y: auto;
            padding-top: 16px;
        }
    }
}

// Groups

.groups {
    .addressFormGroup {
        margin-top: 24px;
    }

    .additionalDetailsFormGroup {
        margin-top: 24px;
    }

    .contactDetailsFormGroup {
        margin-top: 16px;
        padding-bottom: 8px;

        &.withoutMargin {
            margin-top: 0;
        }
    }
}

// Address

.address {
    @include flex(center, flex-start, row);

    .info {
        .street {
            @include typography(14px, 16px, 500, 500);

            color: $color-gray-dark;
        }

        .additional,
        .city {
            @include typography(12px, 14px, 400, 400);

            color: $color-gray-semi;
        }
    }

    .action {
        margin-left: 32px;
    }
}

// Contact

.contactField {
    @include breakpoint(sm) {
        width: calc(50% - 8px);
    }
}
