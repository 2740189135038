@use "@styles/helpers" as *;

.root {
    @include flex(center, flex-end);

    // Add

    .addBtn,
    .adjustBtn {
        min-height: 32px;
        min-width: 112px;
        padding: 2px 8px;
        background: $color-secondary;
        border: 1px solid transparent;
        color: $color-primary;

        .plusIcon {
            margin: 0;
        }

        &:not(.disabled):hover {
            color: $color-white;
            background: $color-primary;
            border-color: transparent;
        }

        &:not(.disabled):focus,
        &:not(.disabled):active {
            color: $color-primary;
            background: $color-secondary;
            border-color: transparent;
        }

        &.disabled {
            cursor: not-allowed;
            opacity: 0.4;
        }

        @include breakpoint(lg) {
            min-width: 112px;
        }
    }

    .adjustBtn {
        @include breakpoint(lg, max) {
            padding: 2px 12px;
            font-size: 12px;
        }
    }
}

// --- View Similar Button

.viewSimilarBtn {
    @include typography(body-small-500);

    width: 100%;
    background: $color-gray-light-ultra;
    color: $color-primary;
    padding: 2px 4px;

    &:hover:not([disabled]) {
        color: $color-white;
        background: $color-primary-shade-1;
    }

    &[disabled] {
        color: $color-white;
        background: $color-secondary-shade-2;
        cursor: not-allowed;
    }

    @include breakpoint(sm) {
        padding: 2px 14px;
    }
}

// Exports

:export {
    breakpoint_sm: $screen-sm-min;
    breakpoint_md: $screen-md-min;
    breakpoint_lg: $screen-lg-min;
}
