@use "@styles/helpers" as *;

.root {
    width: 100%;

    &:not(.inline) {
        .secondary {
            .list {
                @include flex(center, space-around, row);
    
                width: 100%;
                max-width: 350px;
                
                .item {
                    margin: 0 8px !important;
                }
            }
        }

        .tertiary {
            max-width: 350px;
            margin-top: 32px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &.inline {
        .header {
            @include flex(center, flex-start, row);

            flex-wrap: wrap;
            margin-bottom: 24px;
            row-gap: 8px;

            .title {
                max-width: 100%;
                margin-right: 24px;
                text-align: left;
            }
            
            .select {
                margin-top: 0;
            }
        }

        .primary {
            @include flex(center, space-between, row);
            
            .content {
                @include flex(flex-start, center, column);
    
                max-width: inherit;
                text-align: start;
                padding: 0;

                .mainBenefit {
                    max-width: inherit;
    
                    &:not(:first-child) {
                        margin-top: 32px;
                    }
                }
    
                .secondaryBenefits {
                    max-width: inherit;
                    margin-top: 40px;
                }
            }
    
            .image {
                margin-top: 0;
                align-self: stretch;
            }
        }

        .secondary {
            text-align: start;

            .list {
                text-align: center;
            }
        }
        
        .tertiary {
            &:not(:empty) {
                margin-top: 40px;
                text-align: start;
            }
        }
    }
}

.header {
    @include flex(center, center, column);
    @include typography(h4);

    margin-bottom: 24px;
    
    .title {
        @include typography(h4);

        text-align: center;
        flex-shrink: 0;
    }

    .select {
        width: 250px;
        margin-top: 8px;
        
        .control {
            border-radius: 16px;
        }

        .menu {
            border-radius: 16px;
        }

        .menuList {
            padding: 12px 0;
        }

        .error {
            display: none;
        }
    }
}

.primary {
    @include flex(flex-start, center, column);

    .content {
        @include flex(center, center, column);

        width: 100%;
        flex: 1;
        padding: 0 24px;
        text-align: center;

        .mainBenefit {
            max-width: 256px;

            &:not(:first-child) {
                margin-top: 16px;
            }
        }

        .secondaryBenefits {
            margin-top: 24px;
        }
    }

    .image {
        @include flex(center, center, row);
    
        position: relative;
        width: 100%;
        flex: 1;
        max-width: 340px;
        margin-top: 16px;

        &.hidden {
            display: none;
        }

        > img {
            position: relative !important;
            width: 100% !important;
            height: auto !important;
            margin: auto;
        }
    }
}

.secondary {
    width: 100%;
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid $color-gray-light;
    text-align: center;

    .list {
        text-align: center;
    }
}

.tertiary {
    margin-top: 16px;
    text-align: center;
}

.root.loading {
    .header {
        width: 100%;
        
        .title {
            width: 100%;
        }
    }

    .mainBenefit {
        width: 100%;
    }

    .skeletonContainer {
        width: 100%;

        .skeleton {
            width: 100%;
        }
    }
}