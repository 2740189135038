@use "@styles/helpers" as *;

.root {
    @include breakpoint(lg) {
        flex: 1;
        padding-right: 32px;
        max-width: 332px;
    }
}

// Categories

.categories {
    @include rounded-corners;

    padding-top: 8px;
    background-color: $color-gray-light-ultra;

    // Header

    .header {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 17px;
        font-weight: 600;
        color: $color-gray-dark;

        .icon {
            margin-right: 12px;
        }

        &:hover,
        &:focus {
            color: $color-gray-dark;
        }
    }

    // Scrollbar

    .scrollbar {
        &.active {
            max-height: 342px;
        }
    }

    // List

    .list {
        padding-bottom: 20px;
    }

    // Accordion

    .accordion {
        padding: 0 !important;

        .accordionHeader {
            padding: 8px 20px !important;
            flex-direction: row-reverse !important;
        }

        .accordionIconWrapper {
            margin-left: 0;
            margin-right: 12px;
            min-width: 24px;
            width: 24px;
            height: 24px;
        }

        .accordionContent {
            padding-left: 40px !important;
        }

        .accordionIcon {
            transform: rotate(270deg) !important;
            transform-origin: 50% 45% !important;
        }

        .accordionIconExpanded {
            transform: rotate(360deg) !important;
        }
    }

    .link {
        padding: 0 !important;
    }

    .subLink {
        padding: 8px 24px !important;
    }

    .link,
    .subLink {
        @include typography(body-small);

        transition: color 0.2s ease;

        &:hover,
        &:focus {
            color: $color-gray-dark-subtle !important;
        }

        &.active {
            font-weight: 600;
            color: $color-gray-dark-subtle !important;
        }
    }
}

// Filters

.filters {
    &.hidden {
        display: none;
    }
    
    &:not(:first-child) {
        margin-top: 16px;
    }
}

// Exports

:export {
    breakpointLg: $screen-lg-min;
}
