@use "@styles/helpers" as *;

.root {
    @include typography(small);
    @include rounded-corners(4px);
    @include flex(center, center);

    line-height: normal;
    padding: 2px 4px;

    &.info {
        background: $color-info-shade-1;
        color: $color-white;
    }

    &.success {
        background: $color-success;
        color: $color-white;
    }

    &.error {
        background: $color-error;
        color: $color-white;
    }
}
