@use "@styles/helpers" as *;

.btn {
    border: 0;
    color: $color-white;
    background: $color-genius-gradient;
    transition: none;
    position: relative;

    &:hover:not([disabled]) {
        &::before {
            opacity: 1;
            visibility: visible;
        }
    }

    &:focus {
        background: $color-genius-gradient;
    }

    &:active:not([disabled]) {
        background: linear-gradient(71.97deg, #090171 11.12%, #bf0060 34.46%, #ff005a 79.12%);
    }

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.4;
        transition: opacity 0.5s ease;
    }

    &.loading {
        background: #513186;
    }

    &::before {
        @include pos-fill;

        transition: opacity 0.5s ease;
        content: "";
        background: #513186;
        border-radius: 8px;
        opacity: 0;
    }
}

:export {
    whiteColor: $color-white;
}
