@use "@styles/helpers" as *;

.root {
    width: 100%;
    background: white;

    &.bordered {
        border-top: 1px solid $color-gray-light;
    }

    &.hasNegativeMargin {
        width: calc(100% + 32px);
        margin: 0 -16px;
    }

    &.padded {
        .inner {
            padding: 16px;

            @include breakpoint(md) {
                padding: 24px;
            }
        }
    }

    .inner {
        @include flex(center, center, column-reverse);
        
        width: 100%;
    }

    
    .btn {
        width: 100%;
    }
    
    .secondary {
        margin-top: 16px;
        margin-right: 0;
    }

    &.reversed {
        .inner {
            @include flex(center, center, column);    
        }

        .primary {
            margin-top: 16px;
        }

        .secondary {
            margin-top: 0;
        }
    }

    // Responsive

    &.responsive {
        .btn {
            @include typography(body-small-500);

            letter-spacing: .5px;
        }
    }

    // Desktop

    &.desktop {
        background: none;
    
        &.hasNegativeMargin {
            width: calc(100% + 48px);
            margin: 0 -24px;
        }

        .inner {
            @include flex(flex-end, flex-end, row);
        }

        .btn {
            width: auto;
        }

        .primary {
            margin-top: 0;
        }

        .secondary {
            margin-top: 0;
            margin-right: 16px;
        }

        &.reversed {
            .inner {
                @include flex(flex-start, flex-start, row-reverse);    
            }

            .primary {
                margin-right: 16px;
            }

            .secondary {
                margin-right: 0;
            }
        }
    }
}

:export {
    breakpoint_sm: $screen-sm-min;
    breakpoint_md: $screen-md-min;
    breakpoint_lg: $screen-lg-min;
}
