@use "@styles/helpers" as *;

.root {
    @include flex(center);

    max-width: 100%;
    color: $color-white;
    z-index: 1;

    $base-size: 14px;
    $size: calc(#{$base-size} + 4px);
    $offset: calc(-#{$base-size} + 1px);

    .label {
        @include flex(center, flex-start, row);

        position: relative;
        max-width: 100%;
        white-space: nowrap;

        .labelText {
            min-width: 0;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .overlapped {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 50%; 
            transform: translateY(-50%); 
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent rgb(51 51 51) transparent transparent;
            left: -5px; 
            border-width: 11px 5.5px 11px 0;

            @include breakpoint(sm) {
                left: -10px; 
                border-width: 13px 10.5px 13px 0;
            }
        }
    }

    .simpleLabel {
        padding: 1px 8px !important;
        border-radius: 4px !important
    }

    .sponsoredLabel {
        color: $color-gray-dark-subtle;
        background-color: $color-white;
        border: 1px solid $color-gray;
        margin-top: 4px;
    }

    .infoLabel {
        border-radius: 4px !important;
    }

    .geniusDealsContainer {
        @include flex(center, flex-start, row);

        margin-left: 8px;
        background: linear-gradient(243.55deg, #FD015A -4.23%, #1F016F 105.36%);
        border-radius: 4px;
    }
    
    .geniusDealsLogo {
        @include flex(center, flex-start, row);

        position: relative;
        left: 4px;
        padding: 3px 4px;
        font-size: 0;
    }

    .circled {
        border-radius: 40px !important;
    }

    // Size Mixins

    @mixin size-large {
        @include typography(12px, 12px, 500, 500);

        max-width: calc(100% - 8px);

        .label {
            padding: 4px 8px;
        }
        
        .label:first-child:nth-last-child(1) {
            border-radius: 4px;
        }

        .labelIcon {
            max-height: 18px;
            margin-bottom: 2px;
            margin-right: 6px;
        }
        
        .label:first-child {
            margin-left: 8px;
            padding-left: 12px;
            border-radius: 4px 0 0 4px;

            &.withIcon {
                padding-left: 10px;
            }
        }

        .label:last-child {
            padding-right: 12px;
            border-radius: 0 4px 4px 0;
        }

        .geniusDealsLogo {
            width: 87px;
        }

        &.rounded {
            .label:first-child {
                border-radius: 4px 0 0 4px;
            }
        }
    }

    @mixin size-medium {
        @include typography(12px, 12px, 500, 500);

        max-width: calc(100% - 8px);

        .label {
            padding: 2px 6px;
            
            &.sponsoredLabel {
                padding-top: 3px;
                margin-top: 4px;
            }
        }
        
        .labelIcon {
            max-height: 14px;
            margin-bottom: 1px;
            margin-right: 4px;
        }
        
        .label:first-child {
            margin-left: 6px;
            padding-left: 8px;
            border-radius: 4px 0 0 4px;

            &.withIcon {
                padding-left: 6px;
            }
        }

        .label:last-child {
            padding-right: 8px;
            border-radius: 0 4px 4px 0;
        }

        .geniusDealsLogo {
            width: 55px;
            margin-right: 2px;
        }

        &.rounded {
            .label:first-child {
                border-radius: 4px 0 0 4px;
            }
        }
    }

    @mixin size-small {
        @include typography(extra-small-500);

        max-width: calc(100% - 8px);

        .label{
            padding: 2px 4px;
            
            &.sponsoredLabel {
                padding-top: 3px;
            }
        }
        
        .labelIcon {
            max-height: 16px;
            margin-bottom: 1px;
            margin-right: 4px;
        }
        
        .label:first-child {
            margin-left: 4px;
            padding-left: 8px;
            border-radius: 4px 0 0 4px;

            &.withIcon {
                padding-left: 4px;
            }
        }

        .label:last-child {
            padding-right: 8px;
            border-radius: 0 4px 4px 0;
        }

        &.rounded {
            .label:first-child {
                border-radius: 4px 0 0 4px;
            }
        }
        
        .geniusDealsLogo {
            width: 62px;
            padding: 2px 3px;
            left: 2px;
        }
        
        .geniusDealsContainer {
            margin-left: 4px;
        }


        .overlapped {
            &:after {
                left: -5px; 
                border-width: 9px 5.5px 9px 0;
            }
        }

        .beneath {
            left: 6px;
        }

        &.withLeftOffset {
            > .beneath {
                margin-left: -4px !important;
            }
        }
    }

    // Sizes

    &.large {
        @include size-large;
    }

    &.medium {
        @include size-medium;
    }

    &.small {
        @include size-small;
    }

    // Responsive Sizes

    &.break-at-sm {
        @include breakpoint(sm, max) {
            &.responsive-large {
                @include size-large;
            }

            &.responsive-medium {
                @include size-medium;
            }

            &.responsive-small {
                @include size-small;
            }
        }
    }

    &.break-at-md {
        @include breakpoint(md, max) {
            &.responsive-large {
                @include size-large;
            }

            &.responsive-medium {
                @include size-medium;
            }

            &.responsive-small {
                @include size-small;
            }
        }
    }

    &.break-at-lg {
        @include breakpoint(lg, max) {
            &.responsive-large {
                @include size-large;
            }

            &.responsive-medium {
                @include size-medium;
            }

            &.responsive-small {
                @include size-small;
            }
        }
    }

    .label, .geniusDealsLogo {
        &.beneath {
            padding-right: 12px !important;
        
            @include breakpoint(sm) {
                padding-right: 18px !important;
            }
        }
    }
}
