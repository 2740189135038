@use "@styles/helpers" as *;

.root {
    @include rounded-corners;

    overflow: hidden;

    &.elevation-0 {
        border: 1px solid transparent;
    }

    &.elevation-1 {
        box-shadow: 0 2px 8px 0 rgb(0 0 0 / 4%);
        border: 1px solid $color-gray-light;
    }

    &.elevation-2 {
        box-shadow: 0 4px 16px 0 rgb(0 0 0 / 5%);
        border: 1px solid $color-gray-light;
    }

    &.elevation-3 {
        box-shadow: 0 8px 16px 0 rgb(0 0 0 / 6%);
        border: 1px solid $color-gray-light;
    }

    &.padded {
        &.medium {
            padding: 16px;
        }

        &.large {
            padding: 32px;
        }
    }

    &:not(.bordered) {
        border: 1px solid transparent;
    }
}
