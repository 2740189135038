@use "@styles/helpers" as *;

.root {
    .content {
        min-height: auto !important;
        max-width: 640px !important;
        overflow: visible !important;

        .inner {
            overflow: visible !important;
        }
    }

    @include breakpoint(sm) {
        .content {
            max-width: 640px !important;
        }
    }

    @include breakpoint(sm, max) {
        .inner {
            height: 100%;
        }

        .content {
            height: 100%;
        }
    }
}

.formContainer {
    margin-top: 16px;

    @include breakpoint(md) {
        margin-top: 0;
    }
}
