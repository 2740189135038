@use "@styles/helpers" as *;

.root {
    @include flex(center);

    &.withLabel {
        @include rounded-corners;

        background: $color-genius-tint-1;
        padding: 2px 8px;
    }
}

.label {
    @include typography(body-small);

    color: $color-genius;
    margin-right: 6px;
}
