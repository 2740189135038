@use "styles/helpers" as *;


.root {
    @include flex(flex-start, center, column);
    @include rounded-corners(24px);

    border: 1px solid $color-gray-light;
    padding: 16px;

    @include breakpoint(md) {
        flex-direction: row;
        align-items: center;
    }
}

.content {
    @include flex(flex-start, flex-start);

    flex-grow: 1;
}

.title {
    @include typography(16px, 18px, 500, 500);

    color: $color-gray-dark;
}

.date {
    @include typography(body);

    color: $color-gray-dark-subtle;
}

.description {
    @include typography(body);

    margin-top: 2px;
    color: $color-gray-semi;
}


.iconContainer {
    @include flex(center, center);
    
    margin-right: 12px;
    margin-top: 1px;
}

.icon {
    color: $color-primary;
}

.actions {
    @include flex(center, flex-start);

    flex-shrink: 0;
    flex-wrap: wrap;
    margin-left: 36px;
    margin-top: 4px;

    
    .detailsLink {
        @include flex(center);
        @include typography(body-small-500);

        padding-left: 0;
        padding-right: 0;
        color: $color-primary;

        .icon {
            transition: transform 0.15s ease;
        }

        @include with-hover {
            &:hover {
                color: $color-primary-shade-2;

                .icon {
                    transform: translateX(2px);
                    color: $color-primary-shade-2;
                }
            }
        }
    }

    @include breakpoint(md) {
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        margin-top: 0;
    }
}

.skeleton {
    .iconSkeleton {
        width: 24px;
        height: 24px;
    }

    .titleSkeleton {
        width: max(100%, 300px);
    }

    .descriptionSkeleton {
        width: 120px;
    }

    .dateSkeleton {
        width: max(50%, 300px);
    }

    .actionsSkeleton {
        width: 110px;
        
        &:nth-of-type(2) {
            margin-left: 16px;
            margin-top: 2px;
        }
    }
}
