@use "@styles/helpers" as *;

.root {
    padding: 16px 0;
    border-bottom: 1px solid $color-gray-light;

    &:not(.inline) {
        .content {
            @include flex(flex-start, space-between, column);

            flex: 1;

            .priceContainer {
                margin-top: 8px;
                text-align: left;
                flex: 1;
                width: 100%;

                .price,
                .quantity {
                    min-width: 0 !important;
                    flex-shrink: 0;
                }
            }
        }
    }

    &.inline {
        .content {
            @include flex(center, space-between, row);

            flex: 1;

            .priceContainer {
                text-align: right;
                margin-left: 24px;

                @include breakpoint(sm, max) {
                    @include flex(flex-end, center, column);
                }
            }
        }
    }
}

// Replacements

.replacements {
    display: flex;
    flex-direction: column;
}

.replacement {
    @include flex(center, space-between);

    background: $color-gray-light-ultra;
    padding: 8px 16px;
    margin: 8px -16px 0;

    .replacementName.original {
        color: $color-gray-dark !important;
    }
    
    .replacementQuantity {
        color: $color-gray-dark-subtle;
    }


    @include breakpoint(md) {
        border-radius: 8px;
        padding: 8px 16px 8px 0;
        margin: 8px 0 0;
    }
}

// Product Row

.productRow {
    @include flex(center);

    width: 100%;
}

.productRow.unavailable {
    .imageContainer {
        opacity: 0.5;
    }

    .name {
        opacity: 0.5;
    }
}

.productRow.small {
    .name {
        @include typography(14px, 14px, 400, 400);

        color: $color-gray-dark-subtle;
    }

    .price {
        @include typography(14px, 16px, 700, 500);
    }
}

// --- Image

.imageContainer {
    @include flex(center, center);

    flex-shrink: 0;
    max-width: 48px;
    max-height: 48px;
    border-radius: 8px;
    overflow: hidden;

    .imageLink {
        @include flex(center, center);
    }

    @include breakpoint(md) {
        max-width: 64px;
        max-height: 64px;
    }
}

// Content

.content {
    margin-left: 16px;
}

// --- Details

.details {
    @include flex(flex-start, space-between, column);

    flex: 1;
}

.name {
    @include typography(body-500);

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 42px;
    flex-grow: 1;
    line-height: 21px;
    color: $color-gray-dark;
    overflow: hidden;
}

.message {
    @include typography(14px, 14px, 400, 400);

    color: $color-error;
}

// --- Price / Quantity

.priceContainer {
    @include flex(center, space-between, row);
}

.quantity {
    @include typography(body);

    color: $color-gray-semi;
    min-width: 75px;
}

.price {
    @include flex(center, flex-end, row);
    @include typography(14px, 18px, 500, 700);

    min-width: 90px;

    @include breakpoint(sm) {
        min-width: 124px;
        margin-left: 16px;
    }
}

.icon {
    @include flex(center, center);

    flex: 48px 0;
    color: $color-border;
    padding-left: 4px;

    @include breakpoint(md) {
        flex: 64px 0;
        padding-left: 8px;
    }
}

// Exports

:export {
    colorPrimary: $color-primary;
    breakpoint: $screen-md-min;
}
