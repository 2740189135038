@use "styles/helpers" as *;


.root {
    @include flex(center);
    @include typography(body-small);

    padding: 16px 0;

    // Image
    .imageWrapper {
        flex-shrink: 0;
        font-size: 0;
    }

    .image {
        @include flex(center, center);
        @include rounded-corners;
  
        flex-shrink: 0;
    }


    // Product name
    .title {
        @include clamp(2, 20);

        flex-grow: 1;
        margin-right: 16px;
    }


    // Quantity
    .quantityContainer {
        @include flex(center, center, column);

        flex-shrink: 0;
    }

    .quantity {
        @include typography(body);

        color: $color-gray-dark-subtle;
    }


    // Price
    .unitPriceWrapper {
        .unitPrice {
            @include typography(12px, 14px);

            font-weight: 300;
            color: $color-gray-semi;
        }
    }

    .price {
        min-width: 80px;
        margin-left: 16px;
        flex-shrink: 0;
    }

    @include breakpoint(md) {
        .price {
            margin-left: 24px;
        }
    }
}

.discountLabel {
    @include typography(12px, 14px);

    padding: 1px 8px;
    min-width: 46px;
    border-radius: 4px;
    color: $color-white;
    background-color: $color-discount;

    &.desktopDiscountLabel {
        display: none;

        &.hidden {
            opacity: 0;
            visibility: hidden;
        }
    }

    &.mobileDiscountLabel {
        @include flex(center, center);

        margin: 3px 0;
    }

    @include breakpoint(md) {
        &.desktopDiscountLabel {
            @include flex(center, center);

            margin-right: 32px;
            margin-left: 16px;
        }

        &.mobileDiscountLabel {
            display: none;
        }
    }
}