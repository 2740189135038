@use "@styles/helpers" as *;

.group {
    &:not(:first-of-type) {
        margin-top: 8px;

        @include breakpoint(md) {
            margin-top: 16px;
        }
    }

    .groupHeader {
        padding-left: 16px;
        padding-right: 16px;

        @include breakpoint(md) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .groupTitle {
        @include typography(body-small);
    }

    &.gifts {
        .groupIcon {
            margin-bottom: 2px;
            color: $color-gift;
        }
    }
}
