@use "@styles/helpers" as *;

.root {
    flex-grow: 1;
    padding-top: 40px;
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
        padding-top: 0;
    }
}

.links {
    display: flex;
    margin-top: 24px;
    width: 100%;
    flex-direction: column;

    .col {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    @include breakpoint(lg) {
        flex-direction: row;
    }

}

.linksSection {
    border-bottom: 1px solid $color-gray;
    margin: 0 -16px;
    padding: 0 16px;

    a {
        color: $color-gray-dark-subtle;
    }

    @include breakpoint(md) {
        margin: 0;
        padding: 0 24px;
    }

    @include breakpoint(lg) {
        border-bottom: none;
        padding: 0;;
        margin: 0;

        a:hover {
            color: $color-primary;
        }
    }

    @include breakpoint(xl) {
        border-bottom: 0;
 
        &:first-of-type {
            padding-left: 0;
        }
    }
}

.linksSectionTitle {
    @include typography(body-small-500);

    margin: 12px 0;

    &.expanded {
        margin-bottom: 16px;
    }

    .sectionExpandButton {
        @include typography(16px, 16px, 500, 700);
        @include flex(center, space-between);
        @include button-reset;

        color: $color-gray-dark;
        width: 100%;

        .chevron {
            transition: transform 0.3s ease-in-out;
            color: $color-gray-semi;
        }

        .chevron.expanded {
            transform: rotate(180deg);
        }
    }

    @include breakpoint(lg) {
        margin-top: 0;
        margin-bottom: 16px;

        &.expanded {
            margin-bottom: 8px;
        }
    }
}

.linkContainer {
    @include hidden(true);
    @include typography(body-small);

    &:not(:first-of-type) {
        margin-bottom: 16px;
    }

    &.expanded {
        @include visible(block);
    }

    @include breakpoint(lg) {
        margin-bottom: 12px;

        &:not(:first-of-type) {
            margin-bottom: 12px;
        }

        &:last-of-type {
            margin-bottom: 24px;
        }
    }
}


:export {
    breakpoint: $screen-lg-min-value;
}
