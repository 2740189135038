@use "@styles/helpers" as *;


.root {
    .icon.centered {
        align-self: center;
    }
  
    .title {  
        margin-bottom: 0;
    }
  
    .description {
        line-height: normal;
    }
}