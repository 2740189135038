@use "@styles/helpers" as *;

.popoverContent {
    position: relative;
    padding: 12px;

    .popoverTitle {
        @include typography(12px, 12px, 500, 500);
    }

    .popoverText {
        @include typography(12px, 12px, 400, 400);

        margin-top: 4px;
    }

    .popoverClose {
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
    }
}

.inner {
    cursor: pointer;
}