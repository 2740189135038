@use "@styles/helpers" as *;

.root {
    @include rounded-corners;

    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    max-width: 300px;
    background: $color-white;
    border: 1px solid $color-gray-light;
    color: $color-gray-dark;
    transition: border-color 0.2s ease;

    &:hover {
        border-color: $color-gray;

        .favoritesButton {
            background-color: $color-white !important;
        }
    }

    &.disabled {
        pointer-events: none;
        cursor: default;

        > * {
            pointer-events: none;
        }
    }
}

// Loading Overlay

.loadingOverlay {
    @include pos-fill(-1px);
    @include flex(center, center);

    background: rgb(255 255 255 / 85%);
    z-index: z("product-card-loading-overlay");
}

// Favorites

.favoritesButton {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: z("product-card-favorites-button");
}

// Image

.imageContainer {
    @include flex(center, center);

    width: 100%;
    position: relative;
    background: $color-gray-light-ultra;
    border-radius: 8px 8px 0 0;
}

.image {
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: auto;
    max-width: 300px;

    &.unavailable {
        filter: opacity(0.4);
    }
}

// --- Labels

.labels {
    position: absolute;
    top: 8px;
    left: -1px;
    z-index: z("product-card-labels");
}

// --- Optional Card Decorations

.optionalCardDecorations {
    @include flex($flex-direction: column);

    width: calc(100% - 16px);
    position: absolute;
    bottom: 8px;
    left: 8px;

    .optionalCardDecorationsInner {
        @include flex(flex-end, space-between, row);

        flex: 1;
        width: 100%;

    }
    
    // --- Secondary Labels

    .secondaryLabels {
        @include flex(flex-start, flex-end, column);
        
        width: 100%;

        .secondaryLabel {
            max-width: 100%;
            margin-left: -4px;
            left: 0;
        }
    }

    .infoLabel {
        margin-left: -4px !important;
        margin-top: 4px;
    }

    // --- Tags

    // Tags have to behave differently on smaller screens
    // when the banner is present and there is more then one label on the card

    .tags {
        &.wrapped {
            flex-wrap: wrap;
            max-height: 34px;
            
            @include breakpoint(380px) {
                max-height: 64px;
            }
            
            @include breakpoint(430px) {
                flex-wrap: nowrap;
                max-height: 100%;
            }
        }
    }

    // --- Banner
    
    .banner {
        min-height: 24px;
        width: 100%;
        margin: 4px 0 0;
    }
}

.badge {
    position: absolute;
    bottom: -12px;
    z-index: z("product-card-badge");
}

// Content

.content {
    @include flex(flex-start, flex-start, column);

    position: relative;
    padding: 0 8px 8px;
}

// --- Brand

.brand {
    @include truncate-text(100%);
    @include typography(body-small);

    height: 20px;
    margin: 16px 0 2px;
    padding: 0 2px;
    color: $color-gray-semi;
    overflow: hidden;
    text-overflow: ellipsis;
}

// --- Name

.name {
    min-height: 40px;
    flex-grow: 1;
    padding: 0 2px;
}

.nameText {
    @include typography(body-small-500);
    @include clamp(2, 20);
}

// --- Price

.content .priceContainer {
    display: flex;
    flex: 1;
    width: 100%;
    padding: 0 2px;

    .productPrice {
        flex-direction: column !important;
        align-items: flex-start;
        width: 100%;
        flex: 1;
        margin-top: 4px;
    }

    .originalPrice {
        @include typography(14px, 14px, 400, 400);

        min-height: 14px;
        line-height: 14px;

        &:not(.withStrikethrough) {
            font-weight: 500;
        }
    }

    .currentPrice {
        @include typography(18px, 18px, 700, 700);
    }

    .taxes {
        margin-top: 4px;
    }

    .unitPriceLabel {
        @include typography(14px, 14px, 400, 400);

        margin-top: 4px;
        margin-left: 0;
    }
}

// Footer

.footer {
    @include flex(flex-start, center, column);

    position: relative;
    width: 100%;
    height: 32px;
    margin-top: 8px;
}

// --- View Similar Button

.viewSimilarBtn {
    width: 100%;
}

// Skeleton

.skeletonRoot {
    .imageContainer,
    .content {
        display: block;
    }

    .content {
        margin-top: 16px;
    }
}

.imageSkeleton {
    width: 100%;
    height: 0 !important;
    padding-bottom: 100%;
    border-radius: 8px 8px 0 0 !important;
    line-height: 1.5 !important;
}

.contentSkeleton {
    margin-top: 8px;
}
