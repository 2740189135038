@use "@styles/helpers" as *;

.root {
    @include flex(center, flex-start, row);

    &.info {
        color: $color-info-shade-1;

        .badge {
            background: $color-info-shade-1;
            color: $color-white; 
        }
    }

    &.success {
        color: $color-success;

        .badge {
            background: $color-success;
            color: $color-white;
        }
    }

    &.error {
        color: $color-error;

        .badge {
            background: $color-error;
            color: $color-white;
        }
    }
}

.label {
    @include typography(body-small);

    margin-left: 8px;
}
