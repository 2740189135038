@use "@styles/helpers" as *;

.prev {
    @include breakpoint(sm) {
        left: -24px !important;
    }
}

.next {
    @include breakpoint(sm) {
        right: -24px !important;
    }
}

.prev,
.next {
    top: 54% !important;
}
