@use "@styles/helpers" as *;

.root {
    @include rounded-corners;

    width: $account-navigation-width;
    position: sticky;
    top: $desktop-header-height + $container-padding;
    align-self: flex-start;
    flex-shrink: 0;
    display: none;
    margin-right: 32px;

    @include breakpoint(lg) {
        display: block;
    }
}

.title {
    @include flex(center, flex-start, row);
    @include typography(h5);

    position: sticky;
    padding: 0 0 16px;
    top: 0;
    z-index: 1;
    min-height: 48px;
}

.accountMenu {
    .menu {
        background-color: $color-white;
        border-radius: 16px;
        overflow: hidden;

        .item {
            .link {
                padding-left: 16px;
                padding-right: 16px;
            }

            &:first-of-type {
                .link {
                    padding-top: 16px;
                }
            }
        
            &:last-of-type {
                .link {
                    padding-bottom: 16px;
                }
            }
        }

    }
}

