@use "@styles/helpers" as *;

.root {
    @include flex(center, center);

    margin: 24px -8px 0; 

    @include breakpoint(lg) {
        @include flex(center, flex-start);

        margin: 8px -8px 0;
    }
}

.image {
    @include flex(center, center);

    margin: 0 8px;
}