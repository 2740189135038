@use "@styles/helpers" as *;

.root {
    @include rounded-corners;
    @include flex(flex-start);

    background-color: $color-white;
    position: relative;
    padding: 8px;
}

// Content

.content {
    @include flex(flex-start, flex-start, column);

    position: relative;
    flex-grow: 1;
}

.row {
    @include flex(center, space-between);

    width: calc(100% - 12px);
    margin-left: 12px;
}

.name {
    padding: 0;
    border: none;
    border-radius: 0;
}

.removeBtn {
    color: $color-gray-semi;

    &:hover {
        color: $color-primary;
    }
}

// --- Image

.imageContainer {
    position: relative;
    flex-shrink: 0;
    max-width: 76px;
    max-height: 76px;
    line-height: 1;

    .image {
        @include rounded-corners;

        &.unavailable {
            opacity: 0.4;
        }
    }

    .badge {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -5px;
        margin: auto;
        height: 16px;
        line-height: 16px;

        .suffix {
            @include typography(12px, 12px, 700, 500);

            padding: 0 8px;
        }
    }
}

// --- Name

.nameRow {
    align-items: flex-start;
}

.nameContainer {
    @include flex(flex-start);

    height: 32px;
    margin-bottom: 12px;
    padding-top: 4px;
    padding-right: 8px;
}

.name {
    @include typography(body-small);

    flex-grow: 1;
    color: $color-gray-dark-subtle;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 32px;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

// --- Error

.errorRow {
    margin-bottom: 8px;
}

.error {
    @include typography(small-500);

    color: $color-error;
    line-height: 16px;
}

// --- Label

.labelRow {
    margin-top: 4px;
    margin-left: 10px;
    margin-bottom: 8px;
}

// --- Details

.detailsRow {
    min-height: 32px;

    &.unavailable {
        margin-left: 10px;
    }
}

// ------ Price

.priceContainer {
    @include flex(flex-start, center, column);

    position: relative;
    line-height: 14px;
}

.productPrice {
    @include flex(flex-start, center, column);

    .originalPrice {
        margin-bottom: 2px;
    }

    .taxes {
        margin-top: 4px;
    }
}

// ------ Loader

.loader {
    @include pos-fill(0);
    @include flex(center, center);

    left: 0;
    right: auto;
    width: 100%;
    height: 30px;
    margin: auto;
}

// Loading

.loadingOverlay {
    @include pos-fill;
    @include flex(center, center);
    @include rounded-corners;

    z-index: z("product-cart-card-loading-overlay");
    background: rgb(255 255 255 / 80%);
}

// Actions

.actionsContainer {
    margin-left: 4px;
    align-self: flex-end;
}

.viewSimilarBtn {
    .viewSimilarBtnIcon {
        margin-bottom: 2px;
        margin-right: 12px;
    }
}

// Skeleton

.contentSkeletonWrapper {
    width: 100%;
    padding-left: 16px;
}
