@use "@styles/helpers" as *;

.root {
    width: 100%;

    @include flex(flex-start, flex-start, column);

    &.inline {
        @include flex(center, flex-start, row);
    }
}

.label {
    @include typography(body-small-500);

    flex-shrink: 0;
    margin-right: 8px;
    line-height: 24px;
    color: $color-gray-dark;
}

.list {
    width: 100%;

    &.inline {
        @include flex(center, flex-start, row);
    }

    &:not(.inline) {
        @include flex(flex-start, flex-start, column);

        .item {
            width: 100%;

            &:not(:first-child) {
                margin-top: 16px;
            }
        }
    }
}

.clearFiltersBtn {
    @include typography(body-small-500);
    @include button-reset;

    flex-shrink: 0;
    margin-left: 8px;
    padding: 0;
    color: $color-primary;

    &:hover {
        color: $color-primary-shade-1;
    }
}