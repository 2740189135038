@use "@styles/helpers" as *;

.scrollbar {
    position: relative;
    max-height: 100%;
    height: 100%;
}

.menu {
    .menuLink {
        position: relative;
        padding: 6px 16px;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        border-radius: 0;
        font-size: 14px;
        transition: none;
    
        &.selected {
            background-color: $color-white;
            border-top: 1px solid $color-gray-light;
            border-bottom: 1px solid $color-gray-light;
        }
    }
    
    .menuItem {
        .menuIcon {
            width: 22px;
        }
    
        &:first-child {
            .menuLink {
                padding-top: 12px;
    
                &.selected {
                    border-top: 1px solid transparent;
                }
            }
        }
    
        &:last-child {
            .menuLink {
                padding-bottom: 12px;
                border-bottom-left-radius: 12px;
    
                &.selected {
                    border-bottom: 1px solid transparent;
                }
            }
        }
    }
}

