@use "styles/helpers" as *;

.root {
    position: relative;
}

.carousel {
    max-width: 100%;
    margin-top: 16px;
}

.slide {
    max-width: 102px;
    width: 100%;
}

.category {
    text-align: center;
    height: 98px;

    .categoryContent {
        @include flex(center, flex-start, column);

        .categoryName {
            @include flex(center, center);
            @include typography(10px, 12px);

            min-height: 40px;
            padding: 0 4px;
        }
    }
}

.imageWrapper {
    @include flex(center, center);

    width: 56px;
    height: 56px;
}

.categoryImage {
    max-width: 56px;
    max-height: 56px;
}

.categoryImageBg {
    background-color: #F9F9F9;
    border-radius: 4px;
    overflow: hidden;
}

.fade {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 50px;
    height: 100%;
    z-index: 10;
    transition: all 0.4s ease;
    cursor: pointer;

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }

    &.left {
        width: 50px;
        left: 0;
        background:
            linear-gradient(
                to left,
                transparentize($color-white, 1) 0%,
                transparentize($color-white, 0) 100%
            );
    }

    &.right {
        right: 0;
        background:
            linear-gradient(
                to right,
                transparentize($color-white, 1) 0%,
                transparentize($color-white, 0) 100%
            );
    }
}