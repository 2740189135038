@use "@styles/helpers" as *;


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Fira Sans", sans-serif;
    font-size: inherit;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}
    
html,
body {
    height: 100%;
    width: 100%;
    background: $color-background;
    color: $color-gray-dark;
    font-size: 16px;
    line-height: 1.5;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-text-size-adjust: 100%;
}
    
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
    margin: 0;
    padding: 0;
}
    
ul {
    list-style: none;
}
    
input {
    &[type="text"] {
        appearance: none;
    }
    
    &:focus {
        outline: 0;
    }
}
    
a {
    text-decoration: none;
    color: inherit;
}
    
@media (orientation: landscape) {
    body {
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
    }
}

