@use "@styles/helpers" as *;

.root {
    @include flex(flex-start, center, row);
}

.variant {
    @include rounded-corners;

    background: none;
    outline: none;
    padding: 4px;
    border: 1px solid $color-gray-light;
    transition: box-shadow 0.2s ease, border-color 0.2s ease;
    cursor: pointer;

    &:not(.selected) {
        &:hover {
            border-color: $color-secondary-shade-2;
        }
    }

    &.selected {
        box-shadow: 0 0 0 1px $color-primary;
    }

    &:not(:first-child) {
        margin-left: 16px;
    }
}

.thumbnail {
    position: relative;
    max-width: 96px;
    max-height: 96px;

    .label {
        position: absolute;
        left: 0;
        top: 8px;
    }
}

.image {
    @include rounded-corners;

    &.unavailable {
        opacity: 0.4;
    }
}

.price {
    margin-top: 4px;
}
