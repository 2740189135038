@use "@styles/helpers" as *;

// Description

.description {
    @include typography(14px, 18px, 400, 400);

    margin-bottom: 24px;

    @include breakpoint(md) {
        margin-bottom: 32px;
    }
}

// Video

.video {
    @include rounded-corners(16px);
}