@use "@styles/helpers" as *;

.root {
    overflow: hidden;
}

.list {
    display: flex;
    flex-wrap: wrap;
}

.item {
    flex-shrink: 0;
}