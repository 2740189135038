@use "@styles/helpers" as *;

.root {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;
    padding: 0 8px;

    @include breakpoint(sm) {
        margin: 0 -24px;
        padding: 0 16px;
    }
}

.root .itemWrapper {
    margin: 16px 8px;
    flex-shrink: 0;
    width: calc(100% - 16px);

    @include breakpoint(sm) {
        margin: 16px 12px;
        width: calc(100% / 2 - 24px);
    }

    @include breakpoint(md) {
        width: calc(100% / 3 - 24px);
    }
}
