@use "@styles/helpers" as *;

.root {
    @include flex(center);
    @include typography(body);

    margin: 16px -16px 0;
    border-top: 1px solid $color-gray-light;
    border-bottom: 1px solid $color-gray-light;
    padding: 16px;

    @include breakpoint(sm) {
        border: 0;
        padding: 0;
        margin: 40px 0 0;
        display: none;
    }
}

.label {
    color: $color-gray-dark-subtle;
    font-weight: 500;
    flex-shrink: 0;
}

.brandLink {
    color: $color-primary;
    margin-left: 8px;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
        color: $color-primary-shade-1;
    }
}

.icon {
    flex-shrink: 0;
    margin-left: 8px;
    color: $color-gray-semi;
}
