@use "@styles/helpers" as *;

.root {
    width: 100%;

    &.start {
        @include flex(center, flex-start, row);

        .edit {
            @include flex(center, flex-start, row);
        }
    }

    &.end {
        @include flex(center, flex-end, row);

        .edit {
            @include flex(center, flex-end, row);
        }
    }
}

// Common

.add,
.adjust,
.edit {
    width: 100%;

    .btn {
        height: 100%;
        width: 100%;
    }

    &.small {
        height: 32px;
    }

    &.medium {
        height: 40px;
    }

    &.large {
        height: 48px;
    }
}

.add.compact,
.adjust.compact,
.edit.compact {
    width: auto;
}

// Add / Adjust

.add,
.adjust {
    .btn {
        .btnContent {
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .btnIcon {
            margin-right: 0;
        }

        .btnIcon.spaced {
            margin-right: 8px;
        }
    }
}

// Buy more save more

.hasBuyMore {
    @include flex(center, space-between);     

    gap: 8px;

    .buyMoreBtn {
        @include typography(body-small-500);;

        color: $color-buy-more;
        background: $color-bg-buy-more;
        transition: background 0.3s ease;
        
        @include with-hocus($hover: true, $focus: false) {
            background: darken($color-bg-buy-more, 3%);
        }

        padding: 0 !important;
        
        @include breakpoint(380px) {
            padding: 0 16px;
        }

        &.wide {
            padding: 0 8px !important;
        }
    }

    .btn:last-of-type {
        width: fit-content;
        padding: 0 8px;
        
        @include breakpoint(380px) {
            padding: 0 16px;
        }

        flex-basis: 30%;
    }
}

// --- Compact

.add.compact,
.adjust.compact {
    &.small {
        .btn.fixed {
            width: 40px;
        }
    }

    &.medium {
        .btn.fixed {
            width: 48px;
        }
    }

    &.large {
        .btn.fixed {
            width: 54px;
        }
    }
}

// Edit

.edit {
    @include flex(center, center, row);

    &.small {
        .btn {
            min-width: 32px;
        }
    }

    &.medium {
        .btn {
            min-width: 40px;
        }
    }

    &.large {
        .btn {
            min-width: 48px;
        }
    }
}

// --- Compact

.edit.compact {
    &.small {
        .btn {
            width: 32px;
        }

        .input.fixed {
            width: 40px;
        }
    }

    &.medium {
        .btn {
            width: 40px;
        }

        .input.fixed {
            width: 48px;
        }
    }

    &.large {
        .btn {
            width: 48px;
        }

        .input.fixed {
            width: 54px;
        }
    }
}

// Quantity Buttons

.quantityBtn {
    position: relative;
    flex: 1;
    padding: 0;

    &.fixed {
        flex: 0;
    }

    &.disabled {
        opacity: 0.4;
    }

    .btnContent {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .icon {
        @include pos-fill;
        @include flex(center, center, row);

        opacity: 0;
        transform: translateY(16px);
        transition: transform 0.2s ease, opacity 0.2s ease, color 0.1s ease, background-color 0.1s ease;
    }

    .icon.top {
        transform: translateY(-16px);
    }

    .icon.visible {
        opacity: 1;
        transform: translateY(0);
        transition: transform 0.2s ease, opacity 0.2s ease, color 0.1s ease, background-color 0.1s ease;
    }

    &.increaseBtn {
        .icon {
            transition: transform 0.2s ease 0.1s, opacity 0.2s ease 0.1s, color 0.1s ease, background-color 0.1s ease;
        }

        .icon.visible {
            transition: transform 0.2s ease 0.1s, opacity 0.2s ease 0.1s, color 0.1s ease, background-color 0.1s ease;
        }
    }

    @include breakpoint(sm) {
        &:not([disabled]) {
            &:focus,
            &:active {
                background: $color-secondary;
                color: $color-primary;
            }

            &:hover,
            &:focus-visible {
                color: $color-primary-shade-1;
                background: $color-secondary-shade-1;
            }
        }
    }
}

// Quantity Input

.quantityInput {
    @include rounded-corners(8px);
    @include typography(body-small-500);

    flex: 1.5;
    align-self: stretch;
    width: 100%;
    height: 100%;
    margin: 0 8px;
    padding: 0 6px;
    outline: 0;
    border: 1px solid $color-gray-light;
    box-shadow: none;
    text-align: center;
    text-overflow: ellipsis;
    color: $color-gray-dark-subtle;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &.rounded {
        @include rounded-corners(16px);
    }

    @include breakpoint(sm) {
        margin: 0 12px;
    }

    &::selection {
        background-color: $color-secondary;
    }

    &.edit {
        background-color: white;
        color: $color-gray-dark;
        border: 1px solid $color-gray-dark-subtle;
    }

    &.error {
        border-color: $color-error;
        color: $color-error;
    }

    &.compact {
        @include rounded-corners(8px);

        margin: 0 8px;
    }

    &:focus:not(.error, .edit),
    &:hover:not(.error, .edit, [disabled]) {
        border-color: $color-gray;
    }

    @include breakpoint(sm, max) {
        transition: none !important;
    }
}
