@use "@styles/helpers" as *;

@use "../../../node_modules/react-toastify/dist/ReactToastify.css";

:root {
    --toastify-toast-width: 340px;
    --toastify-color-progress-light: #008000;
}

.Toastify__toast {
    @include flex(center);
    @include typography(body-small);

    min-height: 48px;
    color: $color-white;
    margin-bottom: calc(0px + env(safe-area-inset-bottom));

    &:not(:first-child) {
        margin-top: 8px;
    }

    @include breakpoint(sm) {
        &:not(.Toastify__toast--default) {
            @include rounded-corners;
        }
    }

    &.Toastify__toast__top_close {
        .Toastify__close-button {
            align-self: flex-start;
        }
    }
}

.Toastify__toast-icon {
    @include flex(center, center, row);

    width: 32px;
    height: 32px;
    border-radius: 32px;
    align-self: flex-start;
}

.Toastify__close-button {
    @include flex(center);

    align-self: center;
    opacity: 1;
}

.Toastify__toast--default {
    .Toastify__close-button {
        padding: 2px;
        color: $color-gray;
    }
}

.Toastify__progress-bar {
    background: $color-primary;
    height: 4px;
}

.Toastify__toast--success {
    background: $color-success;
}

.Toastify__toast--info {
    background: $color-info;
}

.Toastify__toast--error {
    background: $color-error;
}

.Toastify__toast--warning {
    background: $color-warning;
}

// Transitions

.toast-transition-enter-pop-in {
    animation: none;
}

@keyframes toast-transition-exit-fade-out {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes toast-transition-enter-fade-in-bottom {
    0% {
        opacity: 0;
        transform: scale(1) translateY(100%);
    }
    
    100% {
        opacity: 1;
        transform: scale(1) translateY(0%);
    }
}

.toast-transition-exit-fade-out {
    animation: toast-transition-exit-fade-out 0.2s cubic-bezier(0.12, 0, 0.39, 0) both;
}

.toast-transition-enter-fade-in-bottom {
    animation: toast-transition-enter-fade-in-bottom 0.2s cubic-bezier(0.175, 0.885, 0.32, 1) both;
}

.toast-transition-enter-slide-right {
    animation: Toastify__slideInRight 0.4s cubic-bezier(0.61, 1, 0.88, 1) both;
}
