@use "@styles/helpers" as *;

.root {
    @include flex(center);
    @include typography(body);

    position: relative;
    padding: 12px 24px;
    border: 0;
    outline: none;
    box-shadow: none;
    background: none;
    color: $color-gray-dark-subtle;
    cursor: pointer;

    .content {
        @include flex(center, space-between, row);

        transition: none;
        width: 100%;

        &.column {
            @include flex(center, center, row);
        }
    }

    .inner {
        @include flex(center, flex-start, row);

        &.column {
            @include flex(center, center, column);

            .startIconWrapper {
                margin-right: 0;
            }

            .endIconWrapper {
                margin-left: 0;
            }

            .label {
                margin-top: 8px;
            }
        }
    }

    .icon {
        color: $color-primary;
    }

    .iconWrapper {
        @include flex(center, center);
    }

    .startIconWrapper {
        margin-right: 16px;
    }

    .endIconWrapper {
        margin-left: 8px;
    }

    .image {
        @include flex(center, center, row);

        margin-right: 16px;
        width: 24px;
        height: 24px;
        max-width: 24px;
        max-height: 24px;
        line-height: 1;
    }

    .label {
        flex: 1;
        text-align: start;
        line-height: 1.5;
    }

    .badge {
        margin-left: 8px;
    }

    .chevron {
        @include flex(center, center, row);

        // width: 16px;
        // height: 16px;
        margin-left: 16px;
        color: $color-gray-semi;
    }

    &.active {
        color: $color-primary;
    }

    &.expanded {
        border-bottom: 0;

        .chevron {
            transform: rotateZ(180deg);
        }
    }

    &.withImage {
        .link {
            padding: 12px 24px;

            .label {
                line-height: 1.4;
            }
        }
    }

    &.withPopover {
        cursor: default;
    }

    &:not(.withPopover):focus,
    &:not(.withPopover):hover {
        color: $color-primary-shade-2;
    }
}

.skeleton {
    .label {
        font-size: 0;
    }

    .skeletonContainer {
        width: 100%;
    }
}