@use "@styles/helpers" as *;

.root {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
}

.root iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.overlay {
    @include pos-fill;
}