@use "@styles/helpers" as *;

// Title

.title {
    @include typography(13px, 13px, 400, 400);

    margin-bottom: 8px;
    padding: 0 16px;
    color: $color-gray-semi;
}

// List

.list {
    .item {
        padding: 8px;

        &:not(:first-child) {
            border-top: 1px solid $color-gray-light;
        }
    }
}

// --- Group

.group {
    .groupHeader {
        @include flex(center, flex-start, row);

        padding: 12px 16px 0;
        border-right: 0;
        margin-bottom: 8px;
    }

    .groupTitle {
        @include typography(14px, 14px, 400, 400);

        line-height: normal;
        color: $color-gray-dark-subtle;
        letter-spacing: .15px
    }

    .groupIcon {
        @include flex(center, center);

        flex-shrink: 0;
        margin-left: 2px;
        margin-right: 16px;
    }

    &:not(:first-of-type) {
        margin-top: 0;
        padding-top: 8px;
        border-top: 1px solid $color-gray-light;

        .item:last-of-type {
            border-bottom-width: 0;
        }
    }
}
