@use "@styles/helpers" as *;

.error {
    padding: 16px 16px 32px;
}

.image {
    margin-left: 0;
    margin-right: 0;
}

.title {
    @include typography(16px, 16px, 500, 500);
}

.subtitle {
    @include typography(14px, 14px, 400, 400);
}
