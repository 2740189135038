@use "@styles/helpers" as *;

.container {
    padding: 24px 16px;
    margin: 0 -16px;

    @include breakpoint(md) {
        @include rounded-corners(20px);

        margin: 0;
        padding: 64px;
    }
}

.root {
    @include flex(center);

    flex-flow: column-reverse wrap;

    @include breakpoint(md) {
        flex-direction: row;
    }
}

.textContainer,
.videoContainer {
    width: 100%;
    display: inline-block;

    @include breakpoint(md) {
        width: calc(50% - 12px);
    }
}

.textContainer {
    @include breakpoint(md) {
        margin-right: 12px;
    }
}

.videoContainer {
    margin-bottom: 24px;

    @include breakpoint(md) {
        margin-left: 12px;
        margin-bottom: 0;
    }
}

.title {
    @include typography(h4);

    margin-bottom: 8px;
    color: $color-gray-dark;
}

.description {
    color: $color-gray-dark-subtle;
}

.video {
    @include rounded-corners(20px);
}
