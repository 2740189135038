@use "@styles/helpers" as *;

.root {
    @include rounded-corners;

    background: $color-white;
    border: 1px solid $color-gray-light;
    height: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    color: $color-gray-dark;
    position: relative;

    &:hover {
        border-color: $color-gray;
    }
}

.imageContainer {
    @include flex(center, center);

    width: 100%;
    position: relative;
}

.image {
    border-radius: 8px 8px 0 0;
    width: 100%;
    max-width: 250px;

    &.unavailable {
        opacity: 0.4;
    }
}

.badge {
    position: absolute;
    bottom: -12px;
    z-index: z("product-card-badge");
}

.content {
    @include flex(flex-start, flex-start, column);

    padding: 0 8px 8px;
}

.brandContainer {
    @include truncate-text(100%);
    @include typography(body-small);

    color: $color-gray-semi;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 16px 0 2px;
}

.priceContainer {
    max-width: 100%;
    margin-top: 16px;
    margin-bottom: 8px;

    .productPrice {
        .originalPrice {
            @include typography(14px, 14px, 400, 400);
            @include truncate-text(100%);

            min-height: 14px;
            line-height: 14px;
        }

        .currentPrice {
            @include typography(18px, 18px, 700, 700);
        }
    }
}

.details {
    min-height: 40px;
    flex-grow: 1;
}

.name {
    @include typography(body-small-500);

    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.footer {
    @include flex(center);

    width: 100%;
    height: 36px;
    margin-top: 8px;
    position: relative;
}

.viewBtn {
    @include typography(body-small-500);

    width: 100%;
    height: 100%;
}

.productLabels {
    position: absolute;
    top: 12px;
    left: 0;
    z-index: z("product-card-labels");
}

.tagsContainer {
    @include flex(center);

    position: absolute;
    bottom: 8px;
    left: 8px;
    height: 24px;
    width: calc(100% - 16px);
}
