@use "@styles/helpers" as *;

.selectionModal {
    height: 375px !important;
    border-radius: 8px 8px 0 0;

    @include breakpoint(md) {
        @include rounded-corners;

        height: auto;
        min-height: 0 !important;
    }
}

.list {
    margin: 0 -24px;
}

.description {
    @include typography(body-small);

    color: $color-gray-dark-subtle;
    margin-bottom: 16px;
    margin-top: 24px;

    &.withDisclaimer {
        margin-top: 0;
    }

    @include breakpoint(md) {
        margin-top: 0;
    }
}

.disclaimer {
    @include flex(center);
    @include rounded-corners;

    padding: 16px 24px;
    background: $color-gray-light-ultra;
    margin-bottom: 24px;
    margin-top: 16px;

    @include breakpoint(md) {
        margin-top: 0;
    }
}

.disclaimerIcon {
    color: $color-primary;
    margin-right: 16px;
    flex-shrink: 0;
}

.disclaimerTitle {
    @include typography(body-small-500);

    color: $color-gray-dark;
    margin-bottom: 2px;
}

.disclaimerSubtitle {
    @include typography(small);

    color: $color-gray-dark-subtle;
}

.listItem {
    border-bottom: 1px solid $color-gray-light;
}

.problemTypeItem {
    &:last-of-type {
        border-bottom: 0;
    }
}

.productListItem {
    @include flex(center);

    padding: 12px 24px;

    .imageContainer {
        max-width: 48px;
        max-height: 48px;
        margin: 0 16px;
    }

    .nameContainer {
        @include typography(body-500);

        color: $color-gray-dark;
    }

    @include breakpoint(sm) {
        padding: 16px 24px;
    }
}

.problemType {
    @include flex(center);

    color: $color-gray-dark;

    svg {
        margin-right: 16px;
        color: $color-gray-semi;
    }
}

.productAccordion {
    padding: 12px 24px;

    @include breakpoint(md) {
        padding: 16px 24px;
    }
}

.productAccordionTitle {
    @include flex(center);

    .imageContainer {
        max-width: 48px;
        max-height: 48px;
        margin-right: 16px;
    }

    .nameContainer {
        @include typography(body-500);

        color: $color-gray-dark;
    }
}

.productAccordionContent {
    padding-left: 64px;
    margin-top: 8px;
}

.checkbox {
    @include flex(center);
}

.checkboxLabel {
    @include flex(center);
}

.radio {
    margin: 6px 0;

    .radioLabel {
        @include typography(body);

        color: $color-gray-dark-subtle;
    }
}

.footer {
    @include flex(center, flex-end);

    margin-top: 16px;
}

.scrollbar {
    @include breakpoint(sm) {
        max-height: 420px;
    }
}

.scrollbar :global .simplebar-track.simplebar-vertical {
    right: 4px;
}

.success {
    padding: 32px 0;
}
