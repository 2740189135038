@use "@styles/helpers" as *;

.inner {
    padding: 16px;

    @include breakpoint(sm) {
        padding: 0;
    }
}

// Description

.description {
    @include typography(body);

    color: $color-gray-dark-subtle;
}

// Content

.content {
    margin-top: 4px;
}

// Loading

.loadingContainer {
    @include flex(center, center);

    margin: 40px 0;
}

// Reasons

.reasonContainer {
    margin-top: 20px;
}

.reasonRadio {
    .reasonLabel {
        @include typography(body);

        color: $color-gray-dark-subtle;
    }
}

.reasonTextarea {
    margin-top: 24px;
    resize: none;
}

// Actions

.actionsWrapper {
    margin-top: 16px;
    @include flex(center, flex-end);
}

.actionButton {
    width: 100%;

    @include breakpoint(md) {
        width: auto;
    }
}

