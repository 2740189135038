@use "@styles/helpers" as *;

.root {
    @include flex(center);

    position: relative;
    margin: 8px 0;

    @include breakpoint(md) {
        margin: 0;
    }
}

.label {
    @include typography(body-small-500);

    color: $color-gray-dark;
    margin-right: 8px;
    line-height: 24px;
}

.mobileSelectBtn {
    @include button-reset;
    @include typography(body-small-500);

    color: $color-gray-dark;
    padding: 0;
    line-height: 20px;
}

.selectBtn {
    @include button-reset;
    @include typography(body-small);

    color: $color-gray-dark-subtle;
    padding: 0 8px;
    line-height: 24px;

    &:hover {
        color: $color-primary;

        .chevronIcon {
            color: $color-primary;
        }
    }
}

.chevronIcon {
    color: $color-gray-semi;
}

.sortIcon {
    color: $color-primary;
}

.popoverContainer {
    z-index: z("popover-body");
    margin-top: 16px;
}

.popover {
    padding: 16px 0;
    min-width: 230px;
}

.popoverTitle {
    @include typography(body-500);

    color: $color-gray-dark-subtle;
    padding: 0 16px;
    margin-bottom: 8px;
}

.options {
    @include flex(flex-start, center, column);
}

.option {
    @include flex(center);

    padding: 12px 0;
    width: 100%;

    &:hover {
        background: $color-gray-light-ultra;
    }

    .selectIndicator {
        @include flex(center, center);

        flex-shrink: 0;
        height: 20px;
        width: 20px;
        border: 1px solid $color-border;
        background-color: $color-white;
        border-radius: 50%;
        margin-right: 16px;
        position: relative;
    }

    .optionLabel {
        flex-grow: 1;
    }

    @include breakpoint(md) {
        padding: 12px 16px;
    }
}

.option.selected {
    .selectIndicator .fill {
        height: 10px;
        width: 10px;
        display: block;
        border: 4px solid transparent;
        border-radius: 50%;
        background: $color-primary;
    }
}

.optionLabel {
    @include typography(body-small);

    color: $color-gray-dark-subtle;
}

.modal {
    .modalContent {
        max-height: 80vh !important;
        height: auto !important;
        border-radius: 8px 8px 0 0 !important;
    }
    
    .modalBody {
        padding: 0;
        padding-bottom: calc(env(safe-area-inset-bottom));
    }
}

:export {
    breakpoint: $screen-md-min;
}
