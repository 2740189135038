@use "@styles/helpers" as *;

.root {
    @include flex(center, center);

    width: 100%;
    border-radius: 0 0 8px 8px;
    background: $color-gray-light-ultra;
}

// --- Zoom open

.zoomOpen {
    @include flex(center, center);

    position: absolute;
    bottom: 10px;
    right: 10px;
}

// --- Zoom in / out

.zoomActions {
    position: relative;
    bottom: 0;
    color: $color-gray-semi;
    display: flex;
    justify-content: space-between;
    width: 120px;
    padding: 10px;
}

// --- Zoom Icon

.zoomIcon {
    position: relative;
    width: 40px;
    height: 40px;
    background: $color-white;
    color: $color-primary;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    cursor: pointer;
    outline: none;
    border: none;
    z-index: z("product-details-zoom-icon");

    &:hover {
        background: $color-primary;
        color: white;
        box-shadow: 0 8px 16px rgb(0 0 0 / 4%);
        transform: scale(1.05);
    }

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
    }
}
