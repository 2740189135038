@use "@styles/helpers" as *;

.root {
    position: relative;
    display: flex;
    overflow-x: auto;
    padding-bottom: 8px;
    margin: 0 -4px;

    @include breakpoint(md) {
        flex-wrap: wrap;
        padding-bottom: 0;
        overflow-x: hidden;
    }
}

.chipWrapper {
    display: flex;
    flex-shrink: 0;
    margin: 4px;
}

.chip {
    @include flex(center, center);
    @include rounded-corners;
    @include typography(12px, 14px, 500, 500);

    height: 32px;
    padding: 0 16px;
    border: 1px solid $color-gray-light;
    background: $color-white;
    color: $color-gray-semi;
    line-height: 1;
    transition: border-color 0.2s ease, color 0.2s;

    &:not(.selected):hover,
    &.selected {
        border-color: $color-gray-dark-subtle;
        color: $color-gray-dark-subtle;
    }
}
