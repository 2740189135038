@use "@styles/helpers" as *;

.root {
    @include flex(center, space-between, column);
    @include rounded-corners(16px);

    padding: 12px 0;
    background-color: $color-gray-light-ultra;
    text-align: center;

    @include breakpoint(md) {
        padding: 24px 8px;
    }
}

.root.minimal {
    @include flex(center, space-between, row); 
    @include rounded-corners(0);

    padding: 0;
    background-color: transparent;

    .icon {
        margin-right: 4px;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .value {
        @include typography(12px, 14px, 400, 400);

        white-space: nowrap;
        color: $color-gray-dark;
    }

    @include breakpoint(md) {
        padding: 0;
    }
}

.icon {
    @include flex(center, center);

    svg {
        height: 32px;
        width: 32px;
        color: $color-primary;
    }

    @include breakpoint(md) {
        svg {
            height: 36px;
            width: 36px;
        }
    }
}

.label {
    @include flex(center, center, row);

    flex-grow: 1;
    margin-top: 8px;
    padding: 0 16px;
    font-size: 12px;
    font-weight: 400;

    @include breakpoint(sm) {
        font-size: 14px;
        font-weight: 400;
    }

    @include breakpoint(md) {
        margin-top: 16px;
        padding: 0;
        flex-grow: 0;
        font-size: 16px;
        font-weight: 400;
        color: $color-gray-dark-subtle;
    }
}

.valueWrapper {
    @include flex(center, center, row);

    flex-wrap: wrap;
}

.value {
    margin-top: 2px;
    color: $color-gray-dark;
    font-size: 14px;
    font-weight: 700;

    &.original {
        margin-right: 4px;
        font-weight: 400;
        color: $color-gray-semi;
        text-decoration: line-through;

        ~ .value {
            margin-top: 0;
        }
    }

    &.discounted {
        color: $color-discount;
    }

    @include breakpoint(sm) {
        font-size: 14px;
        font-weight: 700;

        &.original {
            font-weight: 400;
        }
    }

    @include breakpoint(md) {
        margin-top: 4px;
        font-size: 16px;
        font-weight: 700;

        &.original {
            font-weight: 400;
        }
    }
}