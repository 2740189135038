@use "@styles/helpers" as *;

.root {
    @include breakpoint(sm, max) {
        height: 100%;
    }
}

.form {
    @include breakpoint(sm, max) {
        height: 100%;
    }
}

.streetStep {
    padding-bottom: 8px;
}

.stepper {
    @include breakpoint(sm, max) {
        .stepperContent {
            height: 100% !important;
        }
    }
}
