@use "@styles/helpers" as *;

.root {
    @include rounded-corners;

    width: 100%;
    border: 1px solid transparent;
    transition: all 0.2s ease;
    padding: 4px;
    cursor: pointer;

    &.expanded {
        border-color: $color-gray;

        &.hasError {
            border-color: $color-error;
        }
    }

    @include breakpoint(sm, max) {
        transition: none;
    }
}

// Content

.wrapper {
    @include flex(flex-start, flex-start, row);

    .content {
        position: relative;
        flex-grow: 1;
        min-width: 0;
    }
}

// Icon

.icon {
    flex-shrink: 0;
    margin-right: 8px;
    color: $color-primary;
}

// Label

.label {
    @include typography(body-small);

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.2s ease;

    &.default {
        color: $color-gray-semi;
    }

    &.small {
        @include typography(small);

        color: $color-gray-semi;
    }
}

// Textarea

.textareaContainer {
    position: relative;
    font-size: 0;
    padding-top: 4px;

    .placeholder {
        position: absolute;
        width: 100%;
        top: 2px;
        left: 0;
        opacity: 0;
        transition: opacity 0.2s ease;
        pointer-events: none;
        z-index: 1;

        &.visible {
            opacity: 1;
            transition: opacity 0.2s ease;
        }
    }

    .textarea {
        @include typography(body-small);

        width: 100%;
        resize: none;
        padding: 0;
        border-radius: 0;
        box-shadow: none !important;
        border: none !important;
        overflow: hidden;
        transition: opacity 0.2s ease, transform 0.2s ease !important;

        &.hidden {
            opacity: 0;
        }
    }
}

.textareaError {
    min-height: 0;
    margin-top: 8px;
    margin-left: 0;
}

// Actions

.actionsContainer {
    @include flex(center, flex-end, row);

    position: relative;
    width: 100%;
    padding: 12px 4px 4px;
    transition: all 0.2s ease;

    button {
        width: 90px;
    }
}

.deleteBtn {
    margin-right: 8px;
}

:export {
    breakpoint_sm: $screen-sm-min;
    breakpoint_md: $screen-md-min;
    breakpoint_lg: $screen-lg-min;
}
