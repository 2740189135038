@use "@styles/helpers" as *;

.root {
    @include breakpoint(md) {
        padding: 40px 8px;
    }
}

.title {
    @include typography(body);
}

.subtitle {
    @include typography(body-small);
}
