@use "@styles/helpers" as *;

.inputWrapper {
    &.small {
        .input {
            @include typography(body-small);

            height: 32px;
            padding: 1px 36px 0;
        }

        .searchIcon {
            left: 12px;
        }

        .clearIcon {
            right: 12px;
        }
    }

    &.medium {
        .input {
            @include typography(body);

            height: 40px;
            padding: 0 40px;
        }

        .searchIcon {
            left: 16px;
        }

        .clearIcon {
            right: 16px;
        }
    }
}

.input {
    @include typography(body);

    width: 100%;
    height: 40px;
    padding: 0 40px;
    background: $color-gray-light;
    border: 1px solid $color-gray-light;
    border-radius: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.2s ease;

    &.rounded {
        border-radius: 100px;
    }

    &.gray {
        background: $color-gray-light;
        border: 1px solid $color-gray-light;
    }

    &.white {
        background: $color-white;
        border: 1px solid $color-gray-light;
    }

    &::placeholder {
        transition: color 0.2s ease;
    }

    &:focus,
    &:hover {
        border: 1px solid $color-gray !important;
    }
}

.clearIcon,
.searchIcon {
    color: $color-gray-semi;
}

.searchIcon {
    left: 16px;
}

.clearIcon {
    right: 16px;
    opacity: 0;
    transform: scale(0.6);
    pointer-events: none;
    transition: all 0.1s ease;
    cursor: pointer;

    &.visible {
        opacity: 1;
        transform: scale(1);
        pointer-events: all;
    }
}
