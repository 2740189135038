@use "@styles/helpers" as *;

.root {
    display: inline-flex;
    list-style-type: none;
}

.content {
    margin-top: 8px;
}

.list {
    padding: 12px 0;
    min-width: 200px;
    list-style-type: none;
}

.item {
    width: 100%;

    .link {
        border-radius: 0;
        padding: 12px 24px;

        &:hover {
            background-color: $color-gray-light-ultra;
        }
    }
}

.chevron {
    margin-left: 4px;
}