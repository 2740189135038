@use "@styles/helpers" as *;

.ratingCard {
    width: 100%;
    margin: 0 16px 24px;

    @include breakpoint(sm) {
        padding: 16px !important;
    }

    @include breakpoint(md) {
        margin: 0 0 32px;
        padding: 16px 24px !important;
    }
}
