@use "@styles/helpers" as *;

.root {
    @include flex(center, center, column);

    &.medium {
        .symbol {
            margin-right: 8px;
        }

        .textContainer {
            margin-top: 16px;

            .title {
                @include typography(16px, 16px, 500, 500);
            }

            .info {
                @include typography(14px, 14px, 400, 400);
            }

            .inner {
                margin-top: 16px;
            }
        }

        @include breakpoint(sm) {
            padding: 16px 24px;
        }
    }

    &.large {
        .symbol {
            margin-right: 8px;
        }

        &:not(.simple) {
            .ratingContainer {
                height: 60px;

                .check {
                    width: 60px;
                    height: 60px;
                }
            }
        }

        .textContainer {
            margin-top: 24px;

            .title {
                @include typography(20px, 26px, 500, 500);
            }

            .info {
                @include typography(16px, 18px, 400, 400);
            }

            .inner {
                margin-top: 32px;
            }
        }
    }

    &.inline {
        .ratingContainer {
            margin-left: 0;
        }

        @include breakpoint(sm) {
            @include flex(center, space-between, row);

            .ratingContainer {
                margin-left: 32px;
                order: 1;

                .check {
                    left: auto;
                }
            }

            .textContainer {
                margin-top: 0;
                align-items: flex-start;
                text-align: start;

                .success {
                    margin: auto;
                    right: auto;
                }
            }
        }
    }

    &.disabled {
        .textContainer {
            opacity: 0.4;
        }

        .content {
            opacity: 0.4;
        }
    }
}

// Rating / Check

.ratingContainer {
    @include flex(center, center, row);

    position: relative;
    height: 42px;
    transition: opacity 0.2s ease;

    .check {
        @include flex(center, center, row);
        @include pos-fill(0);

        width: 42px;
        height: 42px;
        margin: auto;
        border: 1px solid $color-primary;
        border-radius: 100%;
        color: $color-primary;
    }
}

.rating {
    &.submitted {
        pointer-events: none;
        cursor: default;
    }
}

// Title, Info / Success

.textContainer {
    @include flex(center, center, column);

    position: relative;
    text-align: center;
    transition: opacity 0.2s ease;

    .title {
        color: $color-gray-dark;
    }

    .info {
        color: $color-gray-dark-subtle;
    }

    .success {
        @include flex(center, center, row);
        @include pos-fill(0);

        height: 42px;
        margin: 0;
    }

    .inner {
        width: 100%;
    }
}

// Animation

.fade {
    &.entering,
    &.entered {
        opacity: 1;
        transform: translateY(0);
        transition:
            opacity var(--enterDuration) ease var(--enterDelay),
            transform var(--enterDuration) $ease-out-expo var(--enterDelay),;
    }

    &.exiting,
    &.exited {
        opacity: 0;
        transform: translateY(var(--yOffset));
        transition:
            opacity var(--exitDuration) ease var(--exitDelay),
            transform var(--exitDuration) $ease-out-expo var(--exitDelay);
    }
}

// Content

.content {
    transition: opacity 0.2s ease;
}

:export {
    breakpoint: $screen-sm-min;
}
