@use "@styles/helpers" as *;

.root {
    @include flex(flex-start, flex-start, column);

    position: sticky;
    width: $cart-width;
    padding: $container-padding 0 0;
    margin: -$container-padding 0 0;
    margin-left: 32px;
    align-self: flex-start;
    flex-shrink: 0;

    &:not(.hasHeaderCategoriesBarOffset) {
        top: calc($desktop-header-height);
        height: calc(100vh - $desktop-header-height - 24px);
    }

    &.hasHeaderCategoriesBarOffset {
        top: calc($desktop-header-height + $desktop-header-categories-bar-height);
        height: calc(100vh - $desktop-header-height - $desktop-header-categories-bar-height - 24px);
    }

    @include breakpoint(xl, max) {
        display: none;
    }
}

.inner {
    @include rounded-corners;

    width: 100%;
    border: 1px solid $color-gray-light;
    box-shadow: 0 9px 27px 0 #3333330D;
    overflow: hidden;
}

