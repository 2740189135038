@use "@styles/helpers" as *;

.form {
    width: 100%;
}

// Form Group

.formGroup {
    margin: 12px 0;
    padding: 12px 0 24px;
    border-bottom: 1px solid $color-gray-light;

    &:first-of-type {
        margin-top: 0;
        padding-top: 0;
    }

    &.textareaGroup {
        padding-bottom: 0;
    }

    &.highlightGroup {
        @include rounded-corners(16px);

        border-bottom: 0;
        padding: 24px 16px;
        margin-top: 24px;
        background: $color-gray-light-ultra;

        @include breakpoint(sm) {
            padding: 24px;
        }
    }

    // Label

    .label {
        @include typography(body-500);

        margin-bottom: 8px;

        .labelText {
            span {
                font-weight: 700;
            }
        }
    }

    // Radio

    .radioGroup {
        @include flex(center);

        .radio {
            align-items: center;
            margin-right: 24px;
        }

        .radioLabel {
            @include typography(body);
        }
    }

    // Rating

    .ratingLabel {
        @include typography(h4);

        text-align: center;
        margin: 16px 0;

        @include breakpoint(sm) {
            margin: 24px 0;
            max-width: 425px;
        }
    }

    // Checkbox

    .checkbox {
        @include flex(flex-start, flex-start, row);

        margin-top: 8px;

        .checkboxLabel {
            @include typography(body-small);

            min-height: 24px;
            line-height: 24px;
            color: $color-gray-semi;
        }
    }

    // Textarea

    .textarea {
        resize: none;
    }
}

// Actions

.actions {
    @include flex(center, flex-end, row);

    margin-top: 24px;

    .submitBtn {
        width: 100%;
        border: none;

        @include breakpoint(sm) {
            width: auto;
            min-width: 120px;
        }
    }
}

.info {
    @include typography(body-small);

    color: $color-gray-semi;

    a {
        color: $color-primary;

        &:hover {
            color: $color-primary-shade-1;
        }
    }
}
